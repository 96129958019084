import React from 'react'
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'

const Information = () => {
    const { t } = useTranslation()
    
    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{margin: "0"}}>
                    <h2>{t('Dop-detail4')} <span></span></h2>
                    <p>Leia games is the first company offering casual mobile games with a real money skill game twist in the Web3 space.</p>
                    <p>We believe that video games are the key to the mass adoption of crypto, and therefore, we want to harness the popularity of casual mobile games to onboard players into Web3. Leia Games takes a unique approach by focusing on skill-based, real-money competitions, merging engaging gameplay with the ability to earn cryptocurrency. Our platform offers popular casual mobile games with competitive tournaments, providing a seamless transition from free-to-play to real money gaming.</p>
                    <p>The $LEIA token serves as the primary currency within our ecosystem. It can be used to enter tournaments, purchase in-game items, and participate in community events. Our unified token system simplifies the gaming experience and ensures a sustainable economy by allocating tokens for player rewards, development, and community growth.</p>
                    <p>Leia Games develops various types of games, including casual puzzles, strategy games, and competitive casual eSports titles. These games are available on a user-friendly platform  where players can easily discover and compete. By focusing on both casual fun and competitive tournaments, we cater to a wide range of gaming preferences and skill levels. This diversity ensures an inclusive and engaging gaming experience for all players.</p>
                    <p>With over 3 billion mobile gamers worldwide, Leia Games has immense potential. By introducing them to crypto through the Leia Token, we aim to position Leia Games as the gateway to Web3 for the masses, providing real skill, real money, and real stakes.</p>
                </div>
                <div className="first-para">
                    <h2>Leia Games’ go-to-market strategy <span></span></h2>
                    <p>To achieve mass adoption, Leia Games will utilize a multi-faceted approach. Our strategy to onboard players involves leveraging social gaming and community-driven events. We plan to host engaging real-life gaming tournaments, collaborate with popular gaming and crypto influencers, and launch targeted marketing campaigns. Our focus on social interactions and competitive gaming will drive player engagement and encourage them to invite friends to join the platform. By creating a vibrant community around our games, we aim to attract and retain a large player base who will become active participants within our ecosystem.</p>
                    
                   
                </div>
                <div className="first-para">
                    <h2>The Team <span></span></h2>
                    <div className="team-gems">
                        <div className="single-team">
                            <img src="\assets\alldetailpages\leiadetailpage\banner\team\1.svg" alt="img" className='img-fluid' />
                            <h6>Avihay Hermon </h6>
                            <h5>Co-Founder, CEO</h5>
                            <p>A former investment banker and analyst of video game companies with over five years of experience in video game companies. Avihay uses his business experience and skills to navigate Leia Games to success.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\leiadetailpage\banner\team\2.svg" alt="img" className='img-fluid' />
                            <h6>Meir Warcel </h6>
                            <h5>Co-Founder, CCO</h5>
                            <p>With a strong background in creative management and game design for companies such as Playtika and Jelly Button,  Meir leads the creative vision of our platform and games, ensuring they are both unique and engaging.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\leiadetailpage\banner\team\3.svg" alt="img" className='img-fluid' />
                            <h6>Dona Solomon </h6>
                            <h5>Art Director</h5>
                            <p>With over six years in graphic design and art direction, Dona has worked for the biggest game companies in the world (Playtika, Beach Bum). She leads our platform's art direction and visual style and makes every game stand out as a piece of art.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\leiadetailpage\banner\team\4.svg" alt="img" className='img-fluid' />
                            <h6>Alon Buyum </h6>
                            <h5>VP R&D</h5>
                            <p>Alon has over a decade of experience in both game development and programming. Working for companies like Philips and 7 Elements, Alon uniquely understands programming and game development.
                            </p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\leiadetailpage\banner\team\5.svg" alt="img" className='img-fluid' />
                            <h6>Avishy Menashe </h6>
                            <h5>Lead Client Developer</h5>
                            <p>With more than five years in game development, Avishy is a seasoned developer with experience in skill-based games and a deep knowledge of mobile game development.
                            </p>
                        </div>
                       
                   
                      
                    </div>

                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\alldetailpages\leiadetailpage\banner\leia-tokenomics-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                            <img src="\assets\alldetailpages\leiadetailpage\banner\leia-tokenomics.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                        </div>
                    </div>
                </div>
               
                
            </section>
        </>
    )
}

export default Information
