
import React, { useEffect, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import axios from "axios";

const Banner = () => {
  const { t } = useTranslation()

  return (
    <>
      <section className="main-banner main-bannernodesminers">
        <img src="\assets\landing\banner\banner-shadow.png" alt="img" className="img-fluid banner-shadow hide-in-light-theme" />
        <img src="\assets\landing\banner\banner-shadow-light.png" alt="img" className="img-fluid banner-shadow d-none show-in-light-theme" />
        <video className="main-banner-video hide-in-light-theme"
          muted="muted" playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo">
          <source src="\assets\videos\new-banner-video.mp4" type="video/mp4" />
        </video>
        <video className="main-banner-video d-none show-in-light-theme"
          muted="muted" playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo">
          <source src="\assets\videos\new-banner-video-light.mp4" type="video/mp4" />
        </video>
        <div className="custom-container">
          <div className="main-content">
            <h5>Nodes & Miners</h5>
            <h4>Pioneering Community <br /> <span>Growth and Rewards</span></h4>
            <p>Gems Launchpad is revolutionizing community-driven fundraising with two powerful tools—Gems Nodes and Project Miners. These innovative products unlock new levels of engagement and earning potential for token holders.</p>
            <div className="twice-btns">
              <a href="http://3.90.108.87:40981/" target="_blank" className="btn-green">Buy Gems Nodes</a>
              <a href="http://3.90.108.87:46231/" target="_blank" className="btn-transparent">Buy Miners</a>
            </div>
          </div>
        </div>
        
      </section>

    </>
  );
};

export default Banner;
