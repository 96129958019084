import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import { toast } from "react-toastify";

const ClaimToken = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const UsdtAdress = Environment.dop
  const priceUsdt = 10000
  const tokenAddress = Environment.chinaTomi;
  
  const claimToken = useCallback(
    async (addrss) => {

      const contract = chinaContract(addrss, web3);
      let gas
      try {
        let gasPrice = await web3.eth.getGasPrice()
        gasPrice = parseInt(gasPrice) + 3000000000

        const gas = await contract.methods
          .claim()
          .estimateGas({ from: account });
        const gaslimit = gas + parseInt(15000)
        const details = await contract.methods
          .claim(

        )
          .send({
            from: account,
            gas: gaslimit,
            gasPrice
          });
        return details;
      } catch (error) {
        console.log(error, 'web3');

        throw error;
        // }

      }
    },
    [account, web3]
  );
  return { claimToken: claimToken };
};
export default ClaimToken;
