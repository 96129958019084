import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import Signature from "../../hooks/userSign";
import { API_URL_SURVEY } from "../../utils/ApiUrl";
import axios from "axios";
import Loader from "../../hooks/loader";
import { findAllByLabelText } from "@testing-library/react";
import { Modal } from "react-bootstrap";
import { browserName, isBrowser } from "react-device-detect";
import { Buffer } from "buffer";
import { setupNetwork } from "../../utils/wallet";
import { toast } from "react-toastify";
const ConnectWallet = ({ setSteps, setUserData }) => {
  const token = localStorage.getItem("accessToken");
  const { account, chainId } = useWeb3React();
  if (token && account) {
    const signerAccount = localStorage.getItem("signerAccount");
    if (signerAccount === account) {
      setSteps(1);
    }
  }

  const id = localStorage.getItem("referalId");

  const { login, logout } = useAuth();
  const { userSign } = Signature();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);

  const [showwallet, setShowWallet] = useState(false);

  useEffect(() => {
    if (account) {
      setLoading1(true);
      setTimeout(() => {
        setLoading1(false);
      }, 3000);

      setShowWallet(true);
    } else if (account && chainId !== 8453) {
      // setupNetwork();
    }
  }, [account, chainId]);

  const handleShowWallet = () => setShowWallet(true);
  const handleCloseWallet = () => setShowWallet(false);

  const getBearerToken = async () => {
    setLoading(true);
    if (account) {
      try {
        const sign = await userSign();
        if (sign) {
          const payload = {
            walletAddress: account?.toLowerCase(),
            sign: sign?.toLowerCase(),
            rememberMe: true,
            ...(id !== null && { referredBy: id }),
          };

          console.log(payload);

          const response = await axios.post(
            `${API_URL_SURVEY}/auth/users/signup-signin`,
            payload,
            { headers: { "Content-Type": "application/json" } }
          );
          console.log(response);
          if (response) {
            const data = response?.data?.data;
            localStorage.setItem("accessToken", data?.accessToken);
            localStorage.setItem("signerAccount", account);
            localStorage.setItem("refreshToken", data?.refreshToken);
            setSteps(1);
            setUserData(data?.user);
            setLoading(false);
          }
        }
      } catch (err) {
        console.error("Error in getBearerToken():", err.message);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("No wallet connected");
      toast.error("Wallet not found! Connect again for sign");
      handleCloseWallet();
    }
  };

  const connectWallet = async (e) => {
    try {
      if (account) {
        const connectorId = window.localStorage.getItem("connectorId");
        await logout(connectorId);
        localStorage.removeItem("connectorId");
        localStorage.removeItem("flag");
      } else {
        await login("injected", e);
        localStorage.setItem("connectorId", "injected");
        localStorage.setItem("flag", "true");
      }
    } catch (error) {
      console.error("Error during WalletConnect operation:", error);
      handleCloseWallet();
    }
    // setLoader(false);
  };

  const trustWallet = async () => {
    try {
      if (account) {
        await logout("walletconnect");
        localStorage.removeItem("connectorId");
        localStorage.removeItem("flag");
        localStorage.removeItem("accessToken");
      } else {
        // await setupNetwork();
        await login("walletconnect");
        localStorage.setItem("connectorId", "walletconnect");
        localStorage.setItem("flag", "true");
      }
    } catch (error) {
      console.error("Error during WalletConnect operation:", error);
    } finally {
    }
  };

  const coinbase = async () => {
    try {
      if (account) {
        await logout("coinbaseWallet");
        localStorage.removeItem("connectorId");
        localStorage.removeItem("flag");
        localStorage.removeItem("accessToken");
      } else {
        // await setupNetwork();
        await login("coinbaseWallet");
        localStorage.setItem("connectorId", "coinbaseWallet");
        localStorage.setItem("flag", "true");
      }
    } catch (error) {
      console.error("Error during coinbaseWallet operation:", error);
    } finally {
    }
  };

  const [coinbaseLink, setCoinbaseLink] = useState("");

  useEffect(() => {
    const referalCode = localStorage.getItem("referalId");
    if (referalCode) {
      setCoinbaseLink(
        `https://gems.vip/gemscommunityproject/?id=${referalCode}`
      );
    } else {
      setCoinbaseLink("https://gems.vip/");
    }
  }, []);

  return (
    <>
      {loading1 && <Loader />}
      {loading || walletLoading ? (
        <>
          {walletLoading && (
            <Loader text="Sending wallet connection request.." />
          )}
          {loading && <Loader text="Waiting for signature.." />}
        </>
      ) : (
        <section className="connectwallet-section">
          <div className="main-heading">
            <h4>Connect your wallet</h4>
            <p>
              In due time, you will receive your <span>airdrop</span> to this
              exact address. Meanwhile, we just need to make sure you’re on
              board.
            </p>
          </div>

          <div className="bottom-wallet">
            <a
              onClick={() => connectWallet()}
              className="metamask-hideonmobile"
            >
              <img
                src="\assets\navbar\metamask.svg"
                alt="img"
                className="img-fluid"
              />
              Metamask
            </a>
            <a onClick={() => trustWallet()}>
              <img
                src="\assets\navbar\wallet.svg"
                alt="img"
                className="img-fluid"
              />
              WalletConnect
            </a>

            {isBrowser && (
              <a
                onClick={() => {
                  coinbase();
                }}
              >
                <img
                  src="\assets\navbar\base.svg"
                  alt="img"
                  className="img-fluid"
                />
                CoinBase
              </a>
            )}

            {/* {isBrowser ? (
              <a
                onClick={() => {
                  coinbase();
                }}
              >
                <img
                  src="\assets\navbar\base.svg"
                  alt="img"
                  className="img-fluid"
                />
                CoinBase
              </a>
            ) : (
              <>
                {browserName === "Safari" ||
                browserName === "Mobile Safari" ||
                browserName === "Brave" ||
                browserName === "Firefox" ||
                browserName === "Chrome" ? (
                  <a
                    href={`https://go.cb-w.com/dapp?cb_url=${coinbaseLink}`}
                    target="_blank"
                  >
                    <img
                      src="\assets\navbar\base.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <h6>Coinbase </h6>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      coinbase();
                    }}
                  >
                    <img
                      src="\assets\navbar\base.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    CoinBase
                  </a>
                )}
              </>
            )} */}

            {/* 
            <a>
              <img
                src="\assets\navbar\base.svg"
                alt="img"
                className="img-fluid"
              />
              Coinbase
            </a> */}
          </div>
        </section>
      )}

      <Modal
        className="common-modal-style wallet-modal"
        show={showwallet}
        onHide={handleCloseWallet}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sign Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sign-required">
            <p className="upperpara text-center">
              Please provide sign to continue
            </p>
            <button className="btn-green" onClick={getBearerToken}>
              Sign
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectWallet;
