import React, { useEffect, useState } from "react";
import BannerEducation from "./BannerEducation";
import "./education.scss"
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Education = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);

    const { t } = useTranslation();
    return (
        <>
            <div className="wrapper-education">
                <BannerEducation />
                <div className="maineductionheading">
                    <div className="custom-container">
                        <h6 className="innerheading">
                            {/* {t('Gems-education')} */}
                            {t('Educationnew3')} <span>{t('Educationnew4')}</span>
                        </h6>
                    </div>
                </div>
                <section className="education-section">
                    <div className="custom-container">
                        <div className="bottom-content">
                            <div className="single-card" onClick={() => { setShow1(true) }}>
                                <div className="main-img">
                                    <img src="\assets\education\becominggoldenuser.png" alt="img" className="poster-video" />
                                    <button className="playbtn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                            <g clip-path="url(#clip0_232_2524)">
                                                <path d="M0.928711 0L12.0716 7L0.928711 14V0Z" fill="#01141A" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_232_2524">
                                                    <rect width="13" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        {t('Educationnew5')}
                                    </button>
                                </div>
                                <h6>{t('Becomegolden')}</h6>
                            </div>
                            <div className="single-card" onClick={() => { setShow2(true) }}>
                                <div className="main-img">
                                    <img src="\assets\education\stakinggems.png" alt="img" className="poster-video" />
                                    <button className="playbtn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                            <g clip-path="url(#clip0_232_2524)">
                                                <path d="M0.928711 0L12.0716 7L0.928711 14V0Z" fill="#01141A" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_232_2524">
                                                    <rect width="13" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        {t('Educationnew5')}
                                    </button>
                                </div>
                                <h6>{t('StakingGemsur')}</h6>
                            </div>
                            <div className="single-card" onClick={() => { setShow3(true) }}>
                                <div className="main-img">
                                    <img src="\assets\education\participating.png" alt="img" className="poster-video" />
                                    <button className="playbtn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                            <g clip-path="url(#clip0_232_2524)">
                                                <path d="M0.928711 0L12.0716 7L0.928711 14V0Z" fill="#01141A" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_232_2524">
                                                    <rect width="13" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        {t('Educationnew5')}
                                    </button>
                                </div>
                                <h6>{t('Participatingprivate')}</h6>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <Modal className='common-modal-style image-preview' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/W_rLKRBGyYM" title="How to become a Golden User (for users)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Modal.Body>
            </Modal>
            <Modal className='common-modal-style image-preview' show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/IXJOUDAAwTw" title="How to stake your $GEMS (for users)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Modal.Body>
            </Modal>
            <Modal className='common-modal-style image-preview' show={show3} onHide={handleClose3} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/konk80GVKZw" title="How to participate in a private sale (for users)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Education;
