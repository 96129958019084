import "./navbar.scss";
import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";
import Modal from "react-bootstrap/Modal";
import { useWeb3React } from "@web3-react/core";
import { setupNetwork } from "../../../utils/wallet";
import useAuth from "../../../hooks/useAuth";
import useWeb3 from "../../../hooks/useWeb3";
import { browserName, isBrowser } from "react-device-detect";
import { Buffer } from "buffer";
const languages = [
  {
    code: "en",
    name: "Eng",
    country_code: "gb",
  },
  {
    code: "jp",
    name: "Japanese",
    country_code: "jp",
  },
  {
    code: "es",
    name: "Spanish",
    country_code: "es",
  },
  {
    code: "tr",
    name: "Turkish",
    country_code: "tr",
  },
];
const Navbar = ({ pageLoaded }) => {
  const history = useLocation();
  window.Buffer = Buffer;
  let { account, chainId } = useWeb3React();
  const web3 = useWeb3();
  const { login, logout } = useAuth();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [connect, setConnect] = useState(false);
  const [showwallet, setShowWallet] = useState(false);
  const handleCloseWallet = () => setShowWallet(false);
  const handleShowWallet = () => setShowWallet(true);
  var languageselected = localStorage.getItem("codelanguage");
  const currentLanguageCode = languageselected
    ? languageselected
    : cookies.get("i18next");
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  var languageactive = currentLanguage?.name;

  const changelanguage = (code) => {
    i18next.changeLanguage(code);
    localStorage.setItem("codelanguage", code);
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "light-theme"
  );
  const [nextTheme, setNextTheme] = useState(theme);
  const [animate, setAnimate] = useState(false);

  const toggleTheme = () => {
    setAnimate(true);
    setNextTheme(theme === "light-theme" ? "dark-theme" : "light-theme");
  };

  useEffect(() => {
    if (animate) {
      const animationDuration = 1000;

      const timeoutId = setTimeout(() => {
        setTheme(nextTheme);
        localStorage.setItem("theme", nextTheme);
        setAnimate(false);
      }, animationDuration);

      return () => clearTimeout(timeoutId);
    }
  }, [animate, nextTheme]);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    if (pageLoaded && theme !== "dark-theme") {
      toggleTheme();
    }
  }, [pageLoaded, theme]);

  const url = window.location.pathname;
  const name = url.substring(url.lastIndexOf("/") + 1);

  // console.log(name);

  const connectWallet = async (e) => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      await login("injected", e);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      localStorage.setItem("chain", e);
      handleClose();
      // setclickedbtn(false)
      // setLoader(false);
    }
    // setLoader(false);
  };

  const coinbase = async (e) => {
    try {
      if (account) {
        await logout("coinbaseWallet");
        localStorage.removeItem("connectorId");
        localStorage.removeItem("flag");
        localStorage.removeItem("accessToken");
      } else {
        login("coinbaseWallet", e);
        localStorage.setItem("connectorId", "coinbaseWallet");
        localStorage.setItem("flag", "true");
        handleClose();
      }
    } catch (error) {
      console.error("Error during WalletConnect operation:", error);
      // toast.error('An error occurred during WalletConnect operation');
    }
  };

  const trustWallet = async (e) => {
    try {
      if (account) {
        const connectorId = window.localStorage.getItem("connectorId");
        await logout(connectorId);
        localStorage.removeItem("connectorId");
        localStorage.removeItem("flag");
      } else {
        login("walletconnect", e);
        localStorage.setItem("connectorId", "walletconnect");
        localStorage.setItem("flag", "true");
        handleClose();
      }
    } catch (error) {
      console.error("Error during WalletConnect operation:", error);
      // toast.error('An error occurred during WalletConnect operation');
    }
  };

  const disconnectWallet = async () => {
    const connectorId = window.localStorage.getItem("connectorId");
    logout(connectorId);
    localStorage.removeItem("connectorId");
    localStorage.removeItem("flag");
    localStorage.removeItem("chain");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("wallet");
    if (connectorId === "coinbaseWallet") {
      window.location.reload();
    }
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("wallet");
  };

  // useEffect(() => {
  //   if (account) {
  //     if (chainId != 8453) {
  //       // console.log("here is enter now")
  //       setupNetwork();
  //     }
  //   }
  // }, [account, chainId]);

  // console.log("chain here is", chainId)
  // useEffect(() => {
  //   // Update the timer every second
  //   if (chainId != 8453) {
  //     setupNetwork(8453)
  //   }
  // }, [chainId]);

  const [coinbaseLink, setCoinbaseLink] = useState("");

  useEffect(() => {
    const referalCode = localStorage.getItem("referalId");
    if (referalCode) {
      setCoinbaseLink(
        `https://gems.vip/gemscommunityproject/?id=${referalCode}`
      );
    } else {
      setCoinbaseLink("https://gems.vip/");
    }
  }, []);
  const location = useLocation(); // Get the current location object

  // Check if the current route is '/survey'
  const isSurveyPage =
    location.pathname === "/survey" ||
    location.pathname === "/gemscommunityproject";

  return (
    <>
      <section className="main-navbar">
        <div className="custom-container h-100">
          <nav class="navbar navbar-expand-xl p-0 h-100">
            {window.innerWidth > 600 ? (
              <>
                <Link class="navbar-brand p-0 hide-in-light-theme" to="/">
                  {" "}
                  <img src="\logo.svg" alt="img" className="img-fluid" />
                </Link>
                <Link
                  class="navbar-brand p-0 d-none show-in-light-theme"
                  to="/"
                >
                  {" "}
                  <img src="\logo-white.svg" alt="img" className="img-fluid" />
                </Link>
              </>
            ) : (
              <>
                {" "}
                <Link to="/" className="hide-in-light-theme">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="26"
                    viewBox="0 0 29 26"
                    fill="none"
                  >
                    <path
                      d="M6.52616 0L0 7.05812V11.636L14.3809 26L28.7554 11.6437V9.79509H11.1969L12.4137 13.6117H20.5833L14.3796 19.811L4.52552 9.96455L9.52904 4.55992H19.2303L21.3684 6.87197H27.9485V6.18644L22.228 0H6.52616Z"
                      fill="#E1EFF5"
                    />
                  </svg>
                </Link>{" "}
                <Link to="/" className="d-none show-in-light-theme">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="26"
                    viewBox="0 0 29 26"
                    fill="none"
                  >
                    <path
                      d="M6.52616 0L0 7.05812V11.636L14.3809 26L28.7554 11.6437V9.79509H11.1969L12.4137 13.6117H20.5833L14.3796 19.811L4.52552 9.96455L9.52904 4.55992H19.2303L21.3684 6.87197H27.9485V6.18644L22.228 0H6.52616Z"
                      fill="#01141A"
                    />
                  </svg>
                </Link>{" "}
              </>
            )}
            <div className="twice-onmobile">
              {history.pathname === "/gemscommunityproject" ||
                history.pathname === "/survey" ? (
                ""
              ) : (
                <div className="dropdown lang-drop navbar-toggler">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {theme === "light-theme" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="8.89886"
                          stroke="#081519"
                          stroke-width="2.20229"
                        />
                        <path
                          d="M13.0451 10C13.0451 12.653 12.5971 15.0032 11.9146 16.6493C11.5723 17.4748 11.1927 18.0707 10.8275 18.4433C10.4659 18.8121 10.1868 18.8989 9.99986 18.8989C9.81293 18.8989 9.53382 18.8121 9.17224 18.4433C8.80702 18.0707 8.42741 17.4748 8.08512 16.6493C7.4026 15.0032 6.95466 12.653 6.95466 10C6.95466 7.34703 7.4026 4.99676 8.08512 3.35069C8.42741 2.52516 8.80702 1.92925 9.17224 1.55669C9.53382 1.18785 9.81293 1.10114 9.99986 1.10114C10.1868 1.10114 10.4659 1.18785 10.8275 1.55669C11.1927 1.92925 11.5723 2.52516 11.9146 3.35069C12.5971 4.99676 13.0451 7.34703 13.0451 10Z"
                          stroke="#081519"
                          stroke-width="2.20229"
                        />
                        <path
                          d="M18.5361 8.87793L1.95077 8.87793L1.95077 11.2194L18.5361 11.2194L18.5361 8.87793Z"
                          fill="#081519"
                        />
                        <path
                          d="M18.5361 8.87793L1.95077 8.87793L1.95077 11.2194L18.5361 11.2194L18.5361 8.87793Z"
                          fill="#081519"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="8.89886"
                          stroke="#D1DADD"
                          stroke-width="2.20229"
                        />
                        <path
                          d="M13.0455 10C13.0455 12.653 12.5976 15.0032 11.9151 16.6493C11.5728 17.4748 11.1932 18.0707 10.828 18.4433C10.4664 18.8121 10.1873 18.8989 10.0003 18.8989C9.81342 18.8989 9.53431 18.8121 9.17273 18.4433C8.80751 18.0707 8.4279 17.4748 8.08561 16.6493C7.40309 15.0032 6.95515 12.653 6.95515 10C6.95515 7.34703 7.40309 4.99676 8.08561 3.35069C8.4279 2.52516 8.80751 1.92925 9.17273 1.55669C9.53431 1.18785 9.81342 1.10114 10.0003 1.10114C10.1873 1.10114 10.4664 1.18785 10.828 1.55669C11.1932 1.92925 11.5728 2.52516 11.9151 3.35069C12.5976 4.99676 13.0455 7.34703 13.0455 10Z"
                          stroke="#D1DADD"
                          stroke-width="2.20229"
                        />
                        <path
                          d="M18.5361 8.87793L1.95077 8.87793L1.95077 11.2194L18.5361 11.2194L18.5361 8.87793Z"
                          fill="#D1DADD"
                        />
                        <path
                          d="M18.5361 8.87793L1.95077 8.87793L1.95077 11.2194L18.5361 11.2194L18.5361 8.87793Z"
                          fill="#D1DADD"
                        />
                      </svg>
                    )}
                    {languageactive}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {languages.map(({ code, name, country_code }) => (
                      <li key={country_code}>
                        <a
                          className={classNames("dropdown-item", {
                            disabled: currentLanguageCode === code,
                          })}
                          onClick={() => {
                            changelanguage(code);
                          }}
                        >
                          <span
                            className={`flag-icon flag-icon-${country_code} mx-2`}
                          ></span>
                          {name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <button
                class="navbar-toggler"
                type="button"
                onClick={handleShow1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M1.04785 5.55167L2.79687 2.41694L20.9526 2.33765L19.2036 5.47238L1.04785 5.55167Z"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M20.9527 16.762L19.2036 19.8967H1.04785L2.79687 16.762L20.9527 16.762Z"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M1.04785 12.6427L2.79687 9.508L20.9526 9.42871L19.2036 12.5634L1.04785 12.6427Z"
                    fill="#D9D9D9"
                  />
                </svg>
              </button>
            </div>
            <div
              class="collapse navbar-collapse justify-content-end h-100"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav me-auto">
                <li class="nav-item">
                  <a
                    class={name === "" ? "nav-link active" : "nav-link"}
                    aria-current="page"
                    href="/"
                  >
                    {t("Navbarnew1")} <span></span>
                  </a>
                </li>
                <li class="nav-item">
                  <Link
                    class={
                      name === "launchpad" ? "nav-link active" : "nav-link"
                    }
                    to="/launchpad"
                  >
                    {t("Footernew8")} <span></span>
                  </Link>
                </li>
                {/* <li class="nav-item">
                  <a class="nav-link" href="#">Capital <span></span></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Campus <span></span></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Quests <span></span></a>
                </li> */}
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://gems.trade/"
                    target="_blank"
                  >
                    {t("Footernew9")} <span></span>
                  </a>
                </li>
                {/* <li class="nav-item">
                  <a class="nav-link" href="#">Markets <span></span></a>

                </li> */}
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://staking.gems.vip/"
                    target="_blank"
                  >
                    {t("Footernew14")} <span></span>
                  </a>
                </li>
                <li class="nav-item">
                  <Link
                    class={name === "blog" ? "nav-link active" : "nav-link"}
                    to="/blog"
                  >
                    {t("Footernew24")} <span></span>
                  </Link>
                </li>
                {/* <li class="nav-item">
                  <Link
                    class={name === "nodes-miners" ? "nav-link active" : "nav-link"}
                    to="/nodes-miners"
                  >
                    Nodes & Miners <span></span>
                  </Link>
                </li> */}
                {/* <li class="nav-item">
                  <Link class={name === "gemscommunityproject" ? "nav-link active" : "nav-link"} to="/gemscommunityproject">Gems Community Project <span></span></Link>
                </li> */}
                {/* <li class="nav-item">
                  <Link
                    class={
                      name === "gemscommunityproject"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/gemscommunityproject"
                  >
                    Gems Community Project <span></span>
                  </Link>
                </li> */}
                {/* <li class="nav-item">
                  <a class="nav-link" href="#">Media Kit <span></span></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Support <span></span></a>
                </li> */}
              </ul>
              <div className="right-side">
                <div
                  className="toggle-container"
                  style={
                    theme === "light-theme" ? { background: "#0C2B33" } : {}
                  }
                >
                  <input
                    type="checkbox"
                    id="theme-toggle"
                    className="theme-toggle"
                    onChange={toggleTheme}
                    checked={theme === "dark-theme"}
                  />
                  <label htmlFor="theme-toggle" className="toggle-label">
                    <span className="moon">
                      {theme == "light-theme" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <mask id="path-1-inside-1_184_3720" fill="white">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.7596 0.743164C3.15377 1.19669 1.78276 2.39171 1.17307 4.08098C0.168832 6.86342 1.61035 9.93313 4.3928 10.9374C7.08598 11.9094 10.0483 10.59 11.1462 7.98123C10.1071 8.27472 8.96956 8.25769 7.87639 7.86315C5.09395 6.85891 3.65243 3.78921 4.65666 1.00676C4.68887 0.917505 4.72322 0.829626 4.7596 0.743164Z"
                            />
                          </mask>
                          <path
                            d="M1.17307 4.08098L-0.100904 3.62118L1.17307 4.08098ZM4.7596 0.743164L6.00796 1.26854L7.0995 -1.32506L4.39149 -0.560256L4.7596 0.743164ZM4.3928 10.9374L4.8526 9.66339H4.85259L4.3928 10.9374ZM11.1462 7.98123L12.3946 8.50662L13.4861 5.91301L10.7781 6.67781L11.1462 7.98123ZM7.87639 7.86315L8.33619 6.58918L8.33619 6.58918L7.87639 7.86315ZM4.65666 1.00676L3.38269 0.546964L3.38269 0.546965L4.65666 1.00676ZM2.44704 4.54078C2.9022 3.27966 3.92413 2.38651 5.12772 2.04658L4.39149 -0.560256C2.38341 0.00686955 0.663316 1.50375 -0.100904 3.62118L2.44704 4.54078ZM4.85259 9.66339C2.77375 8.9131 1.69674 6.61963 2.44704 4.54078L-0.100904 3.62118C-1.35908 7.10722 0.446962 10.9532 3.933 12.2113L4.85259 9.66339ZM9.89788 7.45585C9.07802 9.40392 6.86436 10.3895 4.8526 9.66339L3.933 12.2113C7.3076 13.4293 11.0186 11.7762 12.3946 8.50662L9.89788 7.45585ZM10.7781 6.67781C10.0022 6.89695 9.15454 6.88453 8.33619 6.58918L7.41659 9.13712C8.78457 9.63084 10.2119 9.65248 11.5143 9.28466L10.7781 6.67781ZM8.33619 6.58918C6.25734 5.83888 5.18034 3.54541 5.93063 1.46656L3.38269 0.546965C2.12452 4.033 3.93056 7.87894 7.41659 9.13712L8.33619 6.58918ZM5.93063 1.46656C5.95487 1.3994 5.98067 1.33338 6.00796 1.26854L3.51125 0.217784C3.46576 0.325869 3.42288 0.435615 3.38269 0.546964L5.93063 1.46656Z"
                            fill="#D1DBDF"
                            mask="url(#path-1-inside-1_184_3720)"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <mask id="path-1-inside-1_184_3699" fill="white">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.7596 0.743164C3.15377 1.19669 1.78276 2.39171 1.17307 4.08098C0.168832 6.86342 1.61035 9.93313 4.3928 10.9374C7.08598 11.9094 10.0483 10.59 11.1462 7.98123C10.1071 8.27472 8.96956 8.25769 7.87639 7.86315C5.09395 6.85891 3.65243 3.78921 4.65666 1.00676C4.68887 0.917505 4.72322 0.829626 4.7596 0.743164Z"
                            />
                          </mask>
                          <path
                            d="M1.17307 4.08098L-0.100904 3.62118L1.17307 4.08098ZM4.7596 0.743164L6.00796 1.26854L7.0995 -1.32506L4.39149 -0.560256L4.7596 0.743164ZM4.3928 10.9374L4.8526 9.66339H4.85259L4.3928 10.9374ZM11.1462 7.98123L12.3946 8.50662L13.4861 5.91301L10.7781 6.67781L11.1462 7.98123ZM7.87639 7.86315L8.33619 6.58918L8.33619 6.58918L7.87639 7.86315ZM4.65666 1.00676L3.38269 0.546964L3.38269 0.546965L4.65666 1.00676ZM2.44704 4.54078C2.9022 3.27966 3.92413 2.38651 5.12772 2.04658L4.39149 -0.560256C2.38341 0.00686955 0.663316 1.50375 -0.100904 3.62118L2.44704 4.54078ZM4.85259 9.66339C2.77375 8.9131 1.69674 6.61963 2.44704 4.54078L-0.100904 3.62118C-1.35908 7.10722 0.446962 10.9532 3.933 12.2113L4.85259 9.66339ZM9.89788 7.45585C9.07802 9.40392 6.86436 10.3895 4.8526 9.66339L3.933 12.2113C7.3076 13.4293 11.0186 11.7762 12.3946 8.50662L9.89788 7.45585ZM10.7781 6.67781C10.0022 6.89695 9.15454 6.88453 8.33619 6.58918L7.41659 9.13712C8.78457 9.63084 10.2119 9.65248 11.5143 9.28466L10.7781 6.67781ZM8.33619 6.58918C6.25734 5.83888 5.18034 3.54541 5.93063 1.46656L3.38269 0.546965C2.12452 4.033 3.93056 7.87894 7.41659 9.13712L8.33619 6.58918ZM5.93063 1.46656C5.95487 1.3994 5.98067 1.33338 6.00796 1.26854L3.51125 0.217784C3.46576 0.325869 3.42288 0.435615 3.38269 0.546964L5.93063 1.46656Z"
                            fill="#D1DBDF"
                            mask="url(#path-1-inside-1_184_3699)"
                          />
                        </svg>
                      )}
                    </span>
                    <span
                      className="sun"
                      style={
                        theme === "light-theme" ? { background: "#D1DBDF" } : {}
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <circle
                          cx="5.91193"
                          cy="6.01862"
                          r="2.06791"
                          stroke="#02171D"
                          strokeWidth="1.43218"
                        />
                        <path
                          d="M6.82129 10.136L6.84222 11.8167L5.17835 11.8374L5.15742 10.1567L6.82129 10.136Z"
                          fill="#02171D"
                        />
                        <path
                          d="M6.82129 0.162598L6.84222 1.84332L5.17835 1.86405L5.15742 0.183323L6.82129 0.162598Z"
                          fill="#02171D"
                        />
                        <path
                          d="M1.86377 6.82129L0.183045 6.84222L0.16232 5.17835L1.84304 5.15742L1.86377 6.82129Z"
                          fill="#02171D"
                        />
                        <path
                          d="M11.8374 6.82129L10.1567 6.84222L10.136 5.17835L11.8167 5.15742L11.8374 6.82129Z"
                          fill="#02171D"
                        />
                        <path
                          d="M2.49463 3.65649L1.29137 2.48285L2.45325 1.29166L3.65651 2.4653L2.49463 3.65649Z"
                          fill="#02171D"
                        />
                        <path
                          d="M9.54639 10.7083L8.34313 9.5346L9.50501 8.34341L10.7083 9.51706L9.54639 10.7083Z"
                          fill="#02171D"
                        />
                        <path
                          d="M8.34326 2.49463L9.51691 1.29137L10.7081 2.45325L9.53445 3.65651L8.34326 2.49463Z"
                          fill="#02171D"
                        />
                        <path
                          d="M1.29199 9.54663L2.46564 8.34338L3.65683 9.50526L2.48318 10.7085L1.29199 9.54663Z"
                          fill="#02171D"
                        />
                      </svg>
                    </span>
                    <span className="toggle-slider"></span>
                  </label>
                </div>
                {history.pathname === "/gemscommunityproject" ||
                  history.pathname === "/survey" ? (
                  ""
                ) : (
                  <div className="dropdown lang-drop">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {theme === "light-theme" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="8.89886"
                            stroke="#081519"
                            stroke-width="2.20229"
                          />
                          <path
                            d="M13.0451 10C13.0451 12.653 12.5971 15.0032 11.9146 16.6493C11.5723 17.4748 11.1927 18.0707 10.8275 18.4433C10.4659 18.8121 10.1868 18.8989 9.99986 18.8989C9.81293 18.8989 9.53382 18.8121 9.17224 18.4433C8.80702 18.0707 8.42741 17.4748 8.08512 16.6493C7.4026 15.0032 6.95466 12.653 6.95466 10C6.95466 7.34703 7.4026 4.99676 8.08512 3.35069C8.42741 2.52516 8.80702 1.92925 9.17224 1.55669C9.53382 1.18785 9.81293 1.10114 9.99986 1.10114C10.1868 1.10114 10.4659 1.18785 10.8275 1.55669C11.1927 1.92925 11.5723 2.52516 11.9146 3.35069C12.5971 4.99676 13.0451 7.34703 13.0451 10Z"
                            stroke="#081519"
                            stroke-width="2.20229"
                          />
                          <path
                            d="M18.5361 8.87793L1.95077 8.87793L1.95077 11.2194L18.5361 11.2194L18.5361 8.87793Z"
                            fill="#081519"
                          />
                          <path
                            d="M18.5361 8.87793L1.95077 8.87793L1.95077 11.2194L18.5361 11.2194L18.5361 8.87793Z"
                            fill="#081519"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="8.89886"
                            stroke="#D1DADD"
                            stroke-width="2.20229"
                          />
                          <path
                            d="M13.0455 10C13.0455 12.653 12.5976 15.0032 11.9151 16.6493C11.5728 17.4748 11.1932 18.0707 10.828 18.4433C10.4664 18.8121 10.1873 18.8989 10.0003 18.8989C9.81342 18.8989 9.53431 18.8121 9.17273 18.4433C8.80751 18.0707 8.4279 17.4748 8.08561 16.6493C7.40309 15.0032 6.95515 12.653 6.95515 10C6.95515 7.34703 7.40309 4.99676 8.08561 3.35069C8.4279 2.52516 8.80751 1.92925 9.17273 1.55669C9.53431 1.18785 9.81342 1.10114 10.0003 1.10114C10.1873 1.10114 10.4664 1.18785 10.828 1.55669C11.1932 1.92925 11.5728 2.52516 11.9151 3.35069C12.5976 4.99676 13.0455 7.34703 13.0455 10Z"
                            stroke="#D1DADD"
                            stroke-width="2.20229"
                          />
                          <path
                            d="M18.5361 8.87793L1.95077 8.87793L1.95077 11.2194L18.5361 11.2194L18.5361 8.87793Z"
                            fill="#D1DADD"
                          />
                          <path
                            d="M18.5361 8.87793L1.95077 8.87793L1.95077 11.2194L18.5361 11.2194L18.5361 8.87793Z"
                            fill="#D1DADD"
                          />
                        </svg>
                      )}
                      {languageactive}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {languages.map(({ code, name, country_code }) => (
                        <li key={country_code}>
                          <a
                            className={classNames("dropdown-item", {
                              disabled: currentLanguageCode === code,
                            })}
                            onClick={() => {
                              changelanguage(code);
                            }}
                          >
                            <span
                              className={`flag-icon flag-icon-${country_code} mx-2`}
                            ></span>
                            {name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {!isSurveyPage && (
                  <>
                    {account ? (
                      <>
                        <div class="dropdown nav-dropmenu">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {`${account?.slice(0, 6)}...${account?.slice(-4)}`}{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="6"
                              viewBox="0 0 10 6"
                              fill="none"
                            >
                              <path
                                d="M9.22726 0.5H4.77747H0.770515C0.084833 0.5 -0.258008 1.25891 0.227684 1.70379L3.92751 5.09274C4.52034 5.63575 5.48458 5.63575 6.07741 5.09274L7.48449 3.80389L9.77724 1.70379C10.2558 1.25891 9.91295 0.5 9.22726 0.5Z"
                                fill="#30F1B6"
                              />
                            </svg>
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <Link class="dropdown-item" to="/mypurchases">
                                <svg
                                  className="icon-color-both"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M14.375 6.99766V6.5625C14.375 4.60312 11.4195 3.125 7.5 3.125C3.58047 3.125 0.625 4.60312 0.625 6.5625V9.6875C0.625 11.3195 2.67578 12.6164 5.625 13.0047V13.4375C5.625 15.3969 8.58047 16.875 12.5 16.875C16.4195 16.875 19.375 15.3969 19.375 13.4375V10.3125C19.375 8.69531 17.3891 7.39687 14.375 6.99766ZM18.125 10.3125C18.125 11.3453 15.7195 12.5 12.5 12.5C12.2086 12.5 11.9195 12.4898 11.6344 12.4711C13.3195 11.857 14.375 10.8594 14.375 9.6875V8.26094C16.7086 8.60859 18.125 9.55234 18.125 10.3125ZM5.625 11.7383V9.87969C6.2467 9.96069 6.87304 10.0009 7.5 10C8.12696 10.0009 8.7533 9.96069 9.375 9.87969V11.7383C8.75422 11.83 8.12751 11.8757 7.5 11.875C6.87249 11.8757 6.24578 11.83 5.625 11.7383ZM13.125 8.58828V9.6875C13.125 10.343 12.1555 11.0469 10.625 11.4742V9.64844C11.6336 9.40391 12.4875 9.03984 13.125 8.58828ZM7.5 4.375C10.7195 4.375 13.125 5.52969 13.125 6.5625C13.125 7.59531 10.7195 8.75 7.5 8.75C4.28047 8.75 1.875 7.59531 1.875 6.5625C1.875 5.52969 4.28047 4.375 7.5 4.375ZM1.875 9.6875V8.58828C2.5125 9.03984 3.36641 9.40391 4.375 9.64844V11.4742C2.84453 11.0469 1.875 10.343 1.875 9.6875ZM6.875 13.4375V13.1117C7.08047 13.1195 7.28828 13.125 7.5 13.125C7.80312 13.125 8.09922 13.1148 8.38984 13.0977C8.7127 13.2132 9.04157 13.3113 9.375 13.3914V15.2242C7.84453 14.7969 6.875 14.093 6.875 13.4375ZM10.625 15.4883V13.625C11.2465 13.7085 11.8729 13.7503 12.5 13.75C13.127 13.7509 13.7533 13.7107 14.375 13.6297V15.4883C13.1316 15.6706 11.8684 15.6706 10.625 15.4883ZM15.625 15.2242V13.3984C16.6336 13.1539 17.4875 12.7898 18.125 12.3383V13.4375C18.125 14.093 17.1555 14.7969 15.625 15.2242Z"
                                    fill="#30F1B6"
                                  />
                                </svg>
                                My Purchases
                              </Link>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://creators.gems.vip/"
                                target="_blank"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className="icon-color-both"
                                >
                                  <path
                                    d="M8.125 3.125H4.375C4.04348 3.125 3.72554 3.2567 3.49112 3.49112C3.2567 3.72554 3.125 4.04348 3.125 4.375V8.125C3.125 8.45652 3.2567 8.77446 3.49112 9.00888C3.72554 9.2433 4.04348 9.375 4.375 9.375H8.125C8.45652 9.375 8.77446 9.2433 9.00888 9.00888C9.2433 8.77446 9.375 8.45652 9.375 8.125V4.375C9.375 4.04348 9.2433 3.72554 9.00888 3.49112C8.77446 3.2567 8.45652 3.125 8.125 3.125ZM8.125 8.125H4.375V4.375H8.125V8.125ZM15.625 3.125H11.875C11.5435 3.125 11.2255 3.2567 10.9911 3.49112C10.7567 3.72554 10.625 4.04348 10.625 4.375V8.125C10.625 8.45652 10.7567 8.77446 10.9911 9.00888C11.2255 9.2433 11.5435 9.375 11.875 9.375H15.625C15.9565 9.375 16.2745 9.2433 16.5089 9.00888C16.7433 8.77446 16.875 8.45652 16.875 8.125V4.375C16.875 4.04348 16.7433 3.72554 16.5089 3.49112C16.2745 3.2567 15.9565 3.125 15.625 3.125ZM15.625 8.125H11.875V4.375H15.625V8.125ZM8.125 10.625H4.375C4.04348 10.625 3.72554 10.7567 3.49112 10.9911C3.2567 11.2255 3.125 11.5435 3.125 11.875V15.625C3.125 15.9565 3.2567 16.2745 3.49112 16.5089C3.72554 16.7433 4.04348 16.875 4.375 16.875H8.125C8.45652 16.875 8.77446 16.7433 9.00888 16.5089C9.2433 16.2745 9.375 15.9565 9.375 15.625V11.875C9.375 11.5435 9.2433 11.2255 9.00888 10.9911C8.77446 10.7567 8.45652 10.625 8.125 10.625ZM8.125 15.625H4.375V11.875H8.125V15.625ZM15.625 10.625H11.875C11.5435 10.625 11.2255 10.7567 10.9911 10.9911C10.7567 11.2255 10.625 11.5435 10.625 11.875V15.625C10.625 15.9565 10.7567 16.2745 10.9911 16.5089C11.2255 16.7433 11.5435 16.875 11.875 16.875H15.625C15.9565 16.875 16.2745 16.7433 16.5089 16.5089C16.7433 16.2745 16.875 15.9565 16.875 15.625V11.875C16.875 11.5435 16.7433 11.2255 16.5089 10.9911C16.2745 10.7567 15.9565 10.625 15.625 10.625ZM15.625 15.625H11.875V11.875H15.625V15.625Z"
                                    fill="#30F1B6"
                                  />
                                </svg>
                                Creator Dashboard{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  className="icon-color-both1"
                                >
                                  <path
                                    d="M12.25 7.58333V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V2.91667C1.75 2.60725 1.87292 2.3105 2.09171 2.09171C2.3105 1.87292 2.60725 1.75 2.91667 1.75H6.41667"
                                    stroke="#30F1B6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12.25 1.75L7 7"
                                    stroke="#30F1B6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8.75 1.75H12.25V5.25"
                                    stroke="#30F1B6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                onClick={disconnectWallet}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M9.375 16.875C9.375 17.0408 9.30915 17.1997 9.19194 17.3169C9.07473 17.4342 8.91576 17.5 8.75 17.5H3.75C3.58424 17.5 3.42527 17.4342 3.30806 17.3169C3.19085 17.1997 3.125 17.0408 3.125 16.875V3.125C3.125 2.95924 3.19085 2.80027 3.30806 2.68306C3.42527 2.56585 3.58424 2.5 3.75 2.5H8.75C8.91576 2.5 9.07473 2.56585 9.19194 2.68306C9.30915 2.80027 9.375 2.95924 9.375 3.125C9.375 3.29076 9.30915 3.44973 9.19194 3.56694C9.07473 3.68415 8.91576 3.75 8.75 3.75H4.375V16.25H8.75C8.91576 16.25 9.07473 16.3158 9.19194 16.4331C9.30915 16.5503 9.375 16.7092 9.375 16.875ZM17.9422 9.55781L14.8172 6.43281C14.6999 6.31554 14.5409 6.24965 14.375 6.24965C14.2091 6.24965 14.0501 6.31554 13.9328 6.43281C13.8155 6.55009 13.7497 6.70915 13.7497 6.875C13.7497 7.04085 13.8155 7.19991 13.9328 7.31719L15.9914 9.375H8.75C8.58424 9.375 8.42527 9.44085 8.30806 9.55806C8.19085 9.67527 8.125 9.83424 8.125 10C8.125 10.1658 8.19085 10.3247 8.30806 10.4419C8.42527 10.5592 8.58424 10.625 8.75 10.625H15.9914L13.9328 12.6828C13.8155 12.8001 13.7497 12.9591 13.7497 13.125C13.7497 13.2909 13.8155 13.4499 13.9328 13.5672C14.0501 13.6845 14.2091 13.7503 14.375 13.7503C14.5409 13.7503 14.6999 13.6845 14.8172 13.5672L17.9422 10.4422C18.0003 10.3841 18.0464 10.3152 18.0779 10.2393C18.1093 10.1635 18.1255 10.0821 18.1255 10C18.1255 9.91787 18.1093 9.83654 18.0779 9.76066C18.0464 9.68479 18.0003 9.61586 17.9422 9.55781Z"
                                    fill="#FC4F52"
                                  />
                                </svg>
                                Disconnect
                              </a>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <a onClick={handleShowWallet} className="connect-btn">
                        Connect Wallet
                      </a>
                    )}
                  </>
                )}

                {isSurveyPage && (
                  <>
                    {account ? (
                      <>
                        <div class="dropdown nav-dropmenu">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {`${account?.slice(0, 6)}...${account?.slice(-4)}`}{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="6"
                              viewBox="0 0 10 6"
                              fill="none"
                            >
                              <path
                                d="M9.22726 0.5H4.77747H0.770515C0.084833 0.5 -0.258008 1.25891 0.227684 1.70379L3.92751 5.09274C4.52034 5.63575 5.48458 5.63575 6.07741 5.09274L7.48449 3.80389L9.77724 1.70379C10.2558 1.25891 9.91295 0.5 9.22726 0.5Z"
                                fill="#30F1B6"
                              />
                            </svg>
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                onClick={disconnectWallet}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M9.375 16.875C9.375 17.0408 9.30915 17.1997 9.19194 17.3169C9.07473 17.4342 8.91576 17.5 8.75 17.5H3.75C3.58424 17.5 3.42527 17.4342 3.30806 17.3169C3.19085 17.1997 3.125 17.0408 3.125 16.875V3.125C3.125 2.95924 3.19085 2.80027 3.30806 2.68306C3.42527 2.56585 3.58424 2.5 3.75 2.5H8.75C8.91576 2.5 9.07473 2.56585 9.19194 2.68306C9.30915 2.80027 9.375 2.95924 9.375 3.125C9.375 3.29076 9.30915 3.44973 9.19194 3.56694C9.07473 3.68415 8.91576 3.75 8.75 3.75H4.375V16.25H8.75C8.91576 16.25 9.07473 16.3158 9.19194 16.4331C9.30915 16.5503 9.375 16.7092 9.375 16.875ZM17.9422 9.55781L14.8172 6.43281C14.6999 6.31554 14.5409 6.24965 14.375 6.24965C14.2091 6.24965 14.0501 6.31554 13.9328 6.43281C13.8155 6.55009 13.7497 6.70915 13.7497 6.875C13.7497 7.04085 13.8155 7.19991 13.9328 7.31719L15.9914 9.375H8.75C8.58424 9.375 8.42527 9.44085 8.30806 9.55806C8.19085 9.67527 8.125 9.83424 8.125 10C8.125 10.1658 8.19085 10.3247 8.30806 10.4419C8.42527 10.5592 8.58424 10.625 8.75 10.625H15.9914L13.9328 12.6828C13.8155 12.8001 13.7497 12.9591 13.7497 13.125C13.7497 13.2909 13.8155 13.4499 13.9328 13.5672C14.0501 13.6845 14.2091 13.7503 14.375 13.7503C14.5409 13.7503 14.6999 13.6845 14.8172 13.5672L17.9422 10.4422C18.0003 10.3841 18.0464 10.3152 18.0779 10.2393C18.1093 10.1635 18.1255 10.0821 18.1255 10C18.1255 9.91787 18.1093 9.83654 18.0779 9.76066C18.0464 9.68479 18.0003 9.61586 17.9422 9.55781Z"
                                    fill="#FC4F52"
                                  />
                                </svg>
                                Disconnect
                              </a>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/* {animate && <div className={`theme-overlay ${theme === "light-theme" ? "dark" : "light"}`}></div>} */}

      <Modal
        className="common-modal-style wallet-modal"
        show={showwallet}
        onHide={handleCloseWallet}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent">
            <a
              onClick={() => {
                connectWallet();
                // setConnect(true);
                handleCloseWallet();
              }}
              className="metamask-hideonmobile"
            >
              <img
                src="\assets\navbar\metamask.svg"
                alt="img"
                className="img-fluid"
              />
              Metamask
            </a>
            <a
              onClick={() => {
                trustWallet();
                // setConnect(true);
                handleCloseWallet();
                handleClose1();
              }}
            >
              <img
                src="\assets\navbar\wallet.svg"
                alt="img"
                className="img-fluid"
              />
              WalletConnect
            </a>
            {isBrowser && (
              <a
                onClick={() => {
                  coinbase();
                  // setConnect(true);
                  handleCloseWallet();
                  handleClose1();
                }}
              >
                <img
                  src="\assets\navbar\base.svg"
                  alt="img"
                  className="img-fluid"
                />
                CoinBase
              </a>
            )}

            {/* {isBrowser ? (
              <a
                onClick={() => {
                  coinbase();
                  // setConnect(true);
                  handleCloseWallet();
                  handleClose1();
                }}
              >
                <img
                  src="\assets\navbar\base.svg"
                  alt="img"
                  className="img-fluid"
                />
                CoinBase
              </a>
            ) : (
              <>
                {browserName === "Safari" ||
                browserName === "Mobile Safari" ||
                browserName === "Brave" ||
                browserName === "Firefox" ||
                browserName === "Chrome" ? (
                  <a
                    href={`https://go.cb-w.com/dapp?cb_url=${coinbaseLink}`}
                    target="_blank"
                  >
                    <img
                      src="\assets\navbar\base.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <h6>Coinbase </h6>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      coinbase();
                      // setConnect(true);
                      handleCloseWallet();
                      handleClose1();
                    }}
                  >
                    <img
                      src="\assets\navbar\base.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    CoinBase
                  </a>
                )}
              </>
            )} */}
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas
        className="nav-offcanvas"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Body>
          <div className="parent-grid">
            <div className="left-side-grid">
              <Link to="/">
                <img
                  src="\mini-logo-grey.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Link>
            </div>
            <div className="mid-side-grid">
              <div className="upper-grid">
                <img
                  src="\gems-logo.svg"
                  alt="img"
                  className="img-fluid gems-logo"
                />
                <a href="https://leaders.gems.vip/" target="_blank">
                  {t("Sign-in")}
                </a>
              </div>
              <div className="bottom-grid">
                <div className="single-grid">
                  <div className="inner-top">{/* <h6>Features</h6> */}</div>
                  <div className="inner-bottom">
                    <p>© 2024 GEMS</p>
                  </div>
                </div>
                <div className="single-grid">
                  <div className="inner-top">{/* <h6>About</h6> */}</div>
                  <div className="inner-bottom">
                    <Link to="/signupforproject">{t("Where-btn1")}</Link>
                  </div>
                </div>
                <div className="single-grid">
                  <div className="inner-top">{/* <h6>Projects</h6> */}</div>
                  <div className="inner-bottom">
                    {/* <Link to="/signupforleader">Sign up as Leader</Link> */}
                  </div>
                </div>
                <div className="single-grid">
                  <div className="inner-top">{/* <h6>How it Works</h6> */}</div>
                  <div className="inner-bottom">
                    <div className="social-icon">
                      <h6>{t("Join")}</h6>
                      <div className="inner">
                        <a href="https://twitter.com/Gems_VIP_" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                          >
                            <path
                              d="M0.0316953 0.5L5.05085 7.67027L0 13.5H1.13674L5.55875 8.39598L9.13162 13.5H13L7.69845 5.92642L12.3997 0.5H11.263L7.19054 5.2007L3.90008 0.5H0.0316953ZM1.70336 1.39462H3.48051L11.3281 12.6052H9.55093L1.70336 1.39462Z"
                              fill="#AEAEAE"
                            />
                          </svg>
                        </a>
                        <a href="http://t.me/GemsVIPOfficial" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                          >
                            <path
                              d="M0.893682 6.23547C4.38334 4.68313 6.71032 3.65973 7.87463 3.16527C11.199 1.7535 11.8897 1.50826 12.34 1.50008C12.439 1.49838 12.6604 1.52344 12.8038 1.64226C12.9249 1.7426 12.9583 1.87813 12.9742 1.97325C12.9901 2.06838 13.01 2.28508 12.9942 2.4544C12.8141 4.38701 12.0346 9.07694 11.638 11.2415C11.4702 12.1574 11.1398 12.4645 10.8199 12.4945C10.1248 12.5599 9.59692 12.0255 8.92363 11.5749C7.87007 10.8697 7.27488 10.4308 6.25221 9.74269C5.07034 8.94748 5.8365 8.51043 6.51004 7.79615C6.68631 7.60923 9.74916 4.76477 9.80844 4.50673C9.81586 4.47446 9.82274 4.35417 9.75275 4.29065C9.68275 4.22713 9.57945 4.24885 9.5049 4.26612C9.39923 4.29061 7.71615 5.42645 4.45566 7.67363C3.97792 8.00858 3.54521 8.17177 3.1575 8.16322C2.73009 8.15379 1.90793 7.91648 1.29673 7.71363C0.547079 7.46482 -0.0487324 7.33328 0.00314937 6.91073C0.0301726 6.69064 0.327017 6.46555 0.893682 6.23547Z"
                              fill="#AEAEAE"
                            />
                          </svg>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/gemsvip/"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_315_274)">
                              <path
                                d="M11.9583 0.125C12.3341 0.125 12.6944 0.274255 12.9601 0.539932C13.2257 0.805609 13.375 1.16594 13.375 1.54167V11.4583C13.375 11.8341 13.2257 12.1944 12.9601 12.4601C12.6944 12.7257 12.3341 12.875 11.9583 12.875H2.04167C1.66594 12.875 1.30561 12.7257 1.03993 12.4601C0.774255 12.1944 0.625 11.8341 0.625 11.4583V1.54167C0.625 1.16594 0.774255 0.805609 1.03993 0.539932C1.30561 0.274255 1.66594 0.125 2.04167 0.125H11.9583ZM11.6042 11.1042V7.35C11.6042 6.73757 11.3609 6.15023 10.9278 5.71717C10.4948 5.28412 9.90743 5.04083 9.295 5.04083C8.69292 5.04083 7.99167 5.40917 7.65167 5.96167V5.17542H5.67542V11.1042H7.65167V7.61208C7.65167 7.06667 8.09083 6.62042 8.63625 6.62042C8.89926 6.62042 9.15149 6.7249 9.33746 6.91087C9.52344 7.09684 9.62792 7.34908 9.62792 7.61208V11.1042H11.6042ZM3.37333 4.06333C3.68894 4.06333 3.99162 3.93796 4.21479 3.71479C4.43796 3.49162 4.56333 3.18894 4.56333 2.87333C4.56333 2.21458 4.03208 1.67625 3.37333 1.67625C3.05585 1.67625 2.75136 1.80237 2.52687 2.02687C2.30237 2.25136 2.17625 2.55585 2.17625 2.87333C2.17625 3.53208 2.71458 4.06333 3.37333 4.06333ZM4.35792 11.1042V5.17542H2.39583V11.1042H4.35792Z"
                                fill="#878787"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_315_274">
                                <rect
                                  width="13"
                                  height="13"
                                  fill="white"
                                  transform="translate(0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-grid">
              <a onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18"
                    stroke="#0B0B0B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="nav-offcanvas-mobile"
        placement="bottom"
        show={show1}
        onHide={handleClose1}
      >
        <Offcanvas.Body>
          <div className="main-header">
            <a href="/">
              {theme === "light-theme" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="26"
                  viewBox="0 0 29 26"
                  fill="none"
                >
                  <path
                    d="M6.52616 0L0 7.05812V11.636L14.3809 26L28.7554 11.6437V9.79509H11.1969L12.4137 13.6117H20.5833L14.3796 19.811L4.52552 9.96455L9.52904 4.55992H19.2303L21.3684 6.87197H27.9485V6.18644L22.228 0H6.52616Z"
                    fill="#01141A"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="26"
                  viewBox="0 0 29 26"
                  fill="none"
                >
                  <path
                    d="M6.52616 0L0 7.05812V11.636L14.3809 26L28.7554 11.6437V9.79509H11.1969L12.4137 13.6117H20.5833L14.3796 19.811L4.52552 9.96455L9.52904 4.55992H19.2303L21.3684 6.87197H27.9485V6.18644L22.228 0H6.52616Z"
                    fill="#E1EFF5"
                  />
                </svg>
              )}
            </a>
            <a onClick={handleClose1}>
              {theme === "light-theme" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M19.1055 2.65942L15.666 2.65942L11.0006 10.9937L14.44 10.9937L19.1055 2.65942Z"
                    fill="#01141A"
                  />
                  <path
                    d="M19.1055 19.3455L15.666 19.3455L11.0006 11.0112L13.1683 9.57039L14.44 11.0112L19.1055 19.3455Z"
                    fill="#01141A"
                  />
                  <path
                    d="M2.90137 2.65942L6.3408 2.65942L11.0062 10.9937L7.5668 10.9937L2.90137 2.65942Z"
                    fill="#01141A"
                  />
                  <path
                    d="M2.90137 19.3455L6.3408 19.3455L11.0062 11.0112L8.83856 9.57039L7.5668 11.0112L2.90137 19.3455Z"
                    fill="#01141A"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M19.1045 2.65942L15.6651 2.65942L10.9996 10.9937L14.4391 10.9937L19.1045 2.65942Z"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M19.1045 19.3455L15.6651 19.3455L10.9996 11.0112L13.1673 9.57039L14.4391 11.0112L19.1045 19.3455Z"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M2.90088 2.65942L6.34031 2.65942L11.0058 10.9937L7.56632 10.9937L2.90088 2.65942Z"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M2.90088 19.3455L6.34031 19.3455L11.0058 11.0112L8.83807 9.57039L7.56631 11.0112L2.90088 19.3455Z"
                    fill="#D9D9D9"
                  />
                </svg>
              )}
            </a>
          </div>
          <div className="bottom-body">
            <div className="upper-links">
              <a
                href="/"
                style={
                  name === ""
                    ? { color: "var(--V6-Dark-Theme-Green-Color)" }
                    : {}
                }
              >
                {t("Navbarnew1")}
              </a>
              <Link
                style={
                  name === "launchpad"
                    ? { color: "var(--V6-Dark-Theme-Green-Color)" }
                    : {}
                }
                to="/launchpad"
              >
                {t("Footernew8")}
              </Link>
              {/* <a href="#">Capital</a>
              <a href="#">Campus</a>
              <a href="#">Quests</a> */}
              <a href="https://gems.trade/" target="_blank">
                {t("Footernew9")}
              </a>
              {/* <a href="#">Markets</a> */}
              <a href="https://staking.gems.vip/" target="_blank">
                {t("Footernew14")}
              </a>
              <Link
                style={
                  name === "blog"
                    ? { color: "var(--V6-Dark-Theme-Green-Color)" }
                    : {}
                }
                to="/blog"
              >
                {t("Footernew24")}
              </Link>
              {/* <Link
                style={
                  name === "nodes-miners"
                    ? { color: "var(--V6-Dark-Theme-Green-Color)" }
                    : {}
                }
                to="/nodes-miners"
              >
                Nodes & Miners <span></span>
              </Link> */}
              {/* <a href="#">Media Kit</a>
              <a href="#">Support</a> */}
              {/* <Link class={name === "gemscommunityproject" ? "nav-link active" : "nav-link"} to="/gemscommunityproject">Gems Community Project <span></span></Link> */}
              {/* <Link
                class={
                  name === "gemscommunityproject"
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/gemscommunityproject"
              >
                Gems Community Project <span></span>
              </Link> */}

              {account && (
                <>
                  {" "}
                  <Link to="/mypurchases" className="mypurchases">
                    My Purchases
                    <svg
                      className="icon-color-both"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M14.375 6.99766V6.5625C14.375 4.60312 11.4195 3.125 7.5 3.125C3.58047 3.125 0.625 4.60312 0.625 6.5625V9.6875C0.625 11.3195 2.67578 12.6164 5.625 13.0047V13.4375C5.625 15.3969 8.58047 16.875 12.5 16.875C16.4195 16.875 19.375 15.3969 19.375 13.4375V10.3125C19.375 8.69531 17.3891 7.39687 14.375 6.99766ZM18.125 10.3125C18.125 11.3453 15.7195 12.5 12.5 12.5C12.2086 12.5 11.9195 12.4898 11.6344 12.4711C13.3195 11.857 14.375 10.8594 14.375 9.6875V8.26094C16.7086 8.60859 18.125 9.55234 18.125 10.3125ZM5.625 11.7383V9.87969C6.2467 9.96069 6.87304 10.0009 7.5 10C8.12696 10.0009 8.7533 9.96069 9.375 9.87969V11.7383C8.75422 11.83 8.12751 11.8757 7.5 11.875C6.87249 11.8757 6.24578 11.83 5.625 11.7383ZM13.125 8.58828V9.6875C13.125 10.343 12.1555 11.0469 10.625 11.4742V9.64844C11.6336 9.40391 12.4875 9.03984 13.125 8.58828ZM7.5 4.375C10.7195 4.375 13.125 5.52969 13.125 6.5625C13.125 7.59531 10.7195 8.75 7.5 8.75C4.28047 8.75 1.875 7.59531 1.875 6.5625C1.875 5.52969 4.28047 4.375 7.5 4.375ZM1.875 9.6875V8.58828C2.5125 9.03984 3.36641 9.40391 4.375 9.64844V11.4742C2.84453 11.0469 1.875 10.343 1.875 9.6875ZM6.875 13.4375V13.1117C7.08047 13.1195 7.28828 13.125 7.5 13.125C7.80312 13.125 8.09922 13.1148 8.38984 13.0977C8.7127 13.2132 9.04157 13.3113 9.375 13.3914V15.2242C7.84453 14.7969 6.875 14.093 6.875 13.4375ZM10.625 15.4883V13.625C11.2465 13.7085 11.8729 13.7503 12.5 13.75C13.127 13.7509 13.7533 13.7107 14.375 13.6297V15.4883C13.1316 15.6706 11.8684 15.6706 10.625 15.4883ZM15.625 15.2242V13.3984C16.6336 13.1539 17.4875 12.7898 18.125 12.3383V13.4375C18.125 14.093 17.1555 14.7969 15.625 15.2242Z"
                        fill="#30F1B6"
                      />
                    </svg>
                  </Link>
                  <a
                    class="dropdown-item"
                    href="https://creators.gems.vip/"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M8.125 3.125H4.375C4.04348 3.125 3.72554 3.2567 3.49112 3.49112C3.2567 3.72554 3.125 4.04348 3.125 4.375V8.125C3.125 8.45652 3.2567 8.77446 3.49112 9.00888C3.72554 9.2433 4.04348 9.375 4.375 9.375H8.125C8.45652 9.375 8.77446 9.2433 9.00888 9.00888C9.2433 8.77446 9.375 8.45652 9.375 8.125V4.375C9.375 4.04348 9.2433 3.72554 9.00888 3.49112C8.77446 3.2567 8.45652 3.125 8.125 3.125ZM8.125 8.125H4.375V4.375H8.125V8.125ZM15.625 3.125H11.875C11.5435 3.125 11.2255 3.2567 10.9911 3.49112C10.7567 3.72554 10.625 4.04348 10.625 4.375V8.125C10.625 8.45652 10.7567 8.77446 10.9911 9.00888C11.2255 9.2433 11.5435 9.375 11.875 9.375H15.625C15.9565 9.375 16.2745 9.2433 16.5089 9.00888C16.7433 8.77446 16.875 8.45652 16.875 8.125V4.375C16.875 4.04348 16.7433 3.72554 16.5089 3.49112C16.2745 3.2567 15.9565 3.125 15.625 3.125ZM15.625 8.125H11.875V4.375H15.625V8.125ZM8.125 10.625H4.375C4.04348 10.625 3.72554 10.7567 3.49112 10.9911C3.2567 11.2255 3.125 11.5435 3.125 11.875V15.625C3.125 15.9565 3.2567 16.2745 3.49112 16.5089C3.72554 16.7433 4.04348 16.875 4.375 16.875H8.125C8.45652 16.875 8.77446 16.7433 9.00888 16.5089C9.2433 16.2745 9.375 15.9565 9.375 15.625V11.875C9.375 11.5435 9.2433 11.2255 9.00888 10.9911C8.77446 10.7567 8.45652 10.625 8.125 10.625ZM8.125 15.625H4.375V11.875H8.125V15.625ZM15.625 10.625H11.875C11.5435 10.625 11.2255 10.7567 10.9911 10.9911C10.7567 11.2255 10.625 11.5435 10.625 11.875V15.625C10.625 15.9565 10.7567 16.2745 10.9911 16.5089C11.2255 16.7433 11.5435 16.875 11.875 16.875H15.625C15.9565 16.875 16.2745 16.7433 16.5089 16.5089C16.7433 16.2745 16.875 15.9565 16.875 15.625V11.875C16.875 11.5435 16.7433 11.2255 16.5089 10.9911C16.2745 10.7567 15.9565 10.625 15.625 10.625ZM15.625 15.625H11.875V11.875H15.625V15.625Z"
                        fill="#30F1B6"
                      />
                    </svg>
                    Creator Dashboard{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M12.25 7.58333V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V2.91667C1.75 2.60725 1.87292 2.3105 2.09171 2.09171C2.3105 1.87292 2.60725 1.75 2.91667 1.75H6.41667"
                        stroke="#30F1B6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.25 1.75L7 7"
                        stroke="#30F1B6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.75 1.75H12.25V5.25"
                        stroke="#30F1B6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </>
              )}
            </div>
            <div className="twice-items">
              <p>{t("Theme")}</p>
              <div
                className="toggle-container"
                style={theme === "light-theme" ? { background: "#0C2B33" } : {}}
              >
                <input
                  type="checkbox"
                  id="theme-toggle"
                  className="theme-toggle"
                  onChange={toggleTheme}
                  checked={theme === "dark-theme"}
                />
                <label htmlFor="theme-toggle" className="toggle-label">
                  <span className="moon">
                    {theme == "light-theme" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <mask id="path-1-inside-1_184_3720" fill="white">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.7596 0.743164C3.15377 1.19669 1.78276 2.39171 1.17307 4.08098C0.168832 6.86342 1.61035 9.93313 4.3928 10.9374C7.08598 11.9094 10.0483 10.59 11.1462 7.98123C10.1071 8.27472 8.96956 8.25769 7.87639 7.86315C5.09395 6.85891 3.65243 3.78921 4.65666 1.00676C4.68887 0.917505 4.72322 0.829626 4.7596 0.743164Z"
                          />
                        </mask>
                        <path
                          d="M1.17307 4.08098L-0.100904 3.62118L1.17307 4.08098ZM4.7596 0.743164L6.00796 1.26854L7.0995 -1.32506L4.39149 -0.560256L4.7596 0.743164ZM4.3928 10.9374L4.8526 9.66339H4.85259L4.3928 10.9374ZM11.1462 7.98123L12.3946 8.50662L13.4861 5.91301L10.7781 6.67781L11.1462 7.98123ZM7.87639 7.86315L8.33619 6.58918L8.33619 6.58918L7.87639 7.86315ZM4.65666 1.00676L3.38269 0.546964L3.38269 0.546965L4.65666 1.00676ZM2.44704 4.54078C2.9022 3.27966 3.92413 2.38651 5.12772 2.04658L4.39149 -0.560256C2.38341 0.00686955 0.663316 1.50375 -0.100904 3.62118L2.44704 4.54078ZM4.85259 9.66339C2.77375 8.9131 1.69674 6.61963 2.44704 4.54078L-0.100904 3.62118C-1.35908 7.10722 0.446962 10.9532 3.933 12.2113L4.85259 9.66339ZM9.89788 7.45585C9.07802 9.40392 6.86436 10.3895 4.8526 9.66339L3.933 12.2113C7.3076 13.4293 11.0186 11.7762 12.3946 8.50662L9.89788 7.45585ZM10.7781 6.67781C10.0022 6.89695 9.15454 6.88453 8.33619 6.58918L7.41659 9.13712C8.78457 9.63084 10.2119 9.65248 11.5143 9.28466L10.7781 6.67781ZM8.33619 6.58918C6.25734 5.83888 5.18034 3.54541 5.93063 1.46656L3.38269 0.546965C2.12452 4.033 3.93056 7.87894 7.41659 9.13712L8.33619 6.58918ZM5.93063 1.46656C5.95487 1.3994 5.98067 1.33338 6.00796 1.26854L3.51125 0.217784C3.46576 0.325869 3.42288 0.435615 3.38269 0.546964L5.93063 1.46656Z"
                          fill="#D1DBDF"
                          mask="url(#path-1-inside-1_184_3720)"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <mask id="path-1-inside-1_184_3699" fill="white">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.7596 0.743164C3.15377 1.19669 1.78276 2.39171 1.17307 4.08098C0.168832 6.86342 1.61035 9.93313 4.3928 10.9374C7.08598 11.9094 10.0483 10.59 11.1462 7.98123C10.1071 8.27472 8.96956 8.25769 7.87639 7.86315C5.09395 6.85891 3.65243 3.78921 4.65666 1.00676C4.68887 0.917505 4.72322 0.829626 4.7596 0.743164Z"
                          />
                        </mask>
                        <path
                          d="M1.17307 4.08098L-0.100904 3.62118L1.17307 4.08098ZM4.7596 0.743164L6.00796 1.26854L7.0995 -1.32506L4.39149 -0.560256L4.7596 0.743164ZM4.3928 10.9374L4.8526 9.66339H4.85259L4.3928 10.9374ZM11.1462 7.98123L12.3946 8.50662L13.4861 5.91301L10.7781 6.67781L11.1462 7.98123ZM7.87639 7.86315L8.33619 6.58918L8.33619 6.58918L7.87639 7.86315ZM4.65666 1.00676L3.38269 0.546964L3.38269 0.546965L4.65666 1.00676ZM2.44704 4.54078C2.9022 3.27966 3.92413 2.38651 5.12772 2.04658L4.39149 -0.560256C2.38341 0.00686955 0.663316 1.50375 -0.100904 3.62118L2.44704 4.54078ZM4.85259 9.66339C2.77375 8.9131 1.69674 6.61963 2.44704 4.54078L-0.100904 3.62118C-1.35908 7.10722 0.446962 10.9532 3.933 12.2113L4.85259 9.66339ZM9.89788 7.45585C9.07802 9.40392 6.86436 10.3895 4.8526 9.66339L3.933 12.2113C7.3076 13.4293 11.0186 11.7762 12.3946 8.50662L9.89788 7.45585ZM10.7781 6.67781C10.0022 6.89695 9.15454 6.88453 8.33619 6.58918L7.41659 9.13712C8.78457 9.63084 10.2119 9.65248 11.5143 9.28466L10.7781 6.67781ZM8.33619 6.58918C6.25734 5.83888 5.18034 3.54541 5.93063 1.46656L3.38269 0.546965C2.12452 4.033 3.93056 7.87894 7.41659 9.13712L8.33619 6.58918ZM5.93063 1.46656C5.95487 1.3994 5.98067 1.33338 6.00796 1.26854L3.51125 0.217784C3.46576 0.325869 3.42288 0.435615 3.38269 0.546964L5.93063 1.46656Z"
                          fill="#D1DBDF"
                          mask="url(#path-1-inside-1_184_3699)"
                        />
                      </svg>
                    )}
                  </span>
                  <span
                    className="sun"
                    style={
                      theme === "light-theme" ? { background: "#D1DBDF" } : {}
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <circle
                        cx="5.91193"
                        cy="6.01862"
                        r="2.06791"
                        stroke="#02171D"
                        strokeWidth="1.43218"
                      />
                      <path
                        d="M6.82129 10.136L6.84222 11.8167L5.17835 11.8374L5.15742 10.1567L6.82129 10.136Z"
                        fill="#02171D"
                      />
                      <path
                        d="M6.82129 0.162598L6.84222 1.84332L5.17835 1.86405L5.15742 0.183323L6.82129 0.162598Z"
                        fill="#02171D"
                      />
                      <path
                        d="M1.86377 6.82129L0.183045 6.84222L0.16232 5.17835L1.84304 5.15742L1.86377 6.82129Z"
                        fill="#02171D"
                      />
                      <path
                        d="M11.8374 6.82129L10.1567 6.84222L10.136 5.17835L11.8167 5.15742L11.8374 6.82129Z"
                        fill="#02171D"
                      />
                      <path
                        d="M2.49463 3.65649L1.29137 2.48285L2.45325 1.29166L3.65651 2.4653L2.49463 3.65649Z"
                        fill="#02171D"
                      />
                      <path
                        d="M9.54639 10.7083L8.34313 9.5346L9.50501 8.34341L10.7083 9.51706L9.54639 10.7083Z"
                        fill="#02171D"
                      />
                      <path
                        d="M8.34326 2.49463L9.51691 1.29137L10.7081 2.45325L9.53445 3.65651L8.34326 2.49463Z"
                        fill="#02171D"
                      />
                      <path
                        d="M1.29199 9.54663L2.46564 8.34338L3.65683 9.50526L2.48318 10.7085L1.29199 9.54663Z"
                        fill="#02171D"
                      />
                    </svg>
                  </span>
                  <span className="toggle-slider"></span>
                </label>
              </div>
            </div>
          </div>
          {!isSurveyPage && (
            <>
              {account ? (
                <a class="disconnect-btn" onClick={disconnectWallet}>
                  {`${account?.slice(0, 6)}...${account?.slice(-4)}`}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.375 16.875C9.375 17.0408 9.30915 17.1997 9.19194 17.3169C9.07473 17.4342 8.91576 17.5 8.75 17.5H3.75C3.58424 17.5 3.42527 17.4342 3.30806 17.3169C3.19085 17.1997 3.125 17.0408 3.125 16.875V3.125C3.125 2.95924 3.19085 2.80027 3.30806 2.68306C3.42527 2.56585 3.58424 2.5 3.75 2.5H8.75C8.91576 2.5 9.07473 2.56585 9.19194 2.68306C9.30915 2.80027 9.375 2.95924 9.375 3.125C9.375 3.29076 9.30915 3.44973 9.19194 3.56694C9.07473 3.68415 8.91576 3.75 8.75 3.75H4.375V16.25H8.75C8.91576 16.25 9.07473 16.3158 9.19194 16.4331C9.30915 16.5503 9.375 16.7092 9.375 16.875ZM17.9422 9.55781L14.8172 6.43281C14.6999 6.31554 14.5409 6.24965 14.375 6.24965C14.2091 6.24965 14.0501 6.31554 13.9328 6.43281C13.8155 6.55009 13.7497 6.70915 13.7497 6.875C13.7497 7.04085 13.8155 7.19991 13.9328 7.31719L15.9914 9.375H8.75C8.58424 9.375 8.42527 9.44085 8.30806 9.55806C8.19085 9.67527 8.125 9.83424 8.125 10C8.125 10.1658 8.19085 10.3247 8.30806 10.4419C8.42527 10.5592 8.58424 10.625 8.75 10.625H15.9914L13.9328 12.6828C13.8155 12.8001 13.7497 12.9591 13.7497 13.125C13.7497 13.2909 13.8155 13.4499 13.9328 13.5672C14.0501 13.6845 14.2091 13.7503 14.375 13.7503C14.5409 13.7503 14.6999 13.6845 14.8172 13.5672L17.9422 10.4422C18.0003 10.3841 18.0464 10.3152 18.0779 10.2393C18.1093 10.1635 18.1255 10.0821 18.1255 10C18.1255 9.91787 18.1093 9.83654 18.0779 9.76066C18.0464 9.68479 18.0003 9.61586 17.9422 9.55781Z"
                      fill="#FC4F52"
                    />
                  </svg>
                </a>
              ) : (
                <a onClick={handleShowWallet} className="connect-btn">
                  Connect Wallet
                </a>
              )}
            </>
          )}

          {isSurveyPage && (
            <>
              {account ? (
                <a class="disconnect-btn" onClick={disconnectWallet}>
                  {`${account?.slice(0, 6)}...${account?.slice(-4)}`}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.375 16.875C9.375 17.0408 9.30915 17.1997 9.19194 17.3169C9.07473 17.4342 8.91576 17.5 8.75 17.5H3.75C3.58424 17.5 3.42527 17.4342 3.30806 17.3169C3.19085 17.1997 3.125 17.0408 3.125 16.875V3.125C3.125 2.95924 3.19085 2.80027 3.30806 2.68306C3.42527 2.56585 3.58424 2.5 3.75 2.5H8.75C8.91576 2.5 9.07473 2.56585 9.19194 2.68306C9.30915 2.80027 9.375 2.95924 9.375 3.125C9.375 3.29076 9.30915 3.44973 9.19194 3.56694C9.07473 3.68415 8.91576 3.75 8.75 3.75H4.375V16.25H8.75C8.91576 16.25 9.07473 16.3158 9.19194 16.4331C9.30915 16.5503 9.375 16.7092 9.375 16.875ZM17.9422 9.55781L14.8172 6.43281C14.6999 6.31554 14.5409 6.24965 14.375 6.24965C14.2091 6.24965 14.0501 6.31554 13.9328 6.43281C13.8155 6.55009 13.7497 6.70915 13.7497 6.875C13.7497 7.04085 13.8155 7.19991 13.9328 7.31719L15.9914 9.375H8.75C8.58424 9.375 8.42527 9.44085 8.30806 9.55806C8.19085 9.67527 8.125 9.83424 8.125 10C8.125 10.1658 8.19085 10.3247 8.30806 10.4419C8.42527 10.5592 8.58424 10.625 8.75 10.625H15.9914L13.9328 12.6828C13.8155 12.8001 13.7497 12.9591 13.7497 13.125C13.7497 13.2909 13.8155 13.4499 13.9328 13.5672C14.0501 13.6845 14.2091 13.7503 14.375 13.7503C14.5409 13.7503 14.6999 13.6845 14.8172 13.5672L17.9422 10.4422C18.0003 10.3841 18.0464 10.3152 18.0779 10.2393C18.1093 10.1635 18.1255 10.0821 18.1255 10C18.1255 9.91787 18.1093 9.83654 18.0779 9.76066C18.0464 9.68479 18.0003 9.61586 17.9422 9.55781Z"
                      fill="#FC4F52"
                    />
                  </svg>
                </a>
              ) : null}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Navbar;
