import React, { useEffect, useState } from "react";
import "./App.scss";
import Landing from "./components/landing/Landing.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/landing/footer/Footer.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signupproject from "./components/Signupproject/Signupproject.js";
import Signupleader from "./components/Signupproject/Signupleader.js";
import Faqs from "./components/Faqs/Faqs.js";
import Insurance from "./components/Insurance/Insurance.js";
import Terms from "./components/Terms/Terms.js";
import Privacy from "./components/Privacy/Privacy.js";
import ApplyForMarketing from "./components/ApplyForMarketing/Apply.js";
import Career from "./components/Career/Career.js";
import Education from "./components/Education/Education.js";
import Blog from "./components/Blog/Blog.js";
import BlogDetail from "./components/Blog/BlogDetail.js";
import Aml from "./components/Privacy/AmlPolicy.js";
import GemsDetailPage from "./components/AllDetailPages/GemsDetailPage/GemsDetailPage.js";
import DopDetailPage from "./components/AllDetailPages/DopDetailPage/DopDetailPage.js";
import IncentivDetailPage from "./components/AllDetailPages/IncentivDetailPage/IncentiveDetailPage.js";
import LeiaDetailPage from "./components/AllDetailPages/LeiaDetailPage/LeiaDetailPage.js";
import OlympusDetailPage from "./components/AllDetailPages/OlympusDetailPage/OlympusDetailPage.js";
import SeeAllProjects from "./components/SeeAllProjects/SeeAllProjects.js";
import GemsPurchases from "./components/GemsPurchases/GemsPurchases.js";
// import AllPurchases from "./components/GemsPurchases/AllPurchasesPremium.js";
import Claim from "./components/GemsPurchases/Claim.js";
import useEagerConnect from "./hooks/useEagerConnect";
import AllPurchasesPremium from "./components/GemsPurchases/AllPurchasesPremium.js";
import AllPurchasesReferral from "./components/GemsPurchases/AllPurchasesReferral.js";
import NFADetailpage from "./components/AllDetailPages/NFADetailPage/NFADetailpage.js";
import GemsDetailToken from "./components/AllDetailPages/GemsDetailToken/GemsDetailToken.js";
import GemsCommunityProject from "./components/GemsCommunityProject/GemsCommunityProject.js";
import TakeSurvey from "./components/GemsCommunityProject/TakeSurvey.js";
import TermsForCommunnity from "./components/Terms/TermsForCommunity.js";
import AstraDetailPage from "./components/AllDetailPages/AstraDetailPage/AstraDetailPage.js";
import NodesMinersLanding from "./components/NodesMinersLanding/NodesMinersLanding.js";
import NodesDetailPage from "./components/AllDetailPages/NodesDetailPage/NodesDetailPage.js";
import MinersDetailPage from "./components/AllDetailPages/MinersDetailPage/MinersDetailPage.js";
function App() {
  useEagerConnect();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ToastContainer style={{ fontSize: 20 }} />
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Landing />}
          />
          <Route
            exact
            path="/Gems/:name"
            render={() => <GemsDetailPage />}
          />
          <Route exact path="/gemsdetail" component={GemsDetailToken} />
          <Route exact path="/dopdetail" component={DopDetailPage} />
          <Route exact path="/incentiv" component={IncentivDetailPage} />
          <Route exact path="/leia" component={LeiaDetailPage} />
          <Route exact path="/olympus" component={OlympusDetailPage} />
          <Route exact path="/nfa" component={NFADetailpage} />
          <Route exact path="/astranova" component={AstraDetailPage} />
          <Route exact path="/nodes" component={NodesDetailPage} />
          <Route exact path="/miners" component={MinersDetailPage} />
          <Route exact path="/signupforproject" component={Signupproject} />
          <Route exact path="/signupforleader" component={Signupleader} />
          <Route exact path="/faqs" component={Faqs} />
          {/* <Route exact path="/applyforcountrymanager" component={Apply} /> */}
          <Route exact path="/insurance" component={Insurance} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/termscommunityproject" component={TermsForCommunnity} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/amlpolicy" component={Aml} />
          <Route exact path="/careers" component={Career} />
          <Route exact path="/launchpad" component={SeeAllProjects} />
          <Route
            exact
            path="/applyformarketingmanager"
            component={ApplyForMarketing}
          />
          <Route exact path="/education" component={Education} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:slug" component={BlogDetail} />
          <Route exact path="/mypurchases" component={GemsPurchases} />
          <Route exact path="/allpurchasespremium/:id" component={AllPurchasesPremium} />
          <Route exact path="/allpurchasesreferral/:id" component={AllPurchasesReferral} />
          <Route exact path="/claim/:id" component={Claim} />
          <Route exact path="/gemscommunityproject" component={GemsCommunityProject} />
          <Route exact path="/survey" component={TakeSurvey} />
          <Route exact path="/nodes-miners" component={NodesMinersLanding} />

        </Switch>
        <Footer />
      </Router>

      {/* //staging Community */}


    </>
  );
}

export default App;
