import "../../App.scss";
import React, { useEffect } from "react";
import Banner from "./main-banner/Banner.js";
import Process from "./Process/Process.js";
import Crypto from "./CryptoPrices/Crypto.js";
import Discover from "./Discover/Discover.js";
import Trade from "./Trade/Trade.js";
import Staking from "./Staking/Staking.js";
import News from "./News/News.js";
import Visionary from "./Visionary/Visionary.js";
import Selfserve from "./SelfServe/Selfserve.js";


function Landing() {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banner />
      {/* <Crypto /> */}
      <Discover />
      <Process />
      <Trade />
      {/* <Staking /> */}
      <Selfserve />
      <News />
      <Visionary />
    </>
  );
}

export default Landing;
