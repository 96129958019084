import React from 'react'
import Navbar from "../landing/header/Navbar";

const AmlBanner = () => {
  return (
    <>
    <div className="wrapper-terms">
           <Navbar />
            <section className="main-termbanner">
                <img src="/assets/education/leftshade.png" alt="leftshade" className="leftshade" />
                <img src="/assets/education/rightshade.png" alt="leftshade" className="rightshade" />
                <img src="/assets/education/leftshadembl.png" alt="leftshade" className="leftshadembl d-none" />
                <img src="/assets/education/rightshadembl.png" alt="leftshade" className="rightshadembl d-none" />
                <div className="custom-container">
                    <div className="main-content">
                        <div className="contentheads">
                            <h2 className="lower-text">
                            Gems Labs Limited <br />   <span>Anti-Money Laundering (AML) Policy</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
           </div>
    </>
  )
}

export default AmlBanner
