import React from 'react'
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'

const Information = () => {
  const { t } = useTranslation()

    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{margin: "0"}}>
                    <h2>{t('Gems-detailt11')} <span></span></h2>
                    <p>{t('Gems-detailt12')}</p>
                </div>
                <div className="img-wrapper">
                    <img src="\assets\alldetailpages\gemsdetailpage\info\projectinfo1.png" alt="img" className='img-fluid' />
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt13')}<span></span></h2>
                    <p>{t('Gems-detailt14')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt15')} <span></span></h2>
                    <p>{t('Gems-detailt16')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt17')}<span></span></h2>
                    <p>{t('Gems-detailt18')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt19')} <span></span></h2>
                    <p>{t('Gems-detailt20')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt21')} <span></span></h2>
                    <p>{t('Gems-detailt22')}</p>
                    <p>{t('Gems-detailt23')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt24')} <span></span></h2>
                    <p>{t('Gems-detailt25')}</p>
                    <ul>
                        <li>{t('Gems-detailt26')}</li>
                        <li>{t('Gems-detailt27')}</li>
                    </ul>
                    <p>{t('Gems-detailt28')}</p>
                    <ul>
                        <li>{t('Gems-detailt29')}</li>
                        <li>{t('Gems-detailt30')}</li>
                    </ul>
                    <p>{t('Gems-detailt31')}</p>
                    <ul>
                        <li>{t('Gems-detailt32')}</li>
                        <li>{t('Gems-detailt33')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt34')} <span></span></h2>
                    <div className="team-gems">
                    <div className="single-team">
                            <img src="\assets\alldetailpages\gemsdetailpage\info\team\isaacjoshua.svg" alt="img" className='img-fluid' />
                            <h6>Isaac Joshua, ceo</h6>
                            <p>Over 7 years of experience in blockchain, Web3 & crypto, including notable roles at eToro, a respected crypto brokerage firm, and a publicly traded crypto investment group</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\gemsdetailpage\info\team\IMG_6866-Edit.JPG" alt="img" className='img-fluid' />
                            <h6>{t('Gems-detailt39')}</h6>
                            <p>{t('Gems-detailt40')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\gemsdetailpage\info\team\GEMS-6748.JPG" alt="img" className='img-fluid' />
                            <h6>{t('Gems-detailt41')}</h6>
                            <p>{t('Gems-detailt42')}</p>
                        </div>
                    </div>

                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt43')} <span></span></h2>
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\alldetailpages\gemsdetailpage\info\gems-tokenmoics-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                            <img src="\assets\alldetailpages\gemsdetailpage\info\gems-tokenmoics.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Information
