import React, { useEffect, useState } from "react";
import "./gemspurchases.scss";
import Navbar from "../landing/header/Navbar";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import AllProjects from "./AllProjectsPremium";
import AllProjectsPremium from "./AllProjectsPremium";
import AllProjectsReferral from "./AllProjectsReferral";
import Loader from "../../hooks/loader";

// import Loader from "../../hooks/loader";

const GemsPurchases = () => {
  // const { account } = useWeb3React();
  const [mainLoader1, setMainLoader1] = useState(false);
  const [role, setRole] = useState("premium");
  //    const [mainLoader1, setMainLoader1] = useState(false);

  const handleTabSelect = (rolee) => {
    setRole(rolee);
  };

  return (
    <>
      {mainLoader1 && <Loader />}

      <Navbar />
      <section className="gems-purchases">
        <div className="custom-container">
          <div className="main-heading">
            <h5>
              My <span>Purchases</span>
            </h5>
          </div>
          <Tabs
            onSelect={handleTabSelect}
            defaultActiveKey="premium"
            id="uncontrolled-tab-example"
            className="signup-nav"
          >
            <Tab eventKey="premium" title="Premium Projects">
              <AllProjectsPremium role={role} />
            </Tab>
            <Tab eventKey="self" title="Self Serve Projects">
              <AllProjectsReferral
                role={role}
                setMainLoader1={setMainLoader1}
              />
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default GemsPurchases;
