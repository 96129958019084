import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="wrapper-footer">
        <img
          src="\assets\landing\footer\footer-bg.png"
          alt="img"
          className="img-fluid footer-bg hide-in-light-theme"
        />
        <img
          src="\assets\landing\footer\footer-bg-white.png"
          alt="img"
          className="img-fluid footer-bg d-none show-in-light-theme"
        />
        <section className="main-footer">
          <div className="custom-container">
            <div className="parent">
              <div className="main-logo">
                <a href="#" className="hide-in-light-theme">
                  <img
                    src="\logo.svg"
                    alt="img"
                    className="img-fluid"
                    style={{ width: "120px" }}
                  />
                </a>
                <a href="#" className="d-none show-in-light-theme">
                  <img
                    src="\logo-white.svg"
                    alt="img"
                    className="img-fluid"
                    style={{ width: "120px" }}
                  />
                </a>
                <p className="contact-info">{t("Footernew1")} </p>
                <div className="social-links hide-in-light-theme">
                  <a
                    href="https://twitter.com/Gems_VIP_"
                    target="_blank"
                    className="m-0"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <mask
                        id="mask0_370_1507"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="14"
                        height="15"
                      >
                        <path
                          d="M0 0.503418H14V14.5034H0V0.503418Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_370_1507)">
                        <path
                          d="M11.025 1.15527H13.172L8.482 6.52927L14 13.8433H9.68L6.294 9.40827L2.424 13.8433H0.275L5.291 8.09327L0 1.15627H4.43L7.486 5.20927L11.025 1.15527ZM10.27 12.5553H11.46L3.78 2.37627H2.504L10.27 12.5553Z"
                          fill="#E1F5EB"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    href="http://t.me/GemsVIPOfficial"
                    target="_blank"
                    className="m-0"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_505_20939)">
                        <path
                          d="M5.49335 9.35908L5.26177 12.6164C5.5931 12.6164 5.7366 12.4741 5.90869 12.3032L7.4621 10.8186L10.6809 13.1758C11.2713 13.5048 11.6872 13.3316 11.8464 12.6327L13.9593 2.73241L13.9599 2.73183C14.1471 1.85916 13.6443 1.51791 13.0691 1.73199L0.649937 6.48674C-0.197646 6.81574 -0.184813 7.28824 0.505854 7.50233L3.68094 8.48991L11.056 3.87516C11.4031 3.64533 11.7187 3.77249 11.4591 4.00233L5.49335 9.35908Z"
                          fill="#E1F5EB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_505_20939">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.503418)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://t.me/GemsOfficialCommunity"
                    target="_blank"
                    className="m-0"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_505_20939)">
                        <path
                          d="M5.49335 9.35908L5.26177 12.6164C5.5931 12.6164 5.7366 12.4741 5.90869 12.3032L7.4621 10.8186L10.6809 13.1758C11.2713 13.5048 11.6872 13.3316 11.8464 12.6327L13.9593 2.73241L13.9599 2.73183C14.1471 1.85916 13.6443 1.51791 13.0691 1.73199L0.649937 6.48674C-0.197646 6.81574 -0.184813 7.28824 0.505854 7.50233L3.68094 8.48991L11.056 3.87516C11.4031 3.64533 11.7187 3.77249 11.4591 4.00233L5.49335 9.35908Z"
                          fill="#E1F5EB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_505_20939">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.503418)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/gemsvip/"
                    target="_blank"
                    className="m-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <path
                        d="M2.02234 1.21436C1.05992 1.21436 0.430664 1.84633 0.430664 2.67698C0.430664 3.48928 1.04117 4.13928 1.98541 4.13928H2.00368C2.98493 4.13928 3.5956 3.48928 3.5956 2.67698C3.57725 1.84633 2.98493 1.21436 2.02234 1.21436Z"
                        fill="#E1F5EB"
                      />
                      <path
                        d="M0.597168 5.29492H3.41074V13.7597H0.597168V5.29492Z"
                        fill="#E1F5EB"
                      />
                      <path
                        d="M10.3165 5.09619C8.79872 5.09619 7.78094 6.52245 7.78094 6.52245V5.29486H4.96729V13.7596H7.78078V9.03257C7.78078 8.77951 7.79913 8.52684 7.87347 8.34588C8.07686 7.84055 8.53973 7.31704 9.31702 7.31704C10.3351 7.31704 10.7423 8.0933 10.7423 9.23124V13.7596H13.5557V8.90608C13.5557 6.30607 12.1675 5.09619 10.3165 5.09619Z"
                        fill="#E1F5EB"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://coinmarketcap.com/currencies/gems-vip/"
                    target="_blank"
                    className="m-0"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_505_20951)">
                        <path
                          d="M12.0969 8.869C11.8525 9.02358 11.5649 9.04283 11.3468 8.91975C11.0691 8.76225 10.9163 8.39592 10.9163 7.88492V6.35775C10.9163 5.62042 10.6246 5.09542 10.1369 4.95367C9.31094 4.71275 8.68911 5.72367 8.45578 6.104L6.99861 8.4665V5.579C6.98228 4.914 6.76644 4.51675 6.35694 4.39658C6.08511 4.31783 5.67969 4.34933 5.28536 4.9525L2.02044 10.1949C1.58337 9.36517 1.35549 8.44125 1.35661 7.50342C1.35661 4.34525 3.88769 1.77625 6.99861 1.77625C10.1089 1.77625 12.6394 4.34525 12.6394 7.50342C12.6418 7.51392 12.6406 7.52325 12.6412 7.53433C12.6704 8.14625 12.472 8.63275 12.0969 8.87017V8.869ZM13.8936 7.504L13.893 7.47192C13.8755 3.626 10.7897 0.503418 6.99803 0.503418C3.19644 0.503418 0.103027 3.6435 0.103027 7.50342C0.103027 11.3628 3.19644 14.5034 6.99803 14.5034C8.74278 14.5034 10.4082 13.8402 11.6863 12.6368C11.94 12.3976 11.9546 11.9951 11.7195 11.7378C11.6641 11.6766 11.597 11.6271 11.5221 11.5922C11.4473 11.5573 11.3662 11.5376 11.2837 11.5344C11.2012 11.5312 11.1189 11.5445 11.0415 11.5735C10.9642 11.6026 10.8935 11.6467 10.8334 11.7034C9.79755 12.6842 8.42517 13.2308 6.99861 13.2306C5.33261 13.2306 3.83403 12.4938 2.80036 11.3243L5.74503 6.59633V8.77625C5.74503 9.82275 6.15103 10.1611 6.49169 10.2603C6.83236 10.3594 7.35269 10.2918 7.89928 9.40392C8.48144 8.46242 9.08053 7.48067 9.66269 6.55725V7.88492C9.66269 8.86375 10.0547 9.64658 10.7378 10.0316C11.3538 10.3798 12.1284 10.3483 12.759 9.94992C13.5244 9.46692 13.9362 8.57675 13.893 7.504H13.8936Z"
                          fill="#E1F5EB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_505_20951">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.503418)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://www.coingecko.com/en/coins/gems-vip"
                    target="_blank"
                    className="m-0"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_505_20977)">
                        <mask
                          id="mask0_505_20977"
                          style={{ maskType: "luminance" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="14"
                          height="15"
                        >
                          <path
                            d="M14 0.503418H0V14.5034H14V0.503418Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_505_20977)">
                          <path
                            d="M7.01762 0.503488C3.17916 0.521049 0.0817498 3.66893 0.0987021 7.53489C0.116138 11.4008 3.24211 14.5204 7.08008 14.5033C10.9185 14.4858 14.016 11.3379 13.999 7.47244C13.9816 3.60601 10.8561 0.486414 7.01762 0.503488ZM6.13755 7.16024C5.45122 7.16024 4.89471 6.60023 4.89471 5.90899C4.89471 5.21776 5.45122 4.65774 6.13755 4.65774C6.82387 4.65774 7.37991 5.21824 7.37991 5.90899C7.37991 6.59973 6.82338 7.16024 6.13755 7.16024ZM7.12853 8.51295L7.13483 8.50515L7.14159 8.49734C7.49614 8.7276 7.90106 8.80906 8.30549 8.82077C8.71041 8.83149 9.12307 8.80077 9.52896 8.71833C9.93435 8.63345 10.333 8.50808 10.718 8.34807C10.934 8.25831 11.1491 8.16027 11.354 8.04514C11.3554 8.04417 11.3564 8.04367 11.3579 8.04269C11.4852 7.96562 11.6097 7.88416 11.7318 7.80026C11.7584 7.78123 11.7841 7.76122 11.8093 7.74075L11.8122 7.74416L11.8228 7.75683C11.5104 8.04319 11.1384 8.25587 10.7597 8.44368C10.3771 8.62515 9.97795 8.77393 9.56577 8.88125C9.15504 8.98809 8.72639 9.06907 8.29241 9.02907C7.86522 8.99102 7.41526 8.84029 7.12756 8.51344L7.12853 8.51295ZM10.3175 7.16024C10.317 7.00999 10.4371 6.88901 10.5853 6.88755C10.7345 6.88707 10.8546 7.00756 10.8561 7.15731C10.8566 7.30707 10.7364 7.42951 10.5882 7.43001C10.4395 7.43049 10.3189 7.30952 10.3175 7.16024ZM8.18876 13.8741C7.92043 11.9799 9.56286 10.1247 10.4889 9.17541C10.6991 8.96028 11.0241 8.6637 11.3264 8.34857C12.5285 7.21634 12.7678 5.86362 10.3073 5.18751C9.84088 5.05141 9.3575 4.85872 8.86782 4.66407C8.85232 4.61872 8.83488 4.57578 8.81453 4.5353C8.75836 4.43774 8.66004 4.32553 8.51133 4.19821C8.19167 3.91869 7.5906 3.92601 7.07186 4.04992C6.4984 3.91382 5.93266 3.86552 5.38875 3.99674C3.00914 4.65725 2.62652 5.82606 2.534 7.23438C2.40808 8.75637 2.33446 9.89055 1.76244 11.1862C1.04851 10.1491 0.627612 8.89101 0.6218 7.53342C0.605817 3.95869 3.47024 1.04789 7.01955 1.03179C10.5688 1.0157 13.4589 3.90065 13.4749 7.47537C13.4895 10.6681 11.2053 13.3321 8.18828 13.875L8.18876 13.8741Z"
                            fill="#E1F5EB"
                          />
                          <path
                            d="M7.0112 5.92006C7.0112 6.40641 6.61984 6.80007 6.13744 6.80007C5.65502 6.80007 5.26318 6.40641 5.26318 5.92006C5.26318 5.4337 5.65454 5.04004 6.13744 5.04004C6.62032 5.04004 7.0112 5.4337 7.0112 5.92006Z"
                            fill="#E1F5EB"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_505_20977">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.503418)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://www.youtube.com/@gems_vip"
                    target="_blank"
                    className="m-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                    >
                      <path
                        d="M13.4543 0.926486C12.949 0.325751 12.0159 0.0807056 10.234 0.0807056H3.76582C1.94315 0.0807056 0.994316 0.341557 0.490858 0.981132C0 1.60472 0 2.52353 0 3.79518V6.21894C0 8.68253 0.5824 9.93336 3.76582 9.93336H10.2341C11.7793 9.93336 12.6356 9.71713 13.1895 9.18698C13.7576 8.64333 14 7.75569 14 6.21894V3.79518C14 2.45411 13.962 1.52989 13.4543 0.926486ZM8.98805 5.34168L6.05085 6.87676C5.98518 6.91108 5.91337 6.92811 5.84166 6.92811C5.76046 6.92811 5.67944 6.90625 5.60768 6.8628C5.47255 6.78092 5.39005 6.63447 5.39005 6.47649V3.41618C5.39005 3.25848 5.47233 3.11216 5.60718 3.03023C5.74208 2.94831 5.90985 2.94271 6.04981 3.01542L8.98701 4.54061C9.13645 4.6182 9.23029 4.77247 9.23052 4.94078C9.2307 5.10924 9.13726 5.26373 8.98805 5.34168Z"
                        fill="#E1F5EB"
                      />
                    </svg>
                  </a>
                </div>
                <div className="social-links d-none show-in-light-theme">
                  <a
                    href="https://twitter.com/Gems_VIP_"
                    target="_blank"
                    className="m-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <mask
                        id="mask0_505_21011"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="14"
                        height="15"
                      >
                        <path
                          d="M0 0.00683594H14V14.0068H0V0.00683594Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_505_21011)">
                        <path
                          d="M11.025 0.658691H13.172L8.482 6.03269L14 13.3467H9.68L6.294 8.91169L2.424 13.3467H0.275L5.291 7.59669L0 0.659691H4.43L7.486 4.71269L11.025 0.658691ZM10.27 12.0587H11.46L3.78 1.87969H2.504L10.27 12.0587Z"
                          fill="#01141A"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    href="http://t.me/GemsVIPOfficial"
                    target="_blank"
                    className="m-0"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_505_21017)">
                        <path
                          d="M5.49335 8.86249L5.26177 12.1198C5.5931 12.1198 5.7366 11.9775 5.90869 11.8066L7.4621 10.322L10.6809 12.6792C11.2713 13.0082 11.6872 12.835 11.8464 12.1362L13.9593 2.23583L13.9599 2.23524C14.1471 1.36258 13.6443 1.02133 13.0691 1.23541L0.649937 5.99016C-0.197646 6.31916 -0.184813 6.79166 0.505854 7.00574L3.68094 7.99333L11.056 3.37858C11.4031 3.14874 11.7187 3.27591 11.4591 3.50574L5.49335 8.86249Z"
                          fill="#01141A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_505_21017">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.00683594)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://t.me/GemsOfficialCommunity"
                    target="_blank"
                    className="m-0"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_505_21017)">
                        <path
                          d="M5.49335 8.86249L5.26177 12.1198C5.5931 12.1198 5.7366 11.9775 5.90869 11.8066L7.4621 10.322L10.6809 12.6792C11.2713 13.0082 11.6872 12.835 11.8464 12.1362L13.9593 2.23583L13.9599 2.23524C14.1471 1.36258 13.6443 1.02133 13.0691 1.23541L0.649937 5.99016C-0.197646 6.31916 -0.184813 6.79166 0.505854 7.00574L3.68094 7.99333L11.056 3.37858C11.4031 3.14874 11.7187 3.27591 11.4591 3.50574L5.49335 8.86249Z"
                          fill="#01141A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_505_21017">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.00683594)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/gemsvip/"
                    target="_blank"
                    className="m-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_505_21021)">
                        <path
                          d="M2.02234 0.717773C1.05992 0.717773 0.430664 1.34975 0.430664 2.1804C0.430664 2.9927 1.04117 3.6427 1.98541 3.6427H2.00368C2.98493 3.6427 3.5956 2.9927 3.5956 2.1804C3.57725 1.34975 2.98493 0.717773 2.02234 0.717773Z"
                          fill="#01141A"
                        />
                        <path
                          d="M0.597168 4.79834H3.41074V13.2631H0.597168V4.79834Z"
                          fill="#01141A"
                        />
                        <path
                          d="M10.3165 4.59961C8.79872 4.59961 7.78094 6.02586 7.78094 6.02586V4.79828H4.96729V13.263H7.78078V8.53599C7.78078 8.28292 7.79913 8.03026 7.87347 7.8493C8.07686 7.34397 8.53973 6.82046 9.31702 6.82046C10.3351 6.82046 10.7423 7.59671 10.7423 8.73466V13.263H13.5557V8.4095C13.5557 5.80949 12.1675 4.59961 10.3165 4.59961Z"
                          fill="#01141A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_505_21021">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.00683594)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://coinmarketcap.com/currencies/gems-vip/"
                    target="_blank"
                    className="m-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_505_21025)">
                        <path
                          d="M12.0969 8.37242C11.8525 8.527 11.5649 8.54625 11.3468 8.42317C11.0691 8.26567 10.9163 7.89934 10.9163 7.38834V5.86117C10.9163 5.12384 10.6246 4.59884 10.1369 4.45709C9.31094 4.21617 8.68911 5.22709 8.45578 5.60742L6.99861 7.96992V5.08242C6.98228 4.41742 6.76644 4.02017 6.35694 3.9C6.08511 3.82125 5.67969 3.85275 5.28536 4.45592L2.02044 9.69834C1.58337 8.86858 1.35549 7.94466 1.35661 7.00684C1.35661 3.84867 3.88769 1.27967 6.99861 1.27967C10.1089 1.27967 12.6394 3.84867 12.6394 7.00684C12.6418 7.01734 12.6406 7.02667 12.6412 7.03775C12.6704 7.64967 12.472 8.13617 12.0969 8.37359V8.37242ZM13.8936 7.00742L13.893 6.97534C13.8755 3.12942 10.7897 0.00683594 6.99803 0.00683594C3.19644 0.00683594 0.103027 3.14692 0.103027 7.00684C0.103027 10.8662 3.19644 14.0068 6.99803 14.0068C8.74278 14.0068 10.4082 13.3436 11.6863 12.1402C11.94 11.901 11.9546 11.4985 11.7195 11.2413C11.6641 11.1801 11.597 11.1306 11.5221 11.0956C11.4473 11.0607 11.3662 11.0411 11.2837 11.0379C11.2012 11.0346 11.1189 11.0479 11.0415 11.077C10.9642 11.106 10.8935 11.1501 10.8334 11.2068C9.79755 12.1876 8.42517 12.7342 6.99861 12.734C5.33261 12.734 3.83403 11.9973 2.80036 10.8277L5.74503 6.09975V8.27967C5.74503 9.32617 6.15103 9.6645 6.49169 9.76367C6.83236 9.86284 7.35269 9.79517 7.89928 8.90734C8.48144 7.96584 9.08053 6.98409 9.66269 6.06067V7.38834C9.66269 8.36717 10.0547 9.15 10.7378 9.535C11.3538 9.88325 12.1284 9.85175 12.759 9.45334C13.5244 8.97034 13.9362 8.08017 13.893 7.00742H13.8936Z"
                          fill="#01141A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_505_21025">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.00683594)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://www.coingecko.com/en/coins/gems-vip"
                    target="_blank"
                    className="m-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_505_21027)">
                        <mask
                          id="mask0_505_21027"
                          style={{ maskType: "luminance" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="14"
                          height="15"
                        >
                          <path
                            d="M14 0.00683594H0V14.0068H14V0.00683594Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_505_21027)">
                          <path
                            d="M7.01762 0.00690569C3.17916 0.024467 0.0817498 3.17235 0.0987021 7.03831C0.116138 10.9042 3.24211 14.0238 7.08008 14.0068C10.9185 13.9892 14.016 10.8413 13.999 6.97586C13.9816 3.10943 10.8561 -0.0101679 7.01762 0.00690569ZM6.13755 6.66366C5.45122 6.66366 4.89471 6.10365 4.89471 5.41241C4.89471 4.72118 5.45122 4.16116 6.13755 4.16116C6.82387 4.16116 7.37991 4.72166 7.37991 5.41241C7.37991 6.10315 6.82338 6.66366 6.13755 6.66366ZM7.12853 8.01637L7.13483 8.00856L7.14159 8.00076C7.49614 8.23102 7.90106 8.31248 8.30549 8.32419C8.71041 8.33491 9.12307 8.30418 9.52896 8.22174C9.93435 8.13687 10.333 8.0115 10.718 7.85149C10.934 7.76173 11.1491 7.66368 11.354 7.54856C11.3554 7.54759 11.3564 7.54709 11.3579 7.54611C11.4852 7.46904 11.6097 7.38758 11.7318 7.30368C11.7584 7.28465 11.7841 7.26464 11.8093 7.24416L11.8122 7.24758L11.8228 7.26025C11.5104 7.54661 11.1384 7.75929 10.7597 7.9471C10.3771 8.12857 9.97795 8.27735 9.56577 8.38467C9.15504 8.49151 8.72639 8.57249 8.29241 8.53249C7.86522 8.49444 7.41526 8.3437 7.12756 8.01686L7.12853 8.01637ZM10.3175 6.66366C10.317 6.51341 10.4371 6.39243 10.5853 6.39097C10.7345 6.39049 10.8546 6.51097 10.8561 6.66073C10.8566 6.81049 10.7364 6.93293 10.5882 6.93342C10.4395 6.9339 10.3189 6.81294 10.3175 6.66366ZM8.18876 13.3775C7.92043 11.4833 9.56286 9.62812 10.4889 8.67883C10.6991 8.4637 11.0241 8.16712 11.3264 7.85198C12.5285 6.71976 12.7678 5.36704 10.3073 4.69093C9.84088 4.55482 9.3575 4.36213 8.86782 4.16749C8.85232 4.12214 8.83488 4.0792 8.81453 4.03872C8.75836 3.94116 8.66004 3.82895 8.51133 3.70163C8.19167 3.42211 7.5906 3.42943 7.07186 3.55334C6.4984 3.41724 5.93266 3.36894 5.38875 3.50016C3.00914 4.16067 2.62652 5.32948 2.534 6.7378C2.40808 8.25979 2.33446 9.39397 1.76244 10.6896C1.04851 9.65252 0.627612 8.39443 0.6218 7.03683C0.605817 3.46211 3.47024 0.551309 7.01955 0.535212C10.5688 0.519113 13.4589 3.40407 13.4749 6.97879C13.4895 10.1716 11.2053 12.8355 8.18828 13.3785L8.18876 13.3775Z"
                            fill="#01141A"
                          />
                          <path
                            d="M7.0112 5.42347C7.0112 5.90983 6.61984 6.30349 6.13744 6.30349C5.65502 6.30349 5.26318 5.90983 5.26318 5.42347C5.26318 4.93712 5.65454 4.54346 6.13744 4.54346C6.62032 4.54346 7.0112 4.93712 7.0112 5.42347Z"
                            fill="#01141A"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_505_21027">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0 0.00683594)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    href="https://www.youtube.com/@gems_vip"
                    target="_blank"
                    className="m-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                    >
                      <path
                        d="M13.4543 0.926486C12.949 0.325751 12.0159 0.0807056 10.234 0.0807056H3.76582C1.94315 0.0807056 0.994316 0.341557 0.490858 0.981132C0 1.60472 0 2.52352 0 3.79518V6.21894C0 8.68253 0.5824 9.93336 3.76582 9.93336H10.2341C11.7793 9.93336 12.6356 9.71713 13.1895 9.18698C13.7576 8.64333 14 7.75569 14 6.21894V3.79518C14 2.45411 13.962 1.52989 13.4543 0.926486ZM8.98805 5.34168L6.05085 6.87676C5.98518 6.91108 5.91337 6.92811 5.84166 6.92811C5.76046 6.92811 5.67944 6.90625 5.60768 6.8628C5.47256 6.78092 5.39005 6.63447 5.39005 6.47649V3.41618C5.39005 3.25848 5.47233 3.11216 5.60718 3.03023C5.74208 2.94831 5.90985 2.94271 6.04981 3.01542L8.98701 4.54061C9.13645 4.6182 9.23029 4.77247 9.23052 4.94078C9.2307 5.10924 9.13726 5.26373 8.98805 5.34168Z"
                        fill="#01141A"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/* <div className="right-navs">
                                <div className="main-nav">
                                    <h6>About Gems</h6>
                                    <a href="#">About Us</a>
                                    <a href="#">Features</a>
                                    <a href="#">Company</a>
                                    <Link to="/careers">Career</Link>
                                </div>
                                <div className="main-nav">
                                    <h6>Applications</h6>
                                    <a href="#">Launchpad</a>
                                    <a href="#">Gems Trade</a>
                                    <a href="#">Markets</a>
                                    <a href="#">Research</a>
                                </div>
                                <div className="main-nav">
                                    <h6>Token</h6>
                                    <a href="#">GEMS Coin</a>
                                    <a href="https://staking.gems.vip/" target='_blank'>Staking</a>
                                    <a href="#">Buy GEMS</a>
                                    <a href="#">Learn</a>
                                </div>
                                <div className="main-nav">
                                    <h6>Contact</h6>
                                    <a href="#">Support</a>
                                    <a href="#">Help Center</a>
                                    <a href="#">Contact Us</a>
                                    <a href="#">Legal</a>
                                </div>
                                <div className="main-nav">
                                    <h6>Trade</h6>
                                    <a href="#">GEMS</a>
                                    <a href="#">TOMI</a>
                                    <a href="#">ETH</a>
                                    <a href="#">USDT</a>
                                </div>

                            </div> */}
              <div className="right-navs">
                <div className="main-nav">
                  <h6>{t("Footernew23")}</h6>
                  <Link to="/launchpad">{t("Footernew8")}</Link>
                  <a href="https://gems.trade/" target="_blank">
                    {t("Footernew9")}
                  </a>
                  <Link to="/gemscommunityproject"> Community Project</Link>
                </div>
                <div className="main-nav">
                  <h6>{t("Footer-link-heading")}</h6>
                  <Link to="/education">{t("Education")}</Link>
                  <Link to="/blog">{t("Footernew24")}</Link>
                  <a
                    href="\assets\alldetailpages\gemsdetailpage\document\whitepaper.pdf"
                    target="_blank"
                  >
                    {t("Gems-detailt45")}
                  </a>
                  <a href="https://staking.gems.vip/" target="_blank">
                    {t("GEMS-Stake")}
                  </a>
                  <Link to="/faqs">{t("Faqs")}</Link>
                </div>
                <div className="main-nav">
                  <h6>{t("Footer-link-heading1")}</h6>
                  <Link to="/signupforproject"> {t("Twice-btn1")}</Link>
                  <a href="https://leaders.gems.vip/login" target="_blank">
                    {t("Signinasaleqader")}
                  </a>
                  <Link to="/careers">{t("Career")}</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="second-footer">
          <div className="custom-container">
            <div className="parent">
              <h6>Copyright © {currentYear} GEMS. All Rights Reserved.</h6>
              <div className="twice-items">
                <div className="inside-links">
                  <Link to="/terms">Terms of use</Link>
                  <Link to="/privacy">Privacy Policy</Link>
                  <Link to="/amlpolicy">AML Policy</Link>
                </div>
                <a className="d-noneonmobile" onClick={scrollToTop}>
                  {t("Gototop")}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_194_1206)">
                      <path
                        d="M2.02954 7.13691L3.47419 7.13691L3.47419 5.69224L2.02954 5.69224L2.02954 7.13691ZM3.47419 5.69224L4.91815 5.69224L4.91815 4.24829L3.47419 4.24829L3.47419 5.69224ZM4.91815 4.24829L6.3628 4.24829L6.36281 13.1685L7.80466 13.1685L7.80466 4.24829L9.2486 4.24829L9.2486 2.80362L7.80466 2.80362L7.80466 1.00009L6.3628 1.00009L6.3628 2.80362L4.91815 2.80362L4.91815 4.24829ZM9.2486 4.24829L9.2486 5.69224L10.6933 5.69224L10.6933 4.24829L9.2486 4.24829ZM10.6933 5.69224L10.6933 7.13691L12.1372 7.13691L12.1372 5.69224L10.6933 5.69224Z"
                        fill="#9F9F9F"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_194_1206">
                        <rect
                          width="14"
                          height="14"
                          fill="white"
                          transform="translate(0 14) rotate(-90)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
