import React, { useEffect, useState } from "react";
import { API_URL_SURVEY } from "../../utils/ApiUrl";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import Loader from "../../hooks/loader";
import { useWeb3React } from "@web3-react/core";

const VerifyEmail = ({ setSteps, userData }) => {
  if (userData?.isEmailVerified) {
    setSteps(2);
  }

  const token = localStorage.getItem("accessToken");
  // console.log("Access token", token);
  const { account } = useWeb3React();

  if (token && account) {
    const signerAccount = localStorage.getItem("signerAccount");
    if (signerAccount !== account) {
      setSteps(0);
    }
  } else if (!token || !account) {
    setSteps(0);
  }

  const [email, setEmail] = useState();
  const [code, setCode] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [timer, setTimer] = useState(0); // Timer state
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);

  console.log(email);

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSendCodeClick = async () => {
    setEmailError(false);
    if (!emailPattern.test(email)) {
      console.log("Invalid email address: " + email);
      // toast.error("Invalid email address");
      setEmailError("Enter a valid email address!");
      return;
    }
    const accessToken = localStorage.getItem("accessToken");
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_URL_SURVEY}/users/send-otp`,
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log(response);
      setCodeSent(true);
      setTimer(60);
      toast.success("Verification code sent to your email");
    } catch (e) {
      if (e?.response) {
        setEmailError(e?.response?.data?.message);
        // toast.error(e?.response?.data?.message);
        console.error(
          "Error sending verification code:",
          e?.response?.data?.message
        );
      } else {
        console.error("Error sending verification code:", e?.message);
        // toast.error("Error sending verification code");
        setEmailError(e?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyEmailClick = async () => {
    if (code?.length !== 6) {
      setIsCodeInvalid(true);
      return;
    }
    setIsCodeInvalid(false); // Clear the error if the code is valid

    if (!captchaToken) {
      console.error("Please complete the captcha.");
      toast.error("Complete the captcha before continuing");
      return;
    }

    const payload = {
      otp: code,
      recaptcha: captchaToken,
      // ...(captchaToken !== null
      //   ? { recaptcha: captchaToken }
      //   : { recaptcha: "" }),
    };

    const accessToken = localStorage.getItem("accessToken");

    try {
      setLoading(true);
      const response = await axios.patch(
        `${API_URL_SURVEY}/users/verify-otp`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      setSteps(2);
    } catch (e) {
      console.log("Error verifying email:", e);

      if (e?.response?.data?.message === "Otp is incorrect") {
        setIsCodeInvalid(true);
      } else {
        toast.error(e?.response?.message);
      }
      // setSteps(2);
    } finally {
      setLoading(false);
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaToken(value); // Save the captcha response in state
    console.log("Captcha verified, token:", value);
  };

  const capitalizeFirstLetter = (sentence) => {
    if (!sentence) return "";
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval); // Cleanup on unmount
  }, [timer]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="verify-email">
          <div className="main-heading">
            <h4>
              You’ve made <br />
              The right decision.
            </h4>
            <p>
              Now, we just need a few details from you, and we’ll be ready to
              go.
            </p>
          </div>
          <div className="verify-box common-box">
            <h5>Verify your email address</h5>
            <p>
              So we can be in touch. After all, we are making something big
              together.
            </p>
            <div className="option-field">
              <label>Email Address</label>
              <div className="inner-option">
                <input
                  type="text"
                  value={email}
                  placeholder="Please enter your email here..."
                  onChange={(e) => {
                    setEmailError(false);
                    setIsCodeInvalid(false);
                    setEmail(e.target.value);
                  }}
                />
                <div
                  className={`btn-send ${timer > 0 ? "disabled" : ""}`}
                  onClick={handleSendCodeClick}
                  disabled={timer > 0}
                >
                  {codeSent
                    ? `Resend Code${timer > 0 ? ` (${timer}s)` : ""}`
                    : "Send Code"}
                </div>
              </div>
              {emailError && (
                <p className="error-text" style={{ marginTop: "5px" }}>
                  {capitalizeFirstLetter(emailError)}
                </p>
              )}
            </div>
            {codeSent && (
              <div className="option-field">
                <label>Enter Email Verification Code</label>
                <div className="inner-option">
                  <input
                    type="text"
                    placeholder="Please enter 6-digit code"
                    value={code}
                    onChange={(e) => {
                      setIsCodeInvalid(false);
                      setEmailError(false);
                      setCode(e.target.value);
                    }}
                  />
                  {isCodeInvalid && (
                    <p className="error-text" style={{ marginTop: "5px" }}>
                      Invalid email verification code
                    </p>
                  )}
                </div>
              </div>
            )}
            <div className="bottom-captcha mb-3">
              <h5 className="mb-2">Please verify that you're human.</h5>
              {/* <p>Please verify that you're human.</p> */}
              <div className="iframe-captcha">
                <ReCAPTCHA
                  sitekey="6LfyjLMqAAAAAMNRtOXY7MBoWPnpDF42u28LYvi7"
                  onChange={onCaptchaChange}
                  className="recaptchaframe"
                />
              </div>
            </div>
            {codeSent && (
              <button
                onClick={handleVerifyEmailClick}
                className="btn-green"
                // disabled={!captchaToken}
              >
                Verify Email
              </button>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default VerifyEmail;
