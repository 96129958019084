import React, { useEffect, useState } from "react";
import TwitterLogin from "react-twitter-auth";
import { API_URL_SURVEY } from "../../utils/ApiUrl";
import axios from "axios";
import Loader from "../../hooks/loader";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
const ConnecrTwitter = ({ setSteps, userData }) => {
  if (userData?.isTwitterVerified) {
    setSteps(3);
  }

  const token = localStorage.getItem("accessToken");
  // console.log("Access token", token);
  const { account } = useWeb3React();

  if (token && account) {
    const signerAccount = localStorage.getItem("signerAccount");
    if (signerAccount !== account) {
      setSteps(0);
    }
  } else if (!token || !account) {
    setSteps(0);
  }

  const [twitterAuthorization, setTwitterAutization] = useState(null);
  const [twitterStatus, settwitterStatus] = useState(false);
  const [userName, setUserName] = useState(null);
  const [imgNew, setImgeNew] = useState(null);
  const [twitterId, setTwitterId] = useState(null);
  // const [oauthToken1, setOauthToken1] = useState(null);
  // const [oauthVerifier1, setOauthVerifier1] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFailed = (error) => {
    console.log("error twitter", error);
    setError(JSON.stringify(error));
  };

  const onSuccess = (response) => {
    const parsedUrl = new URL(response?.url);
    const searchParams = parsedUrl.searchParams;
    const oauthVerifier = searchParams.get("oauth_verifier");
    const oauthToken = searchParams.get("oauth_token");
    if (response.status === 200) {
      setTwitterAutization(true);
      // setRecaptch(true);
      VerifyFollow(oauthVerifier, oauthToken);
      // setOauthVerifier1(oauthVerifier);
      // setOauthToken1(oauthToken);
    }
  };

  const VerifyFollow = async (a, b) => {
    // if (OauthVerifier1 && OauthToken1) {
    let OauthVerifier = a;
    let OauthToken = b;
    let accessToken = localStorage.getItem("accessToken");
    setLoading(true);
    setError(null);
    const postData = {};
    axios
      .patch(
        `${API_URL_SURVEY}/users/${userData?._id}/toggle-twitter?oauth_verifier=${OauthVerifier}&&oauth_token=${OauthToken}`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(async (response) => {
        console.log("toggle-twitter response", response);
        settwitterStatus(true);
        setUserName(response?.data?.data?.data?.twitterUsername);
        setTwitterId(response?.data?.data?.data?.twitterId);
        setImgeNew("/assets/gemscommunityproject/twitter-img.svg");
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error?.response);

        setUserName(error?.response?.data?.screen_name);
        setImgeNew("/assets/gemscommunityproject/twitter-img.svg");
        if (
          error?.response?.data?.message ==
          "New Twitter accounts are not accepted."
        ) {
          setError(
            "Sorry, it appears that your X account is not yet 1 month old."
          );
        } else {
          setError(error?.response?.data?.message);
        }
        setLoading(false);
        // if (error?.response?.data?.oauth_token_secret) {
        //   //   setRecaptch(true);
        //   setOauthToken1(error?.response?.data?.oauth_token);
        //   setOauthVerifier1(error?.response?.data?.oauth_token_secret);
        // }
      });
    // }
  };

  const handleRemoveClick = async () => {
    if (twitterStatus) {
      let accessToken = localStorage.getItem("accessToken");

      try {
        const payload = {
          twitterUsername: userName,
          twitterId: `${twitterId}`,
        };

        const response = await axios.delete(`${API_URL_SURVEY}/users/twitter`, {
          data: payload,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setError(null);
        setUserName(null);
        setImgeNew(null);
        setTwitterAutization(null);
        settwitterStatus(false);
      } catch (error) {
        if (error?.response) {
          console.log("error", error?.response);
          // setError(error?.response?.data?.message);
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("An error occurred while removing Twitter account.");
        }
      }
    } else {
      setError(null);
      setUserName(null);
      setImgeNew(null);
      setTwitterAutization(null);
      settwitterStatus(false);
    }
  };

  const handleContinueClick = () => {
    if (twitterStatus) {
      setSteps(3);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="connecttwitter">
          <div className="main-heading">
            <h4>
              Are you scared to wake up one day and discover you’re a bot?
            </h4>
            <p>
              OMG Same! Verify your X account so we both know our token will go
              to a human:
            </p>
          </div>
          <div className="twitter-box common-box">
            <img
              src="\assets\gemscommunityproject\twitter-img.svg"
              alt="img"
              className="img-fluid hide-in-light-theme"
            />
            <img
              src="\assets\gemscommunityproject\twitter-img-light.svg"
              alt="img"
              className="img-fluid d-none show-in-light-theme"
            />
            <p className="para">
              X account must be at least 1 month old to participate
            </p>
            <div className="option-field">
              <label>Your X account</label>

              {!twitterAuthorization ? (
                <TwitterLogin
                  loginUrl={API_URL_SURVEY + "/users/twitter"}
                  onFailure={onFailed}
                  onSuccess={onSuccess}
                  requestTokenUrl={API_URL_SURVEY + "/users/twitter-auth"}
                  className="btn-connect"
                >
                  <div>Connect X </div>
                </TwitterLogin>
              ) : (
                <>
                  <div className={`when-accounthave ${error && "error"}`}>
                    <div className="left-detail">
                      <div className="profile">
                        {/* <img src={imgNew} alt="img" className="img-fluid" /> */}
                        <img
                          src="\assets\gemscommunityproject\twitter-img.svg"
                          alt="img"
                          className="img-fluid hide-in-light-theme"
                        />
                        <img
                          src="\assets\gemscommunityproject\twitter-img-light.svg"
                          alt="img"
                          className="img-fluid d-none show-in-light-theme"
                        />
                      </div>
                      <h6>@{userName}</h6>
                    </div>
                    <a
                      href="#"
                      className="btn-remove"
                      onClick={handleRemoveClick}
                    >
                      Remove
                    </a>
                  </div>
                  {error && <p className="error-text">{error}</p>}
                </>
              )}
            </div>
            <button
              onClick={handleContinueClick}
              className={`btn-green ${twitterStatus ? "" : "disabled"}`}
              aria-disabled={!twitterStatus}
              // disabled={!twitterStatus}
            >
              CONTINUE
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default ConnecrTwitter;
