import React, { useEffect } from 'react'
import "./privacy.scss"
import Privacybanner from './Privacybanner';

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="wrapper-apply">
            <Privacybanner />
            <section className="terms-section">
                <div className="custom-container">
                    <div className="insuranceinner">
                        <h6 className="insurancehead">
                            Privacy Policy
                        </h6>
                        <p className="midpara">
                            Last Revised: July 23, 2024
                        </p>
                        <p className="midpara">Gems Labs Limited ("<span className="midparabold">us</span>", "<span className="midparabold">we</span>" or "<span className="midparabold">Company</span>") respect the privacy of our users (each, "<span className="midparabold">you</span>" or "<span className="midparabold">User</span>") and are committed to protect the privacy of Users who access, visit, use or engage with our launchpad, available at: <a href="https://gems.vip" target='_blank'>https://gems.vip</a>, or any other online services we provide (the "<span className="midparabold">Services</span>").</p>
                        <p className="midpara">We have prepared this Privacy Policy to outline our practices with respect to collecting, using and disclosing your information when you use the Services.
                        </p>
                        <p className="midpara">We encourage you to read this Privacy Policy carefully and use it to make informed decisions. By using the Services, you agree to the terms of this Privacy Policy and your continued use of the Services constitutes your ongoing agreement to the Privacy Policy.</p>
                        <p className="midpara">This Privacy Policy is a part of our Terms of Service, available here, and is incorporated therein by reference.</p>

                        <p className="midpara">In this Privacy Policy you will read about, among other things:
                        </p>

                        <ul>
                            <li>What type of information do we collect?</li>
                            <li>Cookies and other technologies</li>
                            <li>How we use the information</li>
                            <li>Who do we share information with, and why?</li>
                            <li>International transfer</li>
                            <li>How long do we retain information for?</li>
                            <li>Your privacy rights</li>
                            <li>How do we protect your information?</li>
                            <li>Marketing</li>
                            <li>How to contact us</li>

                        </ul>

                        <h6 className="insurancehead">
                            What type of information do we collect?
                        </h6>
                        <p className="midpara">We may collect two types of data and information from our Users.</p>
                        <p className="midpara">The first type of information is un-identified and non-identifiable information pertaining to you, which may be made available or gathered via your use of the Services ("<span className="midparabold">Non-personal Information</span>"). We are not aware of the identity from which the Non-personal Information is collected. Non-personal Information which is collected may include your aggregated usage information and technical information transmitted by your device, including certain software and hardware information about your device (e.g., the device you use, the type of browser and operating system your device uses, language preference, etc.), in order to enhance the functionality of the Services. We may also collect information about your activity on the Services (e.g., mouse movements, mouse clicks, actions, online browsing etc.).</p>
                        <p className="midpara">The second type of information is individually identifiable information, namely information that identifies an individual or may with reasonable effort identify an individual ("<span className="midparabold">Personal Information</span>"). Such information may include:</p>
                        <ul>
                            <li>Account Information: When you sign-up and register to the Services, depending on how you use the services (i.e. whether you are a Leader, a blockchain project, or otherwise browsing and/or purchasing tokens) you maybe asked to provide us certain details about yourself, including, without limitation, your full name, device address, email address, location services, wallet address, as well as any other information we may require in order to facilitate a KYC process (i.e. name, ID, nationality, DOB, etc.), to the extent relevant.</li>
                            <li>Voluntary Information: We may collect information which you provide us voluntarily. Further, we may collect information when you respond to communications from us, communicate with us via email or share additional information about yourself through your use of the Services. We may also collect the feedback, suggestions, complaints, bugs and reports which you send to us.</li>
                            <li>Device Information: We may collect Personal Information from your device. Such information may include geolocation data, IP address, unique identifiers as well as other information which relates to your activity through the Services.</li>
                        </ul>
                        <p className="midpara">For the avoidance of doubt, if we combine Personal Information with Non-personal Information, the combined information will be treated as Personal Information as long as it remains combined.</p>
                        <p className="midpara">The Services are not designated to individuals under the age of 13. If you are under 13 years old, you should not use the Services, nor provide any personal information to us. We reserve the right to access and verify any personal information collected from you by us. In the event that we become aware that an individual under the age of 13 has shared any information, we will discard such information. If you have any reason to believe that a minor has shared any information with us, it will be advisable to contact us at <a href="mailto: Info@gems.vip">Info@gems.vip</a>.</p>
                        <h6 className="insurancehead">
                            How do we use your information?
                        </h6>
                        <p className='midpara'>We use and share Personal Information in the manners described in this Privacy Policy. In addition to the purposes listed above, the information we collect, which may include your Personal Information, is used for any of the following purpose, depending on the nature of your use:</p>
                        <ul>
                            <li>To communicate with you and to keep you informed of our latest updates;</li>
                            <li>To set up your account and to provide the Services;</li>
                            <li>To identify and authenticate your access to certain features of the Services;</li>
                            <li>To perform research or to conduct analytics in order to improve and customize the Services to your needs and interests;</li>
                            <li>To support and troubleshoot the Services and to respond to your queries;</li>
                            <li>To analyze consumer trends on an aggregate and anonymized basis and share such trends with our partners;</li>
                            <li>To investigate and resolve disputes in connection with your use of the Services;</li>
                            <li>To detect and prevent fraudulent and illegal activity or any other type of activity that may jeopardize or negatively affect the integrity of the Services; and</li>
                            <li>To investigate violations and enforce our policies, and as required by law, regulation or other governmental authority, or to comply with a subpoena or similar legal process or respond to a government request.</li>
                        </ul>
                        <h6 className="insurancehead">
                            Who do we share your information with, and why?
                        </h6>
                        <p className='midpara'>We do not rent, sell, or share your Personal Information with third-parties except as described in this Privacy Policy.</p>
                        <p className='midpara'>We may share information, including Personal Information, with the following recipients: (i) our subsidiaries and affiliated companies; (ii) subcontractors and other third-party service providers; (iii) auditors or advisers of our business processes; and (iv) any potential purchasers or investors in the Company. In the event of a corporate transaction (e.g., sale of a substantial part of our business, merger, consolidation or asset sale of an asset or transfer in the operation thereof), the acquiring company or transferee will assume the rights and obligations as described in this Privacy Policy.</p>
                        <p className='midpara'>In addition to the purposes listed in this Privacy Policy, we may share Personal Information with our recipients for any of the following purposes: (i) storing or processing Personal Information on our behalf (e.g., cloud computing service providers); (ii) processing such information to assist us with our business operations; (iii) performing research, technical diagnostics, personalization and analytics.</p>
                        <p className='midpara'>We may also disclose Personal Information, or any information you submitted via the Services if we have a good faith belief that disclosure of such information is helpful or reasonably necessary to: (i) comply with any applicable law, regulation, legal process or governmental request; (ii) enforce our policies, including investigations of potential violations thereof; (iii) investigate, detect, prevent, or take action regarding illegal activities or other wrongdoing, suspected fraud or security issues; (iv) to establish or exercise our rights to defend against legal claims; (v) prevent harm to the rights, property or safety of us, our affiliates, our Users, yourself or any third-party; (vi) for the purpose of collaborating with law enforcement agencies; and (vii) in case we find it necessary in order to enforce intellectual property or other legal rights.</p>
                        <h6 className="insurancehead">
                            Third-party collection of information
                        </h6>
                        <p className='midpara'>Our policy only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties via the Services (e.g., by connecting your account on the Service with third party services or by clicking on a link to any other website or location) or via other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them.</p>
                        <p className='midpara'>You acknowledge that we are not responsible for the products, services, or descriptions of products or services that you receive from third-party sites or to the content or privacy practices of those sites, and that this Privacy Policy does not apply to any such third-party products and services. You are knowingly and voluntarily assuming all risks of using third-party sites to purchase products and services. You agree that we shall have no liability whatsoever with respect to such third-party sites and your usage of them.</p>
                        <h6 className="insurancehead">
                            International transfer
                        </h6>
                        <p className='midpara'>Since we operate globally, it may be necessary to transfer data, including Personal Information, to countries outside the European Union. The data protection and other laws of these countries may not be as comprehensive as those in the European Union − in these instances we will take steps to ensure that a similar level of protection is given to Personal Information, including through adequate contractual measures. You hereby consent to the transfer of Personal Information to countries outside the European Union.</p>

                        <h6 className="insurancehead">For How Long Do We Retain Your Information For?</h6>
                        <p className='midpara'>We respect your privacy rights and therefore you may contact us at any time and request: (i) to access, delete, change or update any Personal Information relating to you (for example, if you believe that your Personal Information is incorrect, you may ask to have it corrected or deleted); or (ii) that we will cease any further use of your Personal Information (for example, you may ask that we will stop using or sharing your Personal Information with third-parties) or that we shall remove your Personal Information (subject to any other legal obligation that may require us to keep the information).</p>
                        <p className='midpara'>Please note that unless you instruct us otherwise we retain the information we collect for as long as needed to provide the Services and to comply with our legal obligations, resolve disputes and enforce our agreements. Without derogating from the foregoing, in any other case we will retain the information for a maximum period of ten (10) years. We may rectify, replenish or remove incomplete or inaccurate information, at any time and at our own discretion.</p>
                        <h6 className="insurancehead">Your Rights</h6>
                        <p className='midpara'>Certain jurisdictions provide users with certain statutory rights with respect to their Personal Information. Subject to exemptions provided by law, and with proper identification, you may have some/all of the following rights available to you:</p>
                        <ul>
                            <li>Right to verify your information (to access, delete, change or update any personal information relating to you. For example, if you believe that your personal information is incorrect, you may ask to have it corrected or deleted);</li>
                            <li>Right to see what, if any, personal information about you we have;</li>
                            <li>Right to request that we erase information about you (subject to any other legal obligation that may require us to keep the information);</li>
                            <li>Right to restrict us from processing information pertaining to you; and</li>
                            <li>Right to export information in a portable format;</li>
                        </ul>
                        <p className='midpara'>If you wish to exercise any of these rights, please contact us at: <a href="mailto: Info@gems.vip">Info@gems.vip</a>. When handling your request, we may ask for additional information to confirm your identity (which may include personal information). However, please note that if you choose not to allow us to process your personal information, we may not be able to provide you the Services.</p>
                        <p className='midpara'>If you wish to raise a complaint on how we have handled your Personal Information, please contact us directly at <a href="mailto: Info@gems.vip">Info@gems.vip</a>. If you are not satisfied with our response or believe we are collecting or processing your Personal Information not in accordance with applicable law, you can complain to your applicable data protection authority.</p>
                        <h6 className="insurancehead">How do we protect your information?</h6>
                        <p className='midpara'>We take great care in implementing and maintaining the security of the Services and your information. We employ industry standard procedures and policies to ensure the safety of your information, and prevent unauthorized use of any such information. Although we take reasonable steps to safeguard information, we cannot be responsible for the acts of those who gain unauthorized access or abuse the Services, and we make no warranty, express, implied or otherwise, that we will prevent such access.</p>
                        <p className='midpara'>If you feel that your privacy was treated not in accordance with our policy, or if any person attempted to abuse the Services or acted in an inappropriate manner, please contact us directly at <a href="mailto: Info@gems.vip">Info@gems.vip</a>.</p>
                        <h6 className="insurancehead">Marketing</h6>
                        <p className='midpara'>We may use your personal information, such as your full name, email address, etc., either directly or indirectly, to send you notifications, messages and other updates about new features, offerings, events and special opportunities or any other information we think you may find valuable. We may provide such notices through any of the contact means available to us, through the Services, or through our marketing campaigns on any other sites or platforms.</p>
                        <p className='midpara'>If you do not wish to receive such promotional communications, you may notify us at any time by changing your communications preferences in your account, by following the “unsubscribe”, “stop”, “opt-out” or “change e-mail preferences” instructions contained in the promotional communications you receive, or by sending an email to <a href="mailto: Info@gems.vip">Info@gems.vip</a>.</p>
                        <p className='midpara'>Please note that even if you unsubscribe from our marketing mailing list, we may continue to send you service-related updates and notifications.</p>
                        <h6 className="insurancehead">Updates or amendments to the Privacy Policy</h6>
                        <p className='midpara'>We may revise this Privacy Policy from time to time, in our sole discretion, and the most current version will always be posted on our launchpad (as reflected in the "Last Revised" heading). In the event of a material change to the Privacy Policy, we will notify you through the Services or via email. We encourage you to review this Privacy Policy regularly for any changes.</p>
                        <p className='midpara'>Your continued use of the Services, following the notification of such amendments, constitutes your acknowledgement and consent of such amendments to the Privacy Policy and your agreement to be bound by the terms of such amendments.</p>
                        <h6 className="insurancehead">How to contact us</h6>
                        <p className='midpara'>If you have any general questions regarding the Services or the information that we collect about you and how we use it, please contact us at <a href="mailto: Info@gems.vip">Info@gems.vip</a>.</p>
                        <h6 className="insurancehead">Information about us</h6>
                        <p className='midpara'>The details of the Company are as follows:</p>
                        <p className='midpara'>Gems Labs Limited</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Privacy
