import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Information = () => {
    const { t } = useTranslation()



    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>Overview <span></span></h2>
                    <p>Gems Nodes are a new product within the Gems ecosystem. Node operators receive three distinct benefits: claimable token airdrops from projects launching through the Self-Serve feature on Gems Launchpad, additional allocation from projects launched on Rocks, and rewards from projects listed on Gems Trade.
                    </p>

                </div>
                <div className="first-para">
                    <h2>Product Features <span></span></h2>
                    <h6>NFT Format</h6>
                    <ul>
                        <li>Unique digital ownership
                        </li>
                        <li>Non-tradeable and non-transferable NFTs</li>
                        <li>Unlimited scalability with no cap on total supply</li>
                    </ul>
                    <h6>Token Distribution</h6>
                    <ul>
                        <li>Minimum 1% allocation from each Self-Serve project</li>
                        <li>Additional allocation from Rocks platform projects</li>
                        <li>Rewards from projects listed on Gems Trade</li>
                        <li>Equal distribution among all active nodes</li>
                        <li>2-year airdrop period per project</li>
                        <li>Continuous benefits from new projects</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>How It Works  <span></span></h2>
                    <h6>Token Generation</h6>
                    <p>The monthly token generation is dynamic, adjusting based on:</p>
                    <ul>
                        <li>Number of active nodes</li>
                        <li>Number of active projects
                        </li>
                        <li>Project completion cycles</li>
                    </ul>
                    <h6>Claiming Process</h6>
                    <ul>
                        <li>First claim available after project's public sale</li>
                        <li>Regular claims every 28 days</li>
                        <li>Separate claims for each project's tokens</li>
                        <li>Option to claim accumulated tokens at once</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>Technical Details<span></span></h2>
                    <ul>
                        <li><span>Purchase currency:</span> GEMS tokens</li>
                        <li><span>Node type:</span> Single tier</li>
                        <li><span>Operation period:</span> Perpetual</li>
                        <li><span>Claim interval:</span> 28 days</li>
                        <li><span>Price:</span> $100 in GEMS tokens</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>Utility<span></span></h2>
                    <ul>
                        <li>Passive token accrual from multiple projects
                        </li>
                        <li>Required for operating Project Miners</li>
                        <li>Community participation in the Gems ecosystem</li>
                    </ul>
                </div>

            </section>

            
        </>
    )
}

export default Information
