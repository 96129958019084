import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

const Media = ({ medias }) => {
  // console.log("{{{{{{{{{", medias?.media)
  const { t } = useTranslation()
  const [show, setShow] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState('');
  const [isVideo, setIsVideo] = useState(false);

  const handleShow = (mediaUrl) => {
    setSelectedMedia(mediaUrl);
    setIsVideo(checkIfVideo(mediaUrl));
    setShow(true);
  };

  const handleClose = () => setShow(false);

  // const mediaItems = [
  //   'https://via.placeholder.com/300x200',
  //   'https://www.w3schools.com/html/mov_bbb.mp4',
  //   'https://via.placeholder.com/300x201',
  //   'https://www.w3schools.com/html/movie.ogv',
  //   'https://res.cloudinary.com/drt6vurtt/video/upload/v1726068889/hodlr/bannervideo_zmgoww.mp4',
  //   'https://www.w3schools.com/html/mov_bbb.avi',
  // ];

  const checkIfVideo = (url) => {
    return url?.match(/\.(mp4|webm|ogg|ogv|mov|m4v|m4p|m4b|m4r|m4a|avi|wmv|flv|3gp|3g2)$/i);
  };
  return (
    <>
      {/* <div>
        
      </div> */}
      <section className="media-section">
        <div className="first-para" style={{ margin: "0" }}>
          <h2>{t('Gems-detailt44')} <span></span></h2>
        </div>
        <div className="bottom-gallery">
          {medias?.media?.map((media, index) => (
            <div className="single-gallery" key={index} style={{ marginBottom: '20px', cursor: 'pointer' }} onClick={() => handleShow(media.url)}>
              {checkIfVideo(media.url) ? (
                <video>
                  <source src={media.url} />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={media.url} alt="Media item" />
              )}
            </div>
          ))}
          {/* {images.map((image, index) => (
            <div className="single-gallery" key={index}>
              <img
                src={image}
                alt={`img-${index}`}
                className="img-fluid"
                onClick={() => handleShow(image)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          ))} */}

        </div>
      </section>

      <Modal className='common-modal-style image-preview' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='p-0'>
          {isVideo ? (
            <video controls className="w-100">
              <source src={selectedMedia} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={selectedMedia} alt="Selected" className="img-fluid w-100" />
          )}
        </Modal.Body>
      </Modal>

      {/* <Modal className='common-modal-style image-preview' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='p-0'>
          <img src={selectedImage} alt="Selected" className="img-fluid w-100" />
        </Modal.Body>
      </Modal> */}
    </>
  )
}

export default Media
