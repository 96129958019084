import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import CuurentRound from "../../hooks/DataFetchers/round.js";
import Timer from "../../hooks/DataFetchers/time.js";
import CuurentRoundl from "../../hooks/DataFetchers/roundl.js";
import Timerl from "../../hooks/DataFetchers/timel.js";
import axios from "axios";
import RoundCardsss from "./RoundCardsss.js";
import Environment from "../../utils/Environment.js";


const PresalecardsLive = ({ role, setMainLoader1 }) => {
    var currencyFormatter = require("currency-formatter");
    const { t } = useTranslation();
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [Allprojectssss, setAllprojectssss] = useState([])


    const getAllProjectsData = async (page = 1) => {
        setAllprojectssss([])
        setMainLoader1(true)
        if (role === "live") {
            const offset = page;
            var config = {
                method: "get",
                url: `${Environment?.Api_Url}/projects/all?limit=12&status=active&offset=${offset}`,
                headers: {
                    // authorization: `Bearer ${tok}`
                },
            };
        }
        axios(config)
            .then(function (response) {
                if (response) {
                    const data = response.data;

                    setTotalPages(data?.data?.pages || 0);
                    setTotalCount(data?.data?.count || 0);
                    // console.log("valueheresss", response)
                    // console.log("asddewdfewfwefwef", response)
                    setAllprojectssss(response?.data?.data?.projects)
                    setMainLoader1(false)
                }
            })
            .catch(function (error) {
                setMainLoader1(false)

                console.error("Error fetching data:", error);
            });
    };

    // useEffect(() => {
    //     if (role) {
    //         getAllProjectsData();
    //     }
    // }, [role])

    // console.log("hitttt")

    useEffect(() => {
        if (role) {
            if (role === "live") {
                getAllProjectsData(currentPage);
                // fetchProjects(currentPage);
            }
        }
    }, [role, currentPage]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };



    const getTimeRemaining = (startDate, endDate, status) => {
        const currentTime = new Date();
        // console.log("currenmt time here is ", currentTime)
        let timeDiff;

        if (status === 'starting') {
            timeDiff = new Date(startDate) - currentTime;
        } else if (status === 'ending') {
            timeDiff = new Date(endDate) - currentTime;
        }

        if (timeDiff <= 0) return null;

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const getRoundStatus = (startDate, endDate) => {
        const currentTime = new Date();
        // console.log("startdate end date",startDate ,endDate)
        if (currentTime < new Date(startDate)) {
            return 'starting';
        } else if (currentTime >= new Date(startDate) && currentTime <= new Date(endDate)) {
            return 'ending';
        } else {
            return 'Ended';
        }
    };

    const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);




    return (
        <>
            <section className="process-projects presale-cards">
                <div className="custom-container padd-right-sm">
                    <div className={Allprojectssss?.length <= 0 ? "" : "bottom-cards"}>
                        {/* {Allprojectssss?.map((round, index) => ( */}

                        {Allprojectssss?.map((project, index) => {
                            // Find the first round that is "Upcoming" or "Ongoing"
                            const validRound = project?.allRounds?.find((round) => {
                                const roundStatus = getRoundStatus(round?.startDate, round?.endDate);
                                return roundStatus === 'starting' || roundStatus === 'ending';
                            });

                            // If there's a valid round, render it
                            if (validRound) {
                                // console.log("valind round here is", validRound)
                                const roundStatus = getRoundStatus(validRound?.startDate, validRound?.endDate);
                                const remainingTime = getTimeRemaining(validRound?.startDate, validRound?.endDate, roundStatus);
                                return (
                                    <div className="single-card" key={index}>
                                        <Link to={`/Gems/${project?.projectName}`}>
                                            <div className="main-token-img">
                                                <img width={70} height={70} src={project?.projectImg} alt="img" className="img-fluid" />
                                            </div>

                                            <div className="token-info">
                                                <h6>{project?.projectName} {project?.isPremiumProject ? <span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                    <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                                </svg> Verified Gems</span> : ""} </h6>
                                                <p className="sub-title">{project?.tokenSymbol}</p>
                                                {project?.projectSummary && (
                                                    <p className="para description">{project?.projectSummary}</p>
                                                )}
                                            </div>
                                            <div className="tokenprice-info">
                                                <div className="text">
                                                    <p>{t('Gems-detailt9')}</p>
                                                    <h6>{validRound?.roundNumber || "--"} / {project?.allRounds?.length > 0 ? (project?.allRounds?.length) : (0)}</h6>
                                                </div>
                                                <div className="text">
                                                    <p>{t('Process-t3')}</p>
                                                    <h6>{validRound?.roundPrice ? `$${validRound?.roundPrice}` : "--"}</h6>
                                                </div>
                                                <div className="text">
                                                    <p>{t('Totalrasied')}</p>
                                                    <h6>
                                                        <span>
                                                            {project?.totalAmountRaised[0]?.totalAmountRaised
                                                                ? `$${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                                                                    .format(project?.totalAmountRaised[0]?.totalAmountRaised)
                                                                    .split('.')[0]
                                                                    .replace('$', '')}` // Keeps the dollar sign
                                                                : '--'}
                                                        </span>
                                                        {" "}
                                                        /
                                                        {" "}
                                                        {project?.hardCap
                                                            ? `$${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                                                                .format(project?.hardCap)
                                                                .split('.')[0]
                                                                .replace('$', '')}` // Keeps the dollar sign
                                                            : '--'}
                                                    </h6>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="round-info">
                                            {roundStatus === 'starting' && (
                                                <>
                                                    <h6>{t('Process-t13')}</h6>
                                                    <h6>
                                                        {remainingTime.days ? `${remainingTime.days}D : ` : '0D : '}
                                                        {remainingTime.hours ? `${remainingTime.hours}H : ` : '0H : '}
                                                        {remainingTime.minutes ? `${remainingTime.minutes}M : ` : '0M : '}
                                                        {remainingTime.seconds ? `${remainingTime.seconds}S` : '0S'}
                                                    </h6>
                                                </>
                                            )}

                                            {roundStatus === 'ending' && (
                                                <>
                                                    <h6>{t('Process-t10')}</h6>
                                                    <h6>
                                                        {remainingTime.days ? `${remainingTime.days}D : ` : '0D : '}
                                                        {remainingTime.hours ? `${remainingTime.hours}H : ` : '0H : '}
                                                        {remainingTime.minutes ? `${remainingTime.minutes}M : ` : '0M : '}
                                                        {remainingTime.seconds ? `${remainingTime.seconds}S` : '0S'}
                                                    </h6>
                                                </>
                                            )}

                                            {roundStatus === 'ended' && (
                                                <>
                                                    <h6>{t('RoundIs')}</h6>
                                                    <h6 className="ended">{t('Finished')}</h6>
                                                </>
                                            )}
                                        </div>


                                        {roundStatus === 'starting' && (
                                            <a href="#" className="btn-buy" style={{ opacity: "0.5", pointerEvents: "none" }}>
                                                {t('Btn-buynow')}
                                            </a>
                                        )}

                                        {roundStatus === 'ending' && (
                                            <a
                                                href={`https://buy.gems.vip/?projectName=${project?.projectName}`}
                                                target="_blank"
                                                className="btn-green"
                                            >
                                                {t('Btn-buynow')}
                                            </a>
                                        )}

                                        {roundStatus === 'ended' && (
                                            <Link to={`/Gems/${project?.projectName}`} className="btn-buy">
                                                {t('Process-t7')}
                                            </Link>
                                        )}
                                    </div>
                                );

                            }
                            else {
                                // If no valid round is found, show a message that all rounds have ended
                                return (
                                    <div className="single-card" key={index}>
                                        <Link to={`/Gems/${project?.projectName}`}>
                                            <div className="main-token-img">
                                                <img width={70} height={70} src={project?.projectImg} alt="img" className="img-fluid" />
                                            </div>
                                        </Link>
                                        <div className="token-info">
                                            <h6>{project?.projectName} {project?.isPremiumProject ? <span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                            </svg> Verified Gems</span> : ""} </h6>
                                            <p className="sub-title">{project?.tokenSymbol}</p>
                                            {project?.projectSummary && (
                                                <p className="para description">{project?.projectSummary}</p>
                                            )}
                                        </div>
                                        <div className="tokenprice-info">
                                            <div className="text">
                                                <p>{t('Gems-detailt9')}</p>
                                                <h6>{validRound?.roundNumber || "--"}</h6>
                                            </div>
                                            <div className="text">
                                                <p>{t('Process-t3')}</p>
                                                <h6>{validRound?.roundPrice ? `$${validRound?.roundPrice}` : "--"}</h6>
                                            </div>
                                            <div className="text">
                                                <p>{t('Totalrasied')}</p>
                                                <h6>
                                                    <span>
                                                        {project?.totalAmountRaised[0]?.totalAmountRaised
                                                            ? `$${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                                                                .format(project?.totalAmountRaised[0]?.totalAmountRaised)
                                                                .split('.')[0]
                                                                .replace('$', '')}` // Keeps the dollar sign
                                                            : '--'}
                                                    </span>
                                                    {" "}
                                                    /
                                                    {" "}
                                                    {project?.hardCap
                                                        ? `$${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                                                            .format(project?.hardCap)
                                                            .split('.')[0]
                                                            .replace('$', '')}` // Keeps the dollar sign
                                                        : '--'}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="round-info">
                                            <>
                                                <h6>{t('RoundIs')}</h6>
                                                <h6 className="ended">{t('Finished')}</h6>
                                            </>
                                        </div>
                                        <Link to={`/Gems/${project?.projectName}`} className="btn-buy">
                                            {t('Process-t7')}
                                        </Link>
                                    </div>
                                );
                            }
                        })}


                        {
                            Allprojectssss?.length <= 0 && <>
                                <div className="whencardsnotshowing">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="37" viewBox="0 0 38 37" fill="none">
                                        <path d="M0.5 36.5L0.500002 20.8333L4.83334 20.8333L4.83334 32.75L0.5 36.5Z" fill="#77868B" />
                                        <path d="M0.5 16.166L0.500002 0.49935L4.83334 0.49935L4.83334 12.416L0.5 16.166Z" fill="#77868B" />
                                        <rect x="2.5" y="20.834" width="14.5" height="4.33333" fill="#77868B" />
                                        <rect x="2.5" y="0.5" width="14.5" height="4.33333" fill="#77868B" />
                                        <path d="M21 36.5L21 20.8333L25.3333 20.8333L25.3333 32.75L21 36.5Z" fill="#77868B" />
                                        <path d="M21 16.166L21 0.49935L25.3333 0.49935L25.3333 12.416L21 16.166Z" fill="#77868B" />
                                        <rect x="23" y="20.834" width="14.5" height="4.33333" fill="#77868B" />
                                        <rect x="33" y="32.166" width="4.5" height="4.33333" fill="#77868B" />
                                        <rect x="33" y="11.666" width="4.5" height="4.33333" fill="#77868B" />
                                        <rect x="12.5" y="32.166" width="4.5" height="4.33333" fill="#77868B" />
                                        <rect x="12.5" y="11.666" width="4.5" height="4.33333" fill="#77868B" />
                                        <rect x="23" y="0.5" width="14.5" height="4.33333" fill="#77868B" />
                                    </svg>
                                    <p>GOOD THINGS ARE ON THEIR WAY</p>
                                    <a href="https://creators.gems.vip/signup" className="btn-green" target="_blank" style={{ maxWidth: "150px", margin: "0 auto" }}>Create a Project</a>
                                </div>
                            </>
                        }

                    </div>
                </div>
                {
                    Allprojectssss?.length > 12 && (
                        <>
                            <div className="custom-table">
                                <div className="custom-pagination">
                                    <p>
                                        SHOWING {(currentPage - 1) * 12 + 1}-{Math.min(currentPage * 12, totalCount)} OF {totalCount}
                                    </p>
                                    <ul className="pagination">
                                        {/* Previous Button */}
                                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                            <a
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                href="#"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                    <g opacity="0.5">
                                                        <path d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z" fill="#939FA3" />
                                                        <path d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z" fill="#939FA3" />
                                                        <path d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z" fill="#939FA3" />
                                                    </g>
                                                </svg>
                                            </a>
                                        </li>

                                        {/* Page Numbers */}
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                                                <a
                                                    className="page-link"
                                                    onClick={() => handlePageChange(index + 1)}
                                                    href="#"
                                                >
                                                    {index + 1}
                                                </a>
                                            </li>
                                        ))}

                                        {/* Next Button */}
                                        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                            <a
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                href="#"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                    <path d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z" fill="#30F1B6" />
                                                    <path d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z" fill="#30F1B6" />
                                                    <path d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z" fill="#30F1B6" />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )
                }

            </section>
        </>
    );
};

export default PresalecardsLive;
