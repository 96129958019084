import { Web3Provider } from "@ethersproject/providers";
import { walletConnectV2 } from "../connectors/walletConnectV2";
import { metaMask } from "../connectors/metaMask";
import { coinbaseWallet } from "../connectors/coinbase";

const ConnectorNames = {
  Injected: "injected",
  WalletConnect: "walletconnect",
  CoinbaseWallet: "coinbaseWallet",
};

export const connectorsByName = {
  [ConnectorNames.Injected]: metaMask,
  [ConnectorNames.WalletConnect]: walletConnectV2,
  [ConnectorNames.CoinbaseWallet]: coinbaseWallet,
};

export const getLibraryForSign = (provider) => {
  const library = new Web3Provider(provider);
  console.log("library", library);
  return library;
};

export const getLibrary = (provider) => {
  return provider;
};
