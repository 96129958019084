import React, { useState } from 'react'
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap';

const Information = () => {
    const { t } = useTranslation()

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>{t('Dop-detail4')} <span></span></h2>
                    <p>Astra Nova is an AGI-driven gaming ecosystem that evolves with player behavior, redefining immersion and interactivity. As an NVIDIA Inception Partner, we fuse AI-driven storytelling, Web3 technology, and community-driven design to deliver immersive, dynamic experiences.
                    </p>
                    <p>Backed by the Shib Army, we blend cultural depth with innovation to redefine interactive entertainment and lead the next generation of AI gaming ecosystems. NPCs and monsters powered by advanced AI adapt and grow, crafting personalized quests and dynamic challenges. Developed on Unreal Engine 5, the game seamlessly integrates AI, UGC features, RWA rewards, and transmedia storytelling to offer immersive narratives across platforms in a universe on the brink of destruction.</p>
                </div>
                <div className="first-para">
                    <h2>Astra Nova go-to-market strategy <span></span></h2>
                    <p>Astra Nova is a truly unpredictable, infinitely replayable RPG, where AI adapts NPCs, enemies, and quests in real time, delivering a personalized and ever-evolving experience.</p>
                </div>
                <div className="first-para">
                    <h2>AGI-Driven Immersion <span></span></h2>
                    <p>NPCs, monsters, and quests dynamically adapt to player behavior, creating personalized challenges and evolving narratives powered by cutting-edge AI.</p>
                </div>
                <div className="first-para">
                    <h2>Industry-Leading Expertise<span></span></h2>
                    <p>With a team of 80+ veterans from NVIDIA, Ubisoft, Rockstar, and more—creators behind GTA 5, Mortal Kombat, and Assassin's Creed—Astra Nova is redefining gaming with AGI innovation.  </p>
                    <p>Powered by AI, our ecosystem integrates user-generated content, transmedia storytelling, and real-world asset rewards to create a seamless and dynamic gaming universe.</p>
                </div>
                <div className="first-para">
                    <h2>Live Ecosystem & Products<span></span></h2>
                    <p>Astra Nova is live with gameplay tests, a Loyalty Questing Platform, a Telegram game, and a Webtoon DAPP, all powered by NVIDIA AI and poised for rapid growth.  </p>
                    <p>AI empowers our community to shape the game through content creation, player-driven economies, and evolving narratives, making every decision impactful and meaningful.</p>
                </div>
                <div className="first-para">
                    <h2>Community-Centered Rewards<span></span></h2>
                    <p>Early adopters earn $RVV airdrops, real-world asset vouchers (e.g., Nike, Starbucks), and tokenized diamonds, driving engagement and loyalty.  </p>
                </div>

                <div className="first-para">
                    <h2>The Team <span></span></h2>
                    <div className="team-gems">
                        <div className="single-team">
                            <img src="\assets\alldetailpages\astra\info\team\asshar.png" alt="img" className='img-fluid' />
                            <h6>Muhamed Ashhar </h6>
                            <h5>Co-Founder</h5>
                            <p>Over 10 years as a startup entrepreneur in tech, procurement services, and logistics serving clients such as the US Army, BP, and Shell.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\astra\info\team\faizy.png" alt="img" className='img-fluid' />
                            <h6>Faizy Ahmed </h6>
                            <h5>Co-Founder</h5>
                            <p>Over 8 years as a startup entrepreneur in blockchain, education, trading, and venture capital; $3M+ raised for early-stage startups, Forbes Business Council member.
                            </p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\astra\info\team\ben.png" alt="img" className='img-fluid' style={{ objectPosition: "50%" }} />
                            <h6>Ben X Trinh </h6>
                            <h5>CSO / CCO</h5>
                            <p>15+ years as an award-winning strategist, creative and gaming marketer recently partnered with Riot, Epic, Niantic, LoL LCS, FaZe, Dignitas.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\astra\info\team\sarah.png" alt="img" className='img-fluid' style={{ objectPosition: "50%" }} />
                            <h6>Sarah Sultan </h6>
                            <h5>Regional Saudi Partner</h5>
                            <p>Sarah is a key driver in Astra Nova's quest to be at the forefront of the Web3 gaming revolution in the MENA region. Leveraging her extensive expertise in AI, Robotics, and Game Development.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\astra\info\team\dr.png" alt="img" className='img-fluid' style={{ objectPosition: "50%" }} />
                            <h6>Dr. Keith Barrows </h6>
                            <h5>legal counsel</h5>
                            <p>Leader in legal counsel with 20+ years experience. Specializing in Blockchain Law, AI Policy and Tech Ethics, and CryptoCounsel. Provides legal counsel.</p>
                        </div>
                    </div>

                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img" onClick={() => {setShow1(true)}} style={{cursor: "pointer"}}>
                            <img src="\assets\alldetailpages\astra\info\tokenomics.png" alt="img" className='img-fluid' />
                            {/* <img src="\assets\alldetailpages\astra\info\tokenomics-light.png" alt="img" className='img-fluid d-none show-in-light-theme ' /> */}
                        </div>
                    </div>
                </div>



            </section>

            <Modal className='common-modal-style image-preview distribution-modl' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                    <img src="\assets\alldetailpages\astra\info\tokenomics.png" alt="img" className='img-fluid' />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Information
