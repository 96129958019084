import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BlogDetailBanner from "./BlogDetailBanner";
import axios from "axios";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import Loader from "../../hooks/loader";

const BlogDetail = () => {
  const [blogsData, setBlogsData] = useState({});
  const [linkUser, setlinkuser] = useState("");
  const [linkText, setlinkText] = useState("");
  const [copy, setCopy] = useState(false);
  const location = useLocation();
  const { t } = useTranslation()
  const { id } = location?.state || {};
  const [mainLoader, setMainLoader] = useState(true);

  const params = useParams();

  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  useEffect(() => {
    if (params) {
      // getBlogs(params?.slug);
      getBlogDetails(params?.slug);
    }
  }, [params]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // getBlogDetails();
  }, []);
  useEffect(() => {
    var val = window.location.href;
    setlinkuser(val);
  }, [blogsData]);

  const getBlogs = (slug) => {
    var config = {
      method: "get",
      url: `https://blog.gems.vip/wp-json/wp/v2/posts?per_page=100`,
    };

    axios(config)
      .then(function (res) {
        console.log("blog data: ", res);
        const resData = res?.data;
        const filtered = resData?.filter((item) => item.slug === slug);
        console.log("filetered: ", filtered, filtered[0]?.id);

        // getBlogDetails(filtered[0]?.id);
        // setBlogsData(filtered);
      })
      .catch(function (error) {
        console.error("Error fetching blog data: ", error);
      });
  };

  const getBlogDetails = (id) => {
    var config = {
      method: "get",
      url: `https://blog.gems.vip/wp-json/wp/v2/posts?slug=${id}`,
    };

    axios(config)
      .then(function (res) {
        console.log("blog data: ", res);
        setBlogsData(res?.data[0]);
        setlinkText(res?.data[0]?.title?.rendered);
      })
      .catch(function (error) { });
  };

  function removePTags(htmlString) {
    return htmlString.replace(/<\/?p>/gi, "");
  }

  const twiterPost = async () => {
    const tweetUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      linkUser
    )}&text=${encodeURIComponent(linkText)}`;
    window.open(tweetUrl, "_blank");
    // clickApi('copyTwitter')
  };

  const linkedinShare = async () => {
    const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      linkUser
    )}&title=${encodeURIComponent(linkText)}`;
    window.open(linkedinUrl, "_blank");
    // clickApi('shareLinkedin')
  };

  const facebookShare = async () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      linkUser
    )}&quote=${encodeURIComponent(linkText)}`;
    window.open(facebookUrl, "_blank");
    // clickApi('shareFacebook')
  };

  const generalShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: linkText,
          url: linkUser,
        });
        console.log("Successful share");
        // clickApi('shareGeneral')
      } catch (error) {
        console.error("Error sharing", error);
      }
    } else {
      //   alert("Web Share API is not supported in your browser.");
      // Fallback for browsers that don't support the Web Share API
      const shareUrl = `mailto:?subject=${encodeURIComponent(
        linkText
      )}&body=${encodeURIComponent(linkUser)}`;
      window.open(shareUrl, "_blank");
    }
  };

  useEffect(() => {
      const timer = setTimeout(() => {
        setMainLoader(false);
      }, 3000);
  
      return () => clearTimeout(timer);
    }, []);

  return (
    <>
     {mainLoader && <Loader />}
      <div className="wrapper-apply wrapper-career blog-detail-wrapper wrapper-blog">
        <BlogDetailBanner
          title={blogsData?.title?.rendered}
          date={blogsData?.date}
        />
        <section className="blog-detail">
          <div className="custom-container">
            <div className="parent">
            <div className="left-side">
                <div className="main-heading">
                  <h6>{t('Sharethispost')}</h6>
                </div>
                <div className="social-links">
                  <a onClick={twiterPost}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M10.6433 1.3125H12.5729L8.35717 6.13083L13.3167 12.6875H9.43342L6.39192 8.71092L2.91176 12.6875H0.980922L5.49009 7.53375L0.732422 1.3125H4.71426L7.4635 4.94725L10.6433 1.3125Z" fill="#E1F5EB" />
                    </svg>
                  </a>
                  <a onClick={linkedinShare}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <g clip-path="url(#clip0_357_608)">
                        <path d="M13.9958 14L13.9993 13.9995V8.86496C13.9993 6.35313 13.4585 4.41821 10.522 4.41821C9.11034 4.41821 8.16301 5.19288 7.77626 5.9273H7.73542V4.65271H4.95117V13.9995H7.85034V9.3713C7.85034 8.15271 8.08134 6.97438 9.59042 6.97438C11.0773 6.97438 11.0995 8.36505 11.0995 9.44946V14H13.9958Z" fill="#E1F5EB" />
                        <path d="M0.230469 4.65332H3.13314V14.0001H0.230469V4.65332Z" fill="#E1F5EB" />
                        <path d="M1.68117 0C0.753083 0 0 0.753083 0 1.68117C0 2.60925 0.753083 3.37808 1.68117 3.37808C2.60925 3.37808 3.36233 2.60925 3.36233 1.68117C3.36175 0.753083 2.60867 0 1.68117 0Z" fill="#E1F5EB" />
                      </g>
                      <defs>
                        <clipPath id="clip0_357_608">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a onClick={facebookShare}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M8.08433 14V7.61441H10.2268L10.5483 5.12509H8.08433V3.53603C8.08433 2.81554 8.28358 2.32453 9.31793 2.32453L10.635 2.32399V0.097461C10.4072 0.0678617 9.62539 0 8.71539 0C6.81517 0 5.51425 1.15988 5.51425 3.28949V5.12509H3.36523V7.61441H5.51425V14H8.08433Z" fill="#E1F5EB" />
                    </svg>
                  </a>

                  {copy ? (
                    <a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill={copy ? "green" : "none"}
                      >
                        <path
                          d="M13.8821 2.90101C13.6035 2.62242 13.2728 2.40143 12.9088 2.25065C12.5448 2.09988 12.1547 2.02228 11.7607 2.02228C11.3667 2.02228 10.9766 2.09988 10.6126 2.25065C10.2486 2.40143 9.91788 2.62242 9.6393 2.90101L6.4578 6.08326C5.89518 6.64589 5.5791 7.40897 5.5791 8.20464C5.5791 9.00031 5.89518 9.76339 6.4578 10.326C7.02043 10.8886 7.78351 11.2047 8.57918 11.2047C9.37485 11.2047 10.1379 10.8886 10.7006 10.326L11.0771 9.94876C11.0771 9.94876 10.9076 9.47251 10.7006 9.26551C10.4936 9.05776 10.0166 8.88826 10.0166 8.88826L9.64005 9.26551C9.35869 9.54687 8.97708 9.70494 8.57918 9.70494C8.18127 9.70494 7.79967 9.54687 7.5183 9.26551C7.23694 8.98415 7.07887 8.60254 7.07887 8.20464C7.07887 7.80673 7.23694 7.42512 7.5183 7.14376L10.7006 3.96151C10.9819 3.68025 11.3635 3.52228 11.7613 3.52235C12.1592 3.52242 12.5407 3.68053 12.8219 3.96189C13.1032 4.24325 13.2612 4.62482 13.2611 5.02265C13.261 5.42049 13.1029 5.802 12.8216 6.08326L12.2913 6.61351C12.2913 6.61351 12.6588 6.85201 12.8898 7.08376C13.1223 7.31551 13.3518 7.67401 13.3518 7.67401L13.8821 7.14376C14.1606 6.86518 14.3816 6.53446 14.5324 6.17047C14.6832 5.80649 14.7608 5.41637 14.7608 5.02239C14.7608 4.62841 14.6832 4.23829 14.5324 3.8743C14.3816 3.51031 14.1606 3.17959 13.8821 2.90101Z"
                          fill={copy ? "green" : "black"}
                        />
                        <path
                          d="M12.2926 7.67397C12.014 7.39527 11.6832 7.17418 11.3192 7.02334C10.9551 6.8725 10.5649 6.79486 10.1708 6.79486C9.77677 6.79486 9.38656 6.8725 9.0225 7.02334C8.65845 7.17418 8.32768 7.39527 8.04909 7.67397L7.64334 8.08047C7.64334 8.08047 7.84433 8.53047 8.05133 8.73672C8.25833 8.94372 8.70383 9.14097 8.70383 9.14097L9.10958 8.73447C9.2489 8.59515 9.41429 8.48464 9.59632 8.40925C9.77834 8.33385 9.97344 8.29504 10.1705 8.29504C10.3675 8.29504 10.5626 8.33385 10.7446 8.40925C10.9266 8.48464 11.092 8.59515 11.2313 8.73447C11.3707 8.87379 11.4812 9.03918 11.5566 9.2212C11.632 9.40323 11.6708 9.59832 11.6708 9.79535C11.6708 9.99237 11.632 10.1875 11.5566 10.3695C11.4812 10.5515 11.3707 10.7169 11.2313 10.8562L8.04909 14.0385C7.90977 14.1777 7.74439 14.2882 7.56238 14.3636C7.38037 14.4389 7.18531 14.4777 6.98832 14.4776C6.59048 14.4776 6.20897 14.3195 5.92771 14.0381C5.64645 13.7567 5.48848 13.3752 5.48855 12.9773C5.48862 12.5795 5.64672 12.198 5.92808 11.9167L6.51233 11.3325C6.51233 11.3325 6.13508 11.0632 5.92733 10.8562C5.72108 10.6492 5.45109 10.272 5.45109 10.272L4.86758 10.8562C4.58386 11.1336 4.35799 11.4645 4.20304 11.8298C4.04809 12.1951 3.96715 12.5875 3.96489 12.9843C3.96264 13.3811 4.03912 13.7744 4.1899 14.1414C4.34069 14.5084 4.56279 14.8419 4.84334 15.1225C5.12388 15.4031 5.45731 15.6252 5.82431 15.7761C6.1913 15.9269 6.58458 16.0035 6.98137 16.0013C7.37815 15.9991 7.77056 15.9182 8.13587 15.7633C8.50119 15.6085 8.83214 15.3826 9.10958 15.099L12.2918 11.9167C12.5704 11.6381 12.7914 11.3074 12.9422 10.9434C13.093 10.5794 13.1706 10.1893 13.1706 9.79535C13.1706 9.40137 13.093 9.01125 12.9422 8.64726C12.7914 8.28327 12.5712 7.95255 12.2926 7.67397Z"
                          fill={copy ? "green" : "black"}
                        />
                      </svg>
                    </a>
                  ) : (
                    <>
                      <CopyToClipboard
                        text={window.location.href}
                        onCopy={(e) => textCopiedFun(true)}
                      >
                        <a>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill={copy ? "green" : "none"}
                          >
                            <path
                              d="M13.8821 2.90101C13.6035 2.62242 13.2728 2.40143 12.9088 2.25065C12.5448 2.09988 12.1547 2.02228 11.7607 2.02228C11.3667 2.02228 10.9766 2.09988 10.6126 2.25065C10.2486 2.40143 9.91788 2.62242 9.6393 2.90101L6.4578 6.08326C5.89518 6.64589 5.5791 7.40897 5.5791 8.20464C5.5791 9.00031 5.89518 9.76339 6.4578 10.326C7.02043 10.8886 7.78351 11.2047 8.57918 11.2047C9.37485 11.2047 10.1379 10.8886 10.7006 10.326L11.0771 9.94876C11.0771 9.94876 10.9076 9.47251 10.7006 9.26551C10.4936 9.05776 10.0166 8.88826 10.0166 8.88826L9.64005 9.26551C9.35869 9.54687 8.97708 9.70494 8.57918 9.70494C8.18127 9.70494 7.79967 9.54687 7.5183 9.26551C7.23694 8.98415 7.07887 8.60254 7.07887 8.20464C7.07887 7.80673 7.23694 7.42512 7.5183 7.14376L10.7006 3.96151C10.9819 3.68025 11.3635 3.52228 11.7613 3.52235C12.1592 3.52242 12.5407 3.68053 12.8219 3.96189C13.1032 4.24325 13.2612 4.62482 13.2611 5.02265C13.261 5.42049 13.1029 5.802 12.8216 6.08326L12.2913 6.61351C12.2913 6.61351 12.6588 6.85201 12.8898 7.08376C13.1223 7.31551 13.3518 7.67401 13.3518 7.67401L13.8821 7.14376C14.1606 6.86518 14.3816 6.53446 14.5324 6.17047C14.6832 5.80649 14.7608 5.41637 14.7608 5.02239C14.7608 4.62841 14.6832 4.23829 14.5324 3.8743C14.3816 3.51031 14.1606 3.17959 13.8821 2.90101Z"
                              fill={copy ? "green" : "black"}
                            />
                            <path
                              d="M12.2926 7.67397C12.014 7.39527 11.6832 7.17418 11.3192 7.02334C10.9551 6.8725 10.5649 6.79486 10.1708 6.79486C9.77677 6.79486 9.38656 6.8725 9.0225 7.02334C8.65845 7.17418 8.32768 7.39527 8.04909 7.67397L7.64334 8.08047C7.64334 8.08047 7.84433 8.53047 8.05133 8.73672C8.25833 8.94372 8.70383 9.14097 8.70383 9.14097L9.10958 8.73447C9.2489 8.59515 9.41429 8.48464 9.59632 8.40925C9.77834 8.33385 9.97344 8.29504 10.1705 8.29504C10.3675 8.29504 10.5626 8.33385 10.7446 8.40925C10.9266 8.48464 11.092 8.59515 11.2313 8.73447C11.3707 8.87379 11.4812 9.03918 11.5566 9.2212C11.632 9.40323 11.6708 9.59832 11.6708 9.79535C11.6708 9.99237 11.632 10.1875 11.5566 10.3695C11.4812 10.5515 11.3707 10.7169 11.2313 10.8562L8.04909 14.0385C7.90977 14.1777 7.74439 14.2882 7.56238 14.3636C7.38037 14.4389 7.18531 14.4777 6.98832 14.4776C6.59048 14.4776 6.20897 14.3195 5.92771 14.0381C5.64645 13.7567 5.48848 13.3752 5.48855 12.9773C5.48862 12.5795 5.64672 12.198 5.92808 11.9167L6.51233 11.3325C6.51233 11.3325 6.13508 11.0632 5.92733 10.8562C5.72108 10.6492 5.45109 10.272 5.45109 10.272L4.86758 10.8562C4.58386 11.1336 4.35799 11.4645 4.20304 11.8298C4.04809 12.1951 3.96715 12.5875 3.96489 12.9843C3.96264 13.3811 4.03912 13.7744 4.1899 14.1414C4.34069 14.5084 4.56279 14.8419 4.84334 15.1225C5.12388 15.4031 5.45731 15.6252 5.82431 15.7761C6.1913 15.9269 6.58458 16.0035 6.98137 16.0013C7.37815 15.9991 7.77056 15.9182 8.13587 15.7633C8.50119 15.6085 8.83214 15.3826 9.10958 15.099L12.2918 11.9167C12.5704 11.6381 12.7914 11.3074 12.9422 10.9434C13.093 10.5794 13.1706 10.1893 13.1706 9.79535C13.1706 9.40137 13.093 9.01125 12.9422 8.64726C12.7914 8.28327 12.5712 7.95255 12.2926 7.67397Z"
                              fill={copy ? "green" : "black"}
                            />
                          </svg>
                        </a>
                      </CopyToClipboard>
                      {copy === true && (
                        <p className="copy-text text-black">{t('Copied')}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="right-side">
                <div className="main-content">
                  {/* <h6
                    className="single-head"
                    dangerouslySetInnerHTML={{
                      __html: blogsData?.excerpt?.rendered,
                    }}
                  ></h6> */}
                  <img
                    // src="\assets\dummy\blogdetail.png"
                    src={blogsData?.uagb_featured_image_src?.full[0]}
                    alt="img"
                    className="img-fluid"
                  />
                  <div
                    className="single-head"
                    dangerouslySetInnerHTML={{
                      __html: blogsData?.content?.rendered,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogDetail;
