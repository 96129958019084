import React, { useEffect, useState } from "react";
import "./faqs.scss";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";


const Faqs = () => {
    const [activeKey, setActiveKey] = useState("0");
    const handleSelect = (key) => {
        setActiveKey(key);
    };
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [visibleGroups, setVisibleGroups] = useState(1);


    const handleLoadMore = () => {
        setVisibleGroups((prev) => prev + 1);
    };
    const handleShowLess = () => {
        setVisibleGroups(1);
      };

    return (
        <>
            <section className="mainfaqs faqs-miners">
                <div className="custom-container">
                    <div className="innerfaq">
                        <h2 className="head">Frequently Asked <span>Questions</span></h2>
                        <Accordion activeKey={activeKey} onSelect={handleSelect}>
                        {visibleGroups >= 1 && (
                            <>
                            <Accordion.Item
                                eventKey="0"
                                className={activeKey === "0" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>01.</span> What is a Gems Node?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">

                                        A Gems Node is a part of the Gems technological system that allows operators to receive token airdrops from projects launching on the Gems Launchpad Self-Serve feature, projects launching on Rocks, and projects that are listed on Gems Trade. Gems Nodes are of one type and sold at a fixed price of $100. They are represented as non-tradeable and non-transferable NFTs.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="1"
                                className={activeKey === "1" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>02.</span> What is a Miner?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">A Miner is an NFT-based tool that generates tokens from premium projects on Gems Launchpad. It must be connected to a Gems Node to operate and can be traded on secondary markets. Miners come in three types: Mini, Standard, and Mega. Miner NFTs can be traded and transferred to another wallet.</p>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="2"
                                className={activeKey === "2" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>03.</span>In which token can I buy a Gems Node?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Gems Nodes can be purchased using $GEMS tokens.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="3"
                                className={activeKey === "3" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>04.</span>In which token can I buy a Miner?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Miners can be purchased using USDT. Golden Users may enjoy discounted prices.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="4"
                                className={activeKey === "4" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>05.</span>Can I sell my Miner?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Yes, Miners arrive in the form of NFTs that can be sold on secondary markets as income-generating assets.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
                        )}
{visibleGroups >= 2 && (
                            <>
                            <Accordion.Item
                                eventKey="5"
                                className={activeKey === "5" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>06.</span>Can I sell my Gems Node?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">No, Gems Nodes are non-tradeable and non-transferable.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="6"
                                className={activeKey === "6" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>07.</span>Can both nodes and miners be bought together?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">No, they are sold and bought separately. Nodes are purchased with $GEMS while Miners are purchased with USDT.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="7"
                                className={activeKey === "7" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>08.</span>Is the claim for tokens from the Nodes and the Miners done together or separately?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">The claim is separate. These are two different tools.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="8"
                                className={activeKey === "8" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>09.</span>Are all types of Miners one NFT collection or separate collections?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">All Miners (Mini, Standard, and Mega) are part of one NFT collection on the ERC-1155 protocol. This allows for setting different rarities within the collection, with Mini Miners being more common and Mega Miners being rare and more valuable.  Each Miner type is represented by a different image.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="9"
                                className={activeKey === "9" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>10.</span>How long do Gems Nodes and Project Miners operate?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Gems Nodes continue to work indefinitely as new projects are launched on the Self-Serve feature, while Miners operate for a 2-year period for each project. MultiMiners might operate a while longer, depending on the TGE of the projects included in the Miner, since the token disbursement starts from the date of the TGE.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>
)}
{visibleGroups >= 3 && (
                            <>
                            <Accordion.Item
                                eventKey="10"
                                className={activeKey === "10" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>11.</span>Is there a limit to the number of Gems Nodes?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">No, there can be an unlimited number of Gems Nodes.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="11"
                                className={activeKey === "11" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>12.</span>Is there a limit to the number of Miners?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Yes, the number of Miners is limited. The sale hardcap for Miners is $40 million. Once this hardcap is reached, the sale of Miners will end, determining the total number of Miners. No additional Miners will be created after this point.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="12"
                                className={activeKey === "12" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>13.</span>What is the benefit of using a Leader code?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Using a Gems Leader code provides a 50% discount on Miner purchases.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="13"
                                className={activeKey === "13" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>14.</span>How can I get a Leader code?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Leader codes are available exclusively from authorized Gems Leaders. Look on social networks for communities whose Leader can provide you with a discount code.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="14"
                                className={activeKey === "14" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>15.</span>What is the difference between Gems Nodes and Miners?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Gems Nodes and Miners serve different purposes and have several key differences:</p>
                                    <h6>Purpose and Function:</h6>
                                    <ul>
                                        <li>Nodes receive airdrops from all Self-Serve projects (1% allocation), Rocks platform tokens, and Gems Trade rewards</li>
                                        <li>Miners generate tokens specifically from premium projects at fixed rates</li>
                                        <li>Nodes are required to operate Miners</li>
                                    </ul>
                                    <h6>Types and Availability:</h6>
                                    <ul>
                                        <li>There is only one type of Gems Node - all nodes are identical</li>
                                        <li>Miners come in three types (Mini, Standard, and Mega), each generating different amounts of tokens</li>
                                        <li>Nodes are unlimited in number</li>
                                        <li>Miners have a limited total supply</li>
                                    </ul>
                                    <h6>Price and Purchase:</h6>
                                    <ul>
                                        <li>Nodes have a fixed price of $99 in GEMS tokens</li>
                                        <li>Miner prices start at set levels but increase over time</li>
                                        <li>Miners can be purchased with USDT, and users can get 50% discount with a Leader code</li>
                                    </ul>
                                    <h6>Transferability:</h6>
                                    <ul>
                                        <li>Nodes are non-transferable and cannot be traded</li>
                                        <li>Miners are NFTs that can be traded on secondary markets</li>
                                    </ul>
                                    <h6>Operation Period:</h6>
                                    <ul>
                                        <li>Nodes operate indefinitely</li>
                                        <li>Miners have a 2-year operational period</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>)}
                            {visibleGroups >= 4 && (
                            <>
                            <Accordion.Item
                                eventKey="15"
                                className={activeKey === "15" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>16.</span>How do claims work?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>Claims are available every 28 days after each project TGE (Token Generation Event).</li>
                                        <li>Node and Miner claims are processed separately</li>
                                        <li>Users can track their claimable tokens in real-time on the Gems platform</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="16"
                                className={activeKey === "16" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>17.</span>Can I connect multiple Miners to one Node?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Yes, you can connect multiple Miners to a single Node. Think of a Node like a computer that can run multiple programs (Miners) simultaneously. There is no limit to how many Miners can be connected to one Node.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="17"
                                className={activeKey === "17" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>18.</span>How are token generation rates determined for different Miner types?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Each Miner type (Mini, Standard, and Mega) has a predetermined fixed generation rate. Higher tier Miners generate larger amounts of tokens, with Mega Miners providing the highest yields. The exact generation rates are set when the Miner is created and remain constant throughout its operational period.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="18"
                                className={activeKey === "18" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>19.</span>What happens after a Miner's 2-year operational period ends?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">After the 2-year operational period, the Miner stops generating tokens from its assigned projects. The NFT remains in your wallet but will no longer produce tokens. For MultiMiners, the operational period might extend beyond 2 years since token generation for each project starts from its individual TGE date.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="19"
                                className={activeKey === "19" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>20.</span>Can I upgrade my Miner to a higher tier?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">No, Miners cannot be upgraded. Each Miner's type (Mini, Standard, or Mega) is permanent and determined at the time of purchase.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            </> )}

                            {visibleGroups >= 5 && (
                            <>
                            <Accordion.Item
                                eventKey="20"
                                className={activeKey === "20" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>21.</span>What is a MultiMiner and how is it different from regular Miners?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">A MultiMiner generates tokens from multiple premium projects simultaneously, rather than just one project. The first MultiMiner includes four projects (Incentiv, Olympus AI, Prodex, and Rain) with different allocation percentages for each project. Token generation for each project starts from its respective TGE date.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="21"
                                className={activeKey === "21" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>22.</span>Do I need to maintain my Node/Miner in any way?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">No active maintenance is required. Once your Node is purchased and your Miners are connected to it, the system operates automatically. You only need to claim your tokens every 28 days (or wait longer and claim accumulated tokens at once).</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="22"
                                className={activeKey === "22" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>23.</span>What happens if I disconnect a Miner from its Node?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">If a Miner is disconnected from a Node, it will stop generating tokens until it is connected to a Node again. You must have an active Node connection for your Miners to generate tokens.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                eventKey="23"
                                className={activeKey === "23" ? "active" : ""}
                            >
                                <Accordion.Header>
                                    <p>
                                        <span>24.</span>Are there any additional fees for claiming tokens?
                                    </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="para">Token claims require standard network gas fees for the blockchain transaction. There are no additional fees charged by Gems for claiming tokens from either Nodes or Miners.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            </>)}
                        </Accordion>



                        {visibleGroups * 5 < 22 ? (
                            <button className="btn-green btn-loadmore" onClick={handleLoadMore}>Load More</button>
                        ) : (
                            <button className="btn-green btn-loadmore" onClick={handleShowLess}>Show Less</button>
                        )}



                    </div>
                </div>
            </section>
        </>
    );
};

export default Faqs;
