import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../landing/header/Navbar";
import moment from "moment";

const BlogBanner = ({ blogsDataSingle }) => {
  const { t } = useTranslation();
  // console.log("blogs", blogsDataSingle)
  function removePTags(htmlString) {
    return htmlString.replace(/<\/?p>/gi, "");
  }
  return (
    <>
      <Navbar />
      {blogsDataSingle?.length > 0 ? (
        blogsDataSingle?.map((item, index) => {
          const cleanText = removePTags(item?.title?.rendered);

          return (
            <Link
              to={{
                pathname: `/blog/${item?.slug}`,
                state: {
                  id: item?.id,
                },
              }}
            >
              <section className="blog-banner">
                <img
                  src="\assets\blog\blog-bg.png"
                  alt="img"
                  className="img-fluid banner-bg"
                />
                <img
                  src="\assets\blog\blog-bg-mbl.png"
                  alt="img"
                  className="img-fluid banner-bg d-none banner-bg-mbl"
                />
                <div className="custom-container">
                  <div className="latest-article">
                    <div className="article-content">
                      <p>[{moment(item?.date).format("MMMM DD, YYYY")}]</p>
                      <h6>{cleanText}</h6>
                    </div>
                    <div className="main-artcile-img">
                      <img src={item?.uagb_featured_image_src?.medium[0]} alt="img" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </section>
            </Link>
          );
        })
      )
        :
        (
          ""

        )}
    </>
  );
};

export default BlogBanner;