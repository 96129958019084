import React from 'react'
import "./join.scss"
import { Link } from 'react-router-dom'

const Join = () => {
    return (
        <>
            <section className="miners-join">
                <img src="\assets\gemsnodeslanding\join\join-bg.png" alt="img" className='img-fluid join-bg' />
                <div className="custom-container">
                    <div className="main-content">
                        <h4>Join the future of <br />
                            <span>community-driven growth</span></h4>
                        <p>Build, earn, and thrive with innovative tools designed to maximize rewards for you and your community.</p>
                        <div className="twice-btns">
                            <a href="http://3.90.108.87:40981/" target="_blank" className="btn-green">Buy Gems Nodes</a>
                            <a href="http://3.90.108.87:46231/" target="_blank" className="btn-transparent">Buy Miners</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Join
