import React, { useEffect, useState } from 'react'
import "./news.scss"
import Slider from "react-slick";
import axios from 'axios';
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const News = () => {
    const { t } = useTranslation()
    const [blogsData, setBlogsData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        getBlogs();
    }, []);

    const getBlogs = () => {
        var config = {
            method: "get",
            url: `https://blog.gems.vip/wp-json/wp/v2/posts?per_page=100`,
        };

        axios(config)
            .then(function (res) {
                // console.log("blog data: ", res);
                setBlogsData(res?.data);
            })
            .catch(function (error) { });
    };

    function removePTags(htmlString) {
        return htmlString.replace(/<\/?p>/gi, "");
    }

    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = 1.2;
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentSlide(next),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4.2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 3.1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.25,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className="news-section">
                <div className="custom-container">
                    <div className="main-heading">
                        <h4>{t('News1')} <span>{t('News2')}</span></h4>
                        <Link to="/blog">{t('SeeAll')} <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                            <path d="M5.11377 7.99927L6.76292 7.99927L8.99992 4.00313L7.35077 4.00313L5.11377 7.99927Z" fill="#E1EFF5" />
                            <path d="M5.11377 0.000732422L6.76292 0.000732999L8.99992 3.99687L7.35077 3.99687L5.11377 0.000732422Z" fill="#E1EFF5" />
                            <path d="M0.465332 4.69751L1.28991 3.2693L5.86916 3.33007L5.04458 4.75828L0.465332 4.69751Z" fill="#E1EFF5" />
                        </svg></Link>
                    </div>
                    <div className="bottom-cards">
                        {/* <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\landing\news\img1.png" alt="img" className='img-fluid' />
                            </div>
                            <p className="blog-title">[ NEWS ]</p>
                            <h6>Vel sunt quis eos explicabo quidem est neque facere voluptas nobis et veritatis voluptatem.</h6>
                            <div className="twice-text">
                                <p>Oct 01, 2024</p>
                                <a href="#">Read Now</a>
                            </div>
                        </div> */}
                        {blogsData?.length > 0 ? (
                            blogsData.slice(0, 4).map((item, index) => {
                                const cleanText = removePTags(item?.title?.rendered);

                                return (
                                    <div key={index} className="single-card">
                                        <div className="main-img">
                                            <img src={item?.uagb_featured_image_src?.medium[0]} alt="img" className='img-fluid' />
                                        </div>
                                        <p className="blog-title">[ BLOG ]</p>
                                        <h6 className='descriptionblog'>{cleanText}</h6>
                                        <div className="twice-text">
                                            <p>[{moment(item?.date).format("MMMM DD, YYYY")}]</p>
                                            <Link to={{
                                                pathname: `/blog/${item?.slug}`,
                                                state: {
                                                    id: item?.id,
                                                },
                                            }}
                                            >{t('Btn-readnow')}</Link>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <></>
                        )}

                        {/* <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\landing\news\img3.png" alt="img" className='img-fluid' />
                            </div>
                            <p className="blog-title">[ ANNOUNCEMENT ]</p>
                            <h6>Vel sunt quis eos explicabo quidem est neque facere voluptas nobis et veritatis voluptatem.</h6>
                            <div className="twice-text">
                                <p>Oct 01, 2024</p>
                                <a href="#">Read Now</a>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\landing\news\img4.png" alt="img" className='img-fluid' />
                            </div>
                            <p className="blog-title">[ UPDATE ]</p>
                            <h6>Vel sunt quis eos explicabo quidem est neque facere voluptas nobis et veritatis voluptatem.</h6>
                            <div className="twice-text">
                                <p>Oct 01, 2024</p>
                                <a href="#">Read Now</a>
                            </div>
                        </div> */}
                    </div>
                    <div className="bottom-cards-mobile d-none">
                        <div className="slider">
                            <Slider {...settings}>
                                {blogsData?.length > 0 ? (
                                    blogsData.slice(0, 4).map((item, index) => {
                                        const cleanText = removePTags(item?.title?.rendered);

                                        return (
                                            <div className="single-card">
                                                <div className="main-img">
                                                    <img src={item?.uagb_featured_image_src?.medium[0]} alt="img" className='img-fluid' />
                                                </div>
                                                <p className="blog-title">[ BLOG ]</p>
                                                <h6 className='descriptionblog'>{cleanText}</h6>
                                                <div className="twice-text">
                                                    <p>[{moment(item?.date).format("MMMM DD, YYYY")}]</p>
                                                    <Link to={{
                                                        pathname: `/blog/${item?.slug}`,
                                                        state: {
                                                            id: item?.id,
                                                        },
                                                    }}>{t('Btn-readnow')}</Link>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <></>
                                )}
                                {/* <div className="single-card">
                                    <div className="main-img">
                                        <img src="\assets\landing\news\img2.png" alt="img" className='img-fluid' />
                                    </div>
                                    <p className="blog-title">[ BLOG ]</p>
                                    <h6>Vel sunt quis eos explicabo quidem est neque facere voluptas nobis et veritatis voluptatem.</h6>
                                    <div className="twice-text">
                                        <p>Oct 01, 2024</p>
                                        <a href="#">Read Now</a>
                                    </div>
                                </div>
                                <div className="single-card">
                                    <div className="main-img">
                                        <img src="\assets\landing\news\img3.png" alt="img" className='img-fluid' />
                                    </div>
                                    <p className="blog-title">[ ANNOUNCEMENT ]</p>
                                    <h6>Vel sunt quis eos explicabo quidem est neque facere voluptas nobis et veritatis voluptatem.</h6>
                                    <div className="twice-text">
                                        <p>Oct 01, 2024</p>
                                        <a href="#">Read Now</a>
                                    </div>
                                </div>
                                <div className="single-card">
                                    <div className="main-img">
                                        <img src="\assets\landing\news\img4.png" alt="img" className='img-fluid' />
                                    </div>
                                    <p className="blog-title">[ UPDATE ]</p>
                                    <h6>Vel sunt quis eos explicabo quidem est neque facere voluptas nobis et veritatis voluptatem.</h6>
                                    <div className="twice-text">
                                        <p>Oct 01, 2024</p>
                                        <a href="#">Read Now</a>
                                    </div>
                                </div> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default News
