import React, { useEffect } from 'react'
import BannerTerms from './BannerTerms'
import "./terms.scss"

const TermsForCommunnity = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="wrapper-apply">
            <BannerTerms />
            <section className="terms-section">
                <div className="custom-container">
                    <div className="insuranceinner">
                        <p className="midpara">
                            Please carefully read these terms and conditions before receiving any tokens from the issuer or any other person and accepting them as they affect your obligations and legal rights, including, but not limited to, waivers of rights and limitations of liability. If you do not agree with these terms and conditions or if you are a prohibited person then you are not permitted to receive the tokens.
                        </p>
                        <p className="midpara"> By receiving tokens, you acknowledge that you have fully read, understand and irrevocably accept and agree to be bound by these terms and conditions. You must also monitor the website for any announcements from the issuer as they may add to, or change, these terms and conditions from time to time.</p>
                        <p className="midpara">The tokens do not represent or confer any ownership right or stake, share, or equivalent rights, or any right to receive intellectual property rights in or relating to the Issuer. The tokens are not intended to be or to represent a stock, a loan contract, a commodity, a currency, a share, an instrument creating or acknowledging indebtedness, an instrument giving entitlements to securities, a certificate representing certain securities, an option, a future or a contract for difference or in any permitted jurisdictions.
                        </p>
                        <h6 className="insurancehead">
                            1. Definitions
                        </h6>
                        <p className="midpara"><span className='midparabold'>“Affiliates”</span> means with respect to any specified person, any director, officer, partner, member, agent, advisor or employee of such person and any other person that, directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with, such specified person, and for purposes of this definition “control” (including, with correlative meanings, the terms, “controlled by” and “under common control with”), as used with respect to any person, means the possession, directly or indirectly, of the power to direct or cause the direction of the management or policies of such person, whether through the ownership of voting securities, by contract or otherwise.                        </p>
                        <p className="midpara"><span className='midparabold'>“Airdrop”</span> means the distribution of the token to eligible users via a “claim” system.
                        </p>
                        <p className="midpara"><span className='midparabold'>“Applicable law”</span> means the applicable laws, acts, statutes, ordinances, rules, regulations, judgments, injunctions, orders, treaties, sanctions, administrative acts and decrees of any relevant jurisdiction.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Blockchain”</span> means a type of distributed ledger, comprised of immutable,
                            digitally recorded, data in packages called blocks.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Eligible User”</span> means members of the <span className="midparabold">“Gems”</span>
                            and/or <span className="midparabold">“Gems Meme Coin”</span> community who are determined to be eligible for
                            an allocation of the tokens based on criteria determined by the issuer in its sole discretion, but at all times
                            shall exclude any prohibited person.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Governmental Authority”</span> means any nation or government, any state or
                            other political subdivision thereof, any entity exercising legislative, judicial or administrative functions
                            of or pertaining to government, including, without limitation, any government authority, agency, department,
                            board, commission or instrumentality, and any court, tribunal or arbitrator(s) of competent jurisdiction,
                            and any self-regulatory organisation.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Information Materials”</span> means documents or other materials issued by any
                            member of the Issuer in connection with the tokens from time to time.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Issuer”</span> means Gems Labs limited, a British Virgin Islands business
                            company with limited liability, which is the entity initiating the airdrop (claim) and making the tokens
                            available subject to these T&amp;Cs.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Parties”</span> means the issuer and you.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Person”</span> means an individual or legal entity or person, including,
                            without limitation, a governmental authority or an agency or instrumentality thereof.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Proceedings”</span> means any disputes, claims, suits, actions, causes of action,
                            demands, procedures or proceedings.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Prohibited Actions”</span> has the meaning specified in clause 10.3.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“T&amp;Cs”</span> means these terms and conditions, including without limitation,
                            all information materials, and any other rules, policies or procedures that may be issued by any member of
                            the Issuer and published from time to time on the website, each as amended from time to time and at any time
                            in accordance with the provisions herein.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Token”</span> means the issuer’s cryptographic tokens known as
                            <span className="midparabold">$Gems Meme Coin</span>, as described further in the information materials.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“You”, “Your” or “Yourself”</span> means any person who from time to time and
                            at any time (i) proposes to acquire tokens from the issuer or any third party; or (ii) holds tokens.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">“Website”</span> means any website URL used to allow eligible users to receive
                            the tokens allocated to them under the claimable airdrop (as may be updated from time to time).
                        </p>
                        <p className="midpara">
                            As used herein, any reference to the <span className="midparabold">“use”</span> of the tokens includes,
                            without limitation, any holding, transferring, exchanging and/or selling of the tokens or the grant of
                            any security over the tokens and a reference to <span className="midparabold">“using”</span> the tokens
                            shall be construed accordingly.
                        </p>
                        <h6 className="insurancehead">1. Terms and Conditions, Status and Acceptance</h6>
                        <p className="midpara">
                            <span className="midparabold"></span> These T&amp;Cs constitute a
                            legally binding obligation on you effective upon the earlier to occur of the date and time: (i) you click
                            the check box on the website to indicate that you have read, understand and agree to these T&amp;Cs; or
                            (ii) you receiving any tokens allocated to you as part of the airdrop (claim). These T&amp;Cs define the
                            rights and obligations of the parties in relation to the tokens and your receipt of the tokens.
                        </p>
                        <h6 className="insurancehead">2. Limitations</h6>
                        <p className="midpara">
                            Tokens are only intended for those persons who are knowledgeable and experienced in cryptocurrencies, blockchain and related technologies and protocols. By receiving the tokens, you acknowledge that transactions using cryptocurrencies (including cryptographic tokens) are inherently unstable and you agree to accept that risk, and agree that the Issuer is not liable for any loss that you may suffer or incur, and further acknowledge, accept and assume all risks associated with the tokens including, without limitation, those identified in clause 6 of these T&Cs and in the information materials. Tokens are intended to be marketed, allocated and received by only to persons that are not prohibited persons. The airdrop (claim) is expressly being made in the permitted jurisdictions on the basis that the tokens do not require that a prospectus be prepared or that other disclosure or registration requirements be met or where other investor safeguards or regulatory documents or licensing is required in connection with the making available of the tokens by the user in the permitted jurisdictions.
                        </p>

                        <h6 className="insurancehead">3. General</h6>
                        <p className="midpara">
                            You shall not acquire or seek to receive any tokens if you are a prohibited person or are otherwise not an eligible user. The issuer may change, modify, amend, alter or supplement these T&Cs (each an “amendment”) at any time in order to reflect (i) changes to applicable law that may be, or which may otherwise become, applicable to the tokens, (ii) any developments that may otherwise reasonably be capable of materially adversely impacting the tokens or the airdrop (claim) by the issuer; or (iii) as the issuer may in good faith deem advisable to protect the reputation of the issuer or the Issuer.
                        </p>

                        <h6 className="insurancehead">4. Risks of Tokens and Limitations of Liabilities</h6>
                        <p className="midpara">
                            You understand and acknowledge that tokens, blockchain-based technologies and other associated and related technologies are not exclusively (or, as appropriate, at all) controlled by the Issuer and adverse changes in market forces or the technology, broadly construed, may prevent or compromise the issuer’s performance under these T&Cs. As such, the purchase of tokens carries with it a number of risks. Prior to receiving tokens, you should carefully consider the risks listed herein and in the information materials and, to the extent necessary, consult an appropriate lawyer, accountant, or tax professional. If any of the risks associated with receiving and holding tokens is unacceptable to you, you should not receive tokens. you acknowledge, agree and assume all risks associated with these T&Cs and the tokens including, without limitation, those risks disclosed in the information materials. There may be additional risks that cannot be anticipated or foreseen due to the incipience of cryptographic token technology, blockchain-based technology and related technologies.
                        </p>

                        <h6 className="insurancehead">5. Disclaimer of Warranties</h6>
                        <p className="midpara">
                            The tokens are provided to the fullest extent legally permissible to you “as is” and on an “as available” basis with no representation or warranty of any kind either, statutory, expressed or implied, including, but not limited to, the implied representations or warranties of title, non-infringement, merchantability, and fitness for a particular purpose. You assume all responsibility and risk with respect to your acquisition of the tokens. The Issuer or any of their respective affiliates or advisers make any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of smart contracts associated with the tokens, including that your access to such smart contracts will be reliable, error-free, or uninterrupted, that defects will be corrected. You understand and expressly agree that none, of the Issuer or any of their respective affiliates or advisers represents, warrants or guarantees in any way that tokens might be sold or transferred, or be saleable or transferable, or there is an ability or will be a platform to exchange tokens for fiat currencies, cryptocurrencies or cryptographic tokens, during or after the airdrop (claim). The Issuer further does not make any representations or warranties with respect to the regulatory oversight or the use or security of any such exchange.
                        </p>
                        <h6 className="insurancehead">6. Indemnity</h6>
                        <p className="midpara">
                            You do hereby to the fullest extent permitted by applicable law indemnify, defend and hold the issuer and each other relevant party harmless from and against any and all loss, penalty, claim, damage, liability or expense whatsoever (including reasonable attorneys’ fees and disbursements) due to or arising out of or based upon (i) any inaccurate representation or warranty made by you, or breach or failure by you to comply with any covenant or agreement made by you in these T&Cs or in any other document furnished by you to any of the foregoing in connection with this transaction, or (ii) any action instituted by or on behalf of you against the issuer or any other relevant party that is finally resolved by judgment against you or in favour of the issuer or any other relevant party.
                        </p>

                        <h6 className="insurancehead">7. Miscellaneous</h6>
                        <p className="midpara">
                            <span className="midparabold">Third Party Rights.</span> You hereby acknowledge and agree that each relevant party is an intended third-party beneficiary under these T&Cs (and the issuer shall hold the benefit of such provisions on trust for each such relevant party). However, the parties to these T&Cs may rescind or vary these T&Cs (including, without limitation, any variation so as to extinguish or alter a third party's entitlement to enforce any provisions of these T&Cs) without the consent of any such third party.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">Security.</span> You must at all times remain the only person who has control over your private key, digital wallet and any other device associated with the purchase of tokens and any username, passwords or other login or identifying credentials used by you with respect to the tokens. You must implement reasonable and appropriate measures designed to secure access to any private key, digital wallet or any other device associated with the receipt of tokens. If you transfer any such private key, digital wallet or any other device associated with the purchase of tokens to any third party, you do so at your own risk and the Issuer shall not be held responsible for any loss you may suffer as a result of third parties accessing your private key, digital wallet or any other device associated with the receipt of tokens.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">Suspension.</span> Notwithstanding anything contained herein, the Issuer reserves the right, without notice and in its sole and absolute discretion, to suspend your ability to receive the tokens, and without liability to you, at its sole and absolute discretion, including but not limited to, in case of your breach of these T&Cs or if the Issuer believes you have committed fraud or other misconduct or are a prohibited person.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">Entire Agreement.</span> Unless otherwise provided, these T&Cs are intended to fully reflect the terms of the agreement between the parties, and shall supersede any previously or contemporaneously agreed upon terms or understanding. No provision of these T&Cs shall be considered waived unless such waiver is in writing and signed by the party that benefits from the enforcement of such provision. No waiver of any provision in these T&Cs, however, will be deemed a waiver of a subsequent breach of such provision or a waiver of a similar provision. In addition, a waiver of any breach or a failure to enforce any term or condition of these T&Cs will not in any way affect, limit, or waive a party’s rights hereunder at any time to enforce strict compliance thereafter with every term and condition hereof.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">Assignment.</span> The issuer may, at its sole and absolute discretion, assign any of its rights and/or delegate its duties under these T&Cs. You may not assign your rights or delegate right to receive any tokens, and any assignment or delegation in breach of the foregoing shall be null and void.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">Severability.</span> In the event any one or more of the provisions of these T&Cs are for any reason held to be invalid, illegal or unenforceable in any jurisdiction, in whole or in part or in any respect, or in the event that any one or more of the provisions of these T&Cs operate or would prospectively operate to invalidate these T&Cs in any jurisdiction, then and in any such event, such provision(s) shall be deemed modified to the minimum extent necessary so that such provision, as so modified, shall no longer be held to be invalid, illegal or unenforceable. Any such modification, invalidity or unenforceability shall be strictly limited both to such provision and to such jurisdiction, and in each case to no other. Furthermore, in the event of any such modification, invalidity or unenforceability, these T&Cs shall be interpreted so as to achieve the intent expressed herein to the greatest extent possible in the jurisdiction in question and otherwise as set forth herein.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">Electronic Notices and use of Information.</span> You agree and consent to receive electronically all communications, agreements, documents, receipts, notices and disclosures (hereinafter - the “Communications”). The Issuer and their respective service providers may further disclose your information to any of their respective service providers, agents, relevant custodians or similar third parties for any reason and such persons may keep your information for any period of time permitted by applicable law.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">Tax Issues.</span> The Issuer makes no representations concerning the tax implications of the receipt of tokens or the possession or use of them. You bear the sole and absolute responsibility to determine if the potential appreciation or depreciation in the value of tokens over time has tax implications for you in your home jurisdiction or any other jurisdiction. By receiving tokens, and to the extent permitted by applicable law, you agree to be solely responsible for any applicable taxes imposed on, and agree not to hold the Issuer liable for any tax liability associated with or arising from your acquisition, possession, or transfer of tokens.
                        </p>
                        <p className="midpara">
                            <span className="midparabold">Limited Recourse:</span> Notwithstanding any other provision of these T&Cs, any obligations of the issuer hereunder are limited recourse obligations of the issuer. All obligations of and any claims against the issuer hereunder or in connection herewith after such realisation shall be extinguished and shall not thereafter revive. No recourse shall be had against any officer, director, partner, employee, shareholder or incorporator of the issuer or its respective successors or assigns for any amounts payable in respect of the tokens (except as otherwise provided herein).
                        </p>






                    </div>
                </div>
            </section>
        </div>
    )
}

export default TermsForCommunnity
