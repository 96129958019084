import React from 'react'
import "./nodes.scss"
import { Link } from 'react-router-dom'

const Nodes = () => {
    return (
        <>
            <div style={{ overflow: "hidden" }} className='overflow-hiddenmobile'>
                <section className="nodesminers">
                    <video className="main-banner-video"
                        muted="muted" playsinline="playsinline"
                        autoPlay
                        loop
                        width="100%"
                        id="myVideo">
                        <source src="\assets\gemsnodeslanding\gemsnodesvideo.mp4" type="video/mp4" />
                    </video>
                    <div className="custom-container">
                        <div className="main-content">
                            <h5><img src="\assets\gemsnodeslanding\icons\nodes.svg" alt="img" className='img-fluid' />Gems Nodes</h5>
                            <h4><span>The Backbone of the Gems Ecosystem</span></h4>
                            <p>Gems Nodes are integral components of the Gems network, designed to foster long-term community building while offering continuous rewards.</p>
                            <div className="twice-btns">
                                <a href="http://3.90.108.87:40981/" target="_blank" className="btn-green">Buy Gems Nodes</a>
                                <Link to="/nodes" className="btn-transparent">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bottom-cards-nodes">
                    <div className="custom-container">
                        <div className="parent">
                            <div className="single-card">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <g clip-path="url(#clip0_4374_10334)">
                                        <path d="M27.5479 4.20897L20.3638 0L14 3.72838L7.63616 0L0.452026 4.20897V12.606L6.81585 16.3343V23.7911L14 28L21.1841 23.791V16.3343L27.5479 12.606V4.20897ZM25.1049 4.67906L20.3638 7.4567L15.6228 4.67906L20.3639 1.90143L25.1049 4.67906ZM14 18.6418L9.2589 15.8642L14 13.0866L18.7411 15.8642L14 18.6418ZM8.45647 8.87759L13.1797 6.1104V11.6657L8.45647 14.4329V8.87759ZM19.5435 8.87759V14.4329L14.8203 11.6657V6.11045L19.5435 8.87759ZM12.3772 4.67912L7.63616 7.45675L2.89508 4.67906L7.63616 1.90143L12.3772 4.67912ZM2.09265 6.11045L6.81585 8.87764V14.4329L2.09265 11.6657V6.11045ZM8.45647 17.2955L13.1797 20.0627V25.6179L8.45647 22.8507V17.2955ZM14.8203 25.6179V20.0627L19.5435 17.2955V22.8507L14.8203 25.6179ZM21.1841 14.4329V8.87759L25.9073 6.1104V11.6657L21.1841 14.4329Z" fill="#30F1B6" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4374_10334">
                                            <rect width="28" height="28" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6>NFT Ownership</h6>
                                <p>Gems Nodes are minted as NFTs, but these NFTs are non-tradable and non-transferable.</p>
                            </div>
                            <div className="single-card">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <g clip-path="url(#clip0_4412_3514)">
                                        <path d="M25.4752 11.4797C25.4752 5.14981 20.3254 0 13.9953 0C7.66544 0 2.51562 5.14981 2.51562 11.4797V11.9054L11.4051 18.1603H9.07556V28H18.9153V18.1603H16.5857L25.4752 11.9054V11.4797ZM23.6897 9.79205C22.0017 8.96021 19.9932 8.99289 18.3295 9.88818C18.1774 6.79321 17.5807 3.83966 16.5616 1.97986C20.2162 2.96808 23.0341 6.0137 23.6897 9.79205ZM9.7662 11.9682L11.9219 16.5186L4.70462 11.4404C6.28586 10.4791 8.36847 10.6551 9.7662 11.9682ZM13.9953 1.63998C14.4339 1.63998 15.1401 2.43188 15.7299 4.20132C16.2448 5.74539 16.5744 7.70667 16.6861 9.84161C15.8645 9.41415 14.9474 9.18665 13.9953 9.18665C13.0434 9.18665 12.1263 9.41394 11.3045 9.84161C11.4162 7.70667 11.7461 5.74539 12.2607 4.20132C12.8505 2.43188 13.5569 1.63998 13.9953 1.63998ZM16.5428 11.6875L13.9953 17.0648L11.4478 11.6875C12.1772 11.1298 13.063 10.8268 13.9953 10.8268C14.9278 10.8268 15.8137 11.1298 16.5428 11.6875ZM18.2246 11.9682C19.6223 10.6551 21.7047 10.4791 23.2862 11.4404L16.0687 16.5186L18.2246 11.9682ZM11.4293 1.97986C10.4101 3.83966 9.81342 6.79321 9.6611 9.88818C7.99762 8.99289 5.98914 8.96042 4.30087 9.79205C4.9567 6.0137 7.77438 2.96808 11.4293 1.97986ZM17.2753 26.36H10.7153V19.8003H17.2753V26.36Z" fill="#30F1B6" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4412_3514">
                                            <rect width="28" height="28" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6>Regular Token Airdrops</h6>
                                <p>Earn tokens from projects funded on
                                    Gems Self Serve, projects launched on
                                    Rocks, and projects listed on Gems Trade.</p>
                            </div>
                            <div className="single-card">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M13.1949 22.8666C12.807 22.8692 12.4337 22.7182 12.1566 22.4466L5.55327 15.8433C5.28458 15.5668 5.13428 15.1964 5.13428 14.8108C5.13428 14.4252 5.28458 14.0549 5.55327 13.7783L16.3333 3.00998C16.5371 2.80604 16.7817 2.64758 17.0512 2.54503C17.3207 2.44247 17.6088 2.39815 17.8966 2.41498L24.3949 2.79998C24.607 2.81371 24.8069 2.90415 24.9571 3.05444C25.1074 3.20472 25.1979 3.40456 25.2116 3.61665L25.5966 10.115C25.6088 10.4016 25.5611 10.6876 25.4567 10.9547C25.3522 11.2219 25.1933 11.4643 24.9899 11.6666L14.2216 22.435C14.0875 22.5712 13.9277 22.6795 13.7515 22.7536C13.5753 22.8277 13.3861 22.8661 13.1949 22.8666ZM6.99993 14.805L13.1949 21L23.6949 10.5C23.7227 10.4704 23.7442 10.4355 23.7582 10.3974C23.7723 10.3593 23.7785 10.3188 23.7766 10.2783L23.4383 4.56165L17.7333 4.22331C17.6908 4.21978 17.6481 4.22523 17.6079 4.2393C17.5677 4.25337 17.5309 4.27575 17.4999 4.30498L6.99993 14.805Z" fill="#30F1B6" />
                                    <path d="M6.59167 14.8634C6.36019 14.8607 6.13868 14.7687 5.97334 14.6067L2.67167 11.305C2.50781 11.141 2.41577 10.9186 2.41577 10.6867C2.41577 10.4548 2.50781 10.2324 2.67167 10.0684L5.145 7.59504C5.97686 6.79784 7.0845 6.35278 8.23667 6.35278C9.38884 6.35278 10.4965 6.79784 11.3283 7.59504L12.1567 8.4117C12.3185 8.57919 12.4089 8.80298 12.4089 9.03587C12.4089 9.26876 12.3185 9.49254 12.1567 9.66004L7.21 14.6067C7.04335 14.7667 6.82267 14.8583 6.59167 14.8634ZM4.52667 10.6867L6.59167 12.7517L10.3017 9.0417L10.0917 8.8317C9.59228 8.35392 8.9278 8.08725 8.23667 8.08725C7.54554 8.08725 6.88106 8.35392 6.38167 8.8317L4.52667 10.6867Z" fill="#30F1B6" />
                                    <path d="M17.3133 25.585C17.0814 25.5848 16.859 25.4925 16.695 25.3283L13.3933 22.0266C13.2295 21.8626 13.1375 21.6402 13.1375 21.4083C13.1375 21.1764 13.2295 20.954 13.3933 20.79L18.34 15.8433C18.5075 15.6815 18.7313 15.5911 18.9642 15.5911C19.1971 15.5911 19.4209 15.6815 19.5883 15.8433L20.405 16.6717C20.8121 17.0771 21.1351 17.5589 21.3555 18.0894C21.5759 18.62 21.6893 19.1888 21.6893 19.7633C21.6893 20.3378 21.5759 20.9067 21.3555 21.4372C21.1351 21.9678 20.8121 22.4496 20.405 22.855L17.9317 25.3283C17.7677 25.4925 17.5453 25.5848 17.3133 25.585ZM15.2483 21.4083L17.3133 23.4733L19.1683 21.6183C19.6599 21.1261 19.936 20.4589 19.936 19.7633C19.936 19.0677 19.6599 18.4005 19.1683 17.9083L18.9583 17.6983L15.2483 21.4083Z" fill="#30F1B6" />
                                    <path d="M5.355 23.5199C5.23994 23.5211 5.12582 23.499 5.01955 23.4549C4.91328 23.4108 4.81704 23.3456 4.73666 23.2633C4.65411 23.1828 4.5885 23.0867 4.54371 22.9805C4.49891 22.8743 4.47583 22.7602 4.47583 22.6449C4.47583 22.5297 4.49891 22.4156 4.54371 22.3094C4.5885 22.2032 4.65411 22.107 4.73666 22.0266L7.21 19.5533C7.2901 19.4673 7.3867 19.3984 7.49403 19.3505C7.60137 19.3027 7.71723 19.277 7.83472 19.2749C7.95221 19.2728 8.06891 19.2945 8.17786 19.3385C8.28681 19.3825 8.38578 19.448 8.46887 19.5311C8.55196 19.6142 8.61746 19.7131 8.66147 19.8221C8.70548 19.931 8.72709 20.0477 8.72502 20.1652C8.72295 20.2827 8.69723 20.3986 8.64941 20.5059C8.60158 20.6132 8.53263 20.7098 8.44666 20.7899L5.97333 23.2633C5.89254 23.3451 5.79623 23.4099 5.69005 23.454C5.58387 23.4981 5.46996 23.5205 5.355 23.5199Z" fill="#30F1B6" />
                                    <path d="M3.69845 20.2183C3.58349 20.2188 3.46957 20.1964 3.36339 20.1523C3.25721 20.1082 3.1609 20.0434 3.08012 19.9616C2.91626 19.7975 2.82422 19.5751 2.82422 19.3433C2.82422 19.1114 2.91626 18.889 3.08012 18.7249L4.73678 17.0799C4.90265 16.9254 5.12204 16.8412 5.34873 16.8452C5.57541 16.8492 5.79169 16.9411 5.95201 17.1014C6.11232 17.2617 6.20415 17.478 6.20815 17.7047C6.21215 17.9313 6.12801 18.1507 5.97345 18.3166L4.31678 19.9616C4.23725 20.0451 4.14118 20.111 4.0347 20.1552C3.92823 20.1994 3.8137 20.2209 3.69845 20.2183Z" fill="#30F1B6" />
                                    <path d="M8.65675 25.1766C8.42477 25.1764 8.20236 25.0841 8.03842 24.9199C7.95587 24.8395 7.89026 24.7433 7.84546 24.6371C7.80067 24.5309 7.77759 24.4168 7.77759 24.3016C7.77759 24.1863 7.80067 24.0722 7.84546 23.966C7.89026 23.8598 7.95587 23.7637 8.03842 23.6833L9.68342 22.0266C9.84929 21.872 10.0687 21.7879 10.2954 21.7919C10.522 21.7959 10.7383 21.8877 10.8986 22.048C11.059 22.2083 11.1508 22.4246 11.1548 22.6513C11.1588 22.878 11.0746 23.0974 10.9201 23.2633L9.33342 24.9199C9.24378 25.0061 9.13762 25.0733 9.02133 25.1174C8.90504 25.1616 8.78103 25.1817 8.65675 25.1766Z" fill="#30F1B6" />
                                    <path d="M16.0766 15.1667C15.3342 15.1691 14.6139 14.914 14.0386 14.4449C13.4632 13.9757 13.0683 13.3216 12.9213 12.5939C12.7742 11.8662 12.884 11.11 13.2321 10.4543C13.5801 9.79851 14.1448 9.28374 14.8299 8.9977C15.5149 8.71166 16.278 8.67206 16.989 8.88564C17.7 9.09923 18.315 9.55278 18.729 10.169C19.1431 10.7852 19.3306 11.5259 19.2597 12.2649C19.1887 13.0039 18.8637 13.6955 18.3399 14.2217C18.0443 14.5215 17.6918 14.7594 17.3033 14.9217C16.9147 15.0839 16.4977 15.1672 16.0766 15.1667ZM16.0766 10.5C15.8852 10.4986 15.6954 10.5361 15.5189 10.6103C15.3424 10.6845 15.1829 10.7938 15.0499 10.9317C14.8487 11.1344 14.7119 11.3922 14.657 11.6725C14.602 11.9528 14.6313 12.2432 14.7411 12.5069C14.8509 12.7706 15.0363 12.996 15.274 13.1545C15.5117 13.313 15.7909 13.3975 16.0766 13.3975C16.3623 13.3975 16.6416 13.313 16.8792 13.1545C17.1169 12.996 17.3023 12.7706 17.4121 12.5069C17.522 12.2432 17.5512 11.9528 17.4962 11.6725C17.4413 11.3922 17.3045 11.1344 17.1033 10.9317C16.9704 10.7938 16.8108 10.6845 16.6343 10.6103C16.4578 10.5361 16.2681 10.4986 16.0766 10.5Z" fill="#30F1B6" />
                                </svg>
                                <h6>Unlimited Potential</h6>
                                <p>No cap on the number of nodes; grow your stake as the ecosystem expands.</p>
                            </div>
                            <div className="single-card">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M14.0001 26.5417C12.3272 26.5417 10.6919 26.0456 9.30089 25.1162C7.90992 24.1868 6.8258 22.8658 6.1856 21.3202C5.54541 19.7746 5.37791 18.074 5.70428 16.4332C6.03064 14.7924 6.83622 13.2853 8.01914 12.1024C9.20206 10.9195 10.7092 10.1139 12.3499 9.78753C13.9907 9.46116 15.6914 9.62866 17.237 10.2689C18.7825 10.909 20.1035 11.9932 21.0329 13.3841C21.9623 14.7751 22.4584 16.4104 22.4584 18.0833C22.4553 20.3257 21.5632 22.4753 19.9776 24.0609C18.392 25.6464 16.2424 26.5386 14.0001 26.5417ZM14.0001 11.375C12.6733 11.375 11.3763 11.7684 10.2731 12.5056C9.16996 13.2427 8.31013 14.2904 7.80239 15.5162C7.29466 16.742 7.16181 18.0908 7.42065 19.3921C7.67949 20.6934 8.3184 21.8887 9.25658 22.8268C10.1948 23.765 11.3901 24.4039 12.6914 24.6628C13.9926 24.9216 15.3415 24.7888 16.5673 24.281C17.793 23.7733 18.8407 22.9135 19.5779 21.8103C20.315 20.7071 20.7084 19.4101 20.7084 18.0833C20.7053 16.3051 19.9976 14.6006 18.7402 13.3432C17.4828 12.0858 15.7783 11.3781 14.0001 11.375Z" fill="#30F1B6" />
                                    <path d="M13.4869 14.56L12.8335 16.17C12.7903 16.2703 12.7186 16.3558 12.6274 16.4159C12.5362 16.476 12.4294 16.5081 12.3202 16.5083H10.8502C10.7355 16.5097 10.624 16.5463 10.5307 16.6131C10.4375 16.6799 10.367 16.7737 10.3288 16.8819C10.2906 16.9901 10.2866 17.1074 10.3172 17.2179C10.3479 17.3285 10.4117 17.4269 10.5002 17.5L11.5618 18.5617C11.6308 18.6307 11.681 18.7163 11.7076 18.8102C11.7341 18.9042 11.7362 19.0034 11.7135 19.0983L11.2118 21.1167C11.1876 21.2237 11.1946 21.3354 11.2321 21.4385C11.2695 21.5417 11.3359 21.6318 11.4233 21.6983C11.5106 21.7647 11.6152 21.8046 11.7246 21.8131C11.834 21.8217 11.9436 21.7987 12.0402 21.7467L13.7668 20.755C13.853 20.709 13.9492 20.685 14.0468 20.685C14.1445 20.685 14.2407 20.709 14.3268 20.755L16.0535 21.7467C16.1501 21.7987 16.2597 21.8217 16.3691 21.8131C16.4785 21.8046 16.5831 21.7647 16.6704 21.6983C16.7578 21.6318 16.8242 21.5417 16.8616 21.4385C16.8991 21.3354 16.9061 21.2237 16.8819 21.1167L16.3335 19.075C16.3109 18.98 16.3129 18.8809 16.3395 18.7869C16.366 18.693 16.4162 18.6074 16.4852 18.5383L17.5002 17.5C17.5829 17.4224 17.6402 17.3215 17.6643 17.2106C17.6884 17.0998 17.6783 16.9842 17.6353 16.8792C17.5923 16.7742 17.5185 16.6848 17.4235 16.6227C17.3285 16.5607 17.2169 16.5289 17.1035 16.5317H15.7268C15.6176 16.5315 15.5108 16.4994 15.4196 16.4392C15.3284 16.3791 15.2568 16.2936 15.2135 16.1933L14.5135 14.5833C14.4723 14.4824 14.4025 14.3957 14.3126 14.3338C14.2228 14.272 14.1169 14.2377 14.0078 14.2353C13.8988 14.2328 13.7914 14.2622 13.6989 14.3199C13.6063 14.3776 13.5326 14.461 13.4869 14.56Z" fill="#30F1B6" />
                                    <path d="M17.8383 12.425C17.698 12.4272 17.5593 12.3942 17.435 12.329C17.3106 12.2638 17.2046 12.1685 17.1267 12.0517C17.0061 11.8672 16.9607 11.6436 16.9998 11.4266C17.0388 11.2097 17.1594 11.016 17.3367 10.885L20.125 8.92504V3.20837H7.875V8.87837L10.6633 10.8384C10.8275 10.9804 10.9331 11.1784 10.9595 11.3938C10.9858 11.6093 10.9312 11.8269 10.8061 12.0043C10.681 12.1818 10.4945 12.3064 10.2827 12.3539C10.0709 12.4015 9.84893 12.3686 9.66 12.2617L6.74333 10.22C6.55508 10.0886 6.40082 9.91424 6.2933 9.71137C6.18578 9.5085 6.12809 9.28295 6.125 9.05337V2.91671C6.125 2.52993 6.27865 2.159 6.55214 1.88551C6.82563 1.61202 7.19656 1.45837 7.58333 1.45837H20.4167C20.8034 1.45837 21.1744 1.61202 21.4479 1.88551C21.7214 2.159 21.875 2.52993 21.875 2.91671V9.03004C21.8719 9.25962 21.8142 9.48517 21.7067 9.68803C21.5992 9.8909 21.4449 10.0653 21.2567 10.1967L18.34 12.2384C18.199 12.356 18.022 12.4219 17.8383 12.425Z" fill="#30F1B6" />
                                    <path d="M16.3334 11.7367C16.1023 11.7337 15.8815 11.6405 15.718 11.4771C15.5546 11.3136 15.4614 11.0928 15.4584 10.8617V3.20837H12.5417V10.8617C12.5417 11.0938 12.4496 11.3163 12.2855 11.4804C12.1214 11.6445 11.8988 11.7367 11.6667 11.7367C11.4347 11.7367 11.2121 11.6445 11.048 11.4804C10.8839 11.3163 10.7917 11.0938 10.7917 10.8617V2.33337C10.7948 2.10225 10.8879 1.88144 11.0514 1.718C11.2148 1.55455 11.4356 1.4614 11.6667 1.45837H16.3334C16.5645 1.4614 16.7853 1.55455 16.9488 1.718C17.1122 1.88144 17.2054 2.10225 17.2084 2.33337V10.8617C17.2054 11.0928 17.1122 11.3136 16.9488 11.4771C16.7853 11.6405 16.5645 11.7337 16.3334 11.7367Z" fill="#30F1B6" />
                                </svg>
                                <h6>Perpetual Rewards</h6>
                                <p>As new projects launch, your node continues to generate rewards indefinitely.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Nodes
