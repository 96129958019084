import React, { useState } from "react";
import "./signupproject.scss";
import { Modal } from "react-bootstrap";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const countries = [
  { name: "Afghanistan" },
  { name: "Albania" },
  { name: "Algeria" },
  { name: "Andorra" },
  { name: "Angola" },
  { name: "Antigua and Barbuda" },
  { name: "Argentina" },
  { name: "Armenia" },
  { name: "Australia" },
  { name: "Austria" },
  { name: "Azerbaijan" },
  { name: "Bahamas" },
  { name: "Bahrain" },
  { name: "Bangladesh" },
  { name: "Barbados" },
  { name: "Belarus" },
  { name: "Belgium" },
  { name: "Belize" },
  { name: "Benin" },
  { name: "Bhutan" },
  { name: "Bolivia" },
  { name: "Bosnia and Herzegovina" },
  { name: "Botswana" },
  { name: "Brazil" },
  { name: "Brunei" },
  { name: "Bulgaria" },
  { name: "Burkina Faso" },
  { name: "Burundi" },
  { name: "Cabo Verde" },
  { name: "Cambodia" },
  { name: "Cameroon" },
  { name: "Canada" },
  { name: "Central African Republic" },
  { name: "Chad" },
  { name: "Chile" },
  { name: "China" },
  { name: "Colombia" },
  { name: "Comoros" },
  { name: "Congo (Congo-Brazzaville)" },
  { name: "Costa Rica" },
  { name: "Croatia" },
  { name: "Cuba" },
  { name: "Cyprus" },
  { name: "Czechia (Czech Republic)" },
  { name: "Denmark" },
  { name: "Djibouti" },
  { name: "Dominica" },
  { name: "Dominican Republic" },
  { name: "East Timor (Timor-Leste)" },
  { name: "Ecuador" },
  { name: "Egypt" },
  { name: "El Salvador" },
  { name: "Equatorial Guinea" },
  { name: "Eritrea" },
  { name: "Estonia" },
  { name: "Eswatini (fmr. 'Swaziland')" },
  { name: "Ethiopia" },
  { name: "Fiji" },
  { name: "Finland" },
  { name: "France" },
  { name: "Gabon" },
  { name: "Gambia" },
  { name: "Georgia" },
  { name: "Germany" },
  { name: "Ghana" },
  { name: "Greece" },
  { name: "Grenada" },
  { name: "Guatemala" },
  { name: "Guinea" },
  { name: "Guinea-Bissau" },
  { name: "Guyana" },
  { name: "Haiti" },
  { name: "Honduras" },
  { name: "Hungary" },
  { name: "Iceland" },
  { name: "India" },
  { name: "Indonesia" },
  { name: "Iran" },
  { name: "Iraq" },
  { name: "Ireland" },
  { name: "Israel" },
  { name: "Italy" },
  { name: "Ivory Coast" },
  { name: "Jamaica" },
  { name: "Japan" },
  { name: "Jordan" },
  { name: "Kazakhstan" },
  { name: "Kenya" },
  { name: "Kiribati" },
  { name: "Korea, North" },
  { name: "Korea, South" },
  { name: "Kosovo" },
  { name: "Kuwait" },
  { name: "Kyrgyzstan" },
  { name: "Laos" },
  { name: "Latvia" },
  { name: "Lebanon" },
  { name: "Lesotho" },
  { name: "Liberia" },
  { name: "Libya" },
  { name: "Liechtenstein" },
  { name: "Lithuania" },
  { name: "Luxembourg" },
  { name: "Madagascar" },
  { name: "Malawi" },
  { name: "Malaysia" },
  { name: "Maldives" },
  { name: "Mali" },
  { name: "Malta" },
  { name: "Marshall Islands" },
  { name: "Mauritania" },
  { name: "Mauritius" },
  { name: "Mexico" },
  { name: "Micronesia" },
  { name: "Moldova" },
  { name: "Monaco" },
  { name: "Mongolia" },
  { name: "Montenegro" },
  { name: "Morocco" },
  { name: "Mozambique" },
  { name: "Myanmar (Burma)" },
  { name: "Namibia" },
  { name: "Nauru" },
  { name: "Nepal" },
  { name: "Netherlands" },
  { name: "New Zealand" },
  { name: "Nicaragua" },
  { name: "Niger" },
  { name: "Nigeria" },
  { name: "North Macedonia (fmr. 'Macedonia')" },
  { name: "Norway" },
  { name: "Oman" },
  { name: "Pakistan" },
  { name: "Palau" },
  { name: "Panama" },
  { name: "Papua New Guinea" },
  { name: "Paraguay" },
  { name: "Peru" },
  { name: "Philippines" },
  { name: "Poland" },
  { name: "Portugal" },
  { name: "Qatar" },
  { name: "Romania" },
  { name: "Russia" },
  { name: "Rwanda" },
  { name: "Saint Kitts and Nevis" },
  { name: "Saint Lucia" },
  { name: "Saint Vincent and the Grenadines" },
  { name: "Samoa" },
  { name: "San Marino" },
  { name: "Sao Tome and Principe" },
  { name: "Saudi Arabia" },
  { name: "Senegal" },
  { name: "Serbia" },
  { name: "Seychelles" },
  { name: "Sierra Leone" },
  { name: "Singapore" },
  { name: "Slovakia" },
  { name: "Slovenia" },
  { name: "Solomon Islands" },
  { name: "Somalia" },
  { name: "South Africa" },
  { name: "South Sudan" },
  { name: "Spain" },
  { name: "Sri Lanka" },
  { name: "Sudan" },
  { name: "Suriname" },
  { name: "Sweden" },
  { name: "Switzerland" },
  { name: "Syria" },
  { name: "Taiwan" },
  { name: "Tajikistan" },
  { name: "Tanzania" },
  { name: "Thailand" },
];

const Signupleader = () => {
  const [show1, setShow1] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [nickName, setNickName] = useState("");
  const [nameError, setNameError] = useState("");
  const [twitter, setTwitter] = useState("");
  const [twitterError, setTwitterError] = useState("");
  const [telegram, setTelegram] = useState("");
  const [telegramError, setTelegramError] = useState("");
  const [email, setEmail] = useState("");
  const [emailerrorregister, setEmailErrorRegister] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("Country");
  const [countryError, setCountryError] = useState("");

  const handleItemClick = (countryName) => {
    setSelectedCountry(countryName);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // Function to validate social media links
  const isValidSocialLink = (link, type) => {
    let regex;
    if (type === "twitter") {
      regex =
        /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/i;
    } else if (type === "telegram") {
      regex = /^(?:https?:\/\/)?t\.me\/(?:\w)*$/i;
    }
    return regex.test(link);
  };

  const submit = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setEmailErrorRegister("Email is Required");
    } else if (!isValidEmail(email)) {
      setEmailErrorRegister("Email is invalid");
    }
    if (nickName.length === 0) {
      setNameError("Nickname is Required");
    }
    if (selectedCountry === "Country") {
      setCountryError("Select Country");
    }
    if (twitter.length === 0) {
      setTwitterError("Twitter Link is Required");
    } else if (!isValidSocialLink(twitter, "twitter")) {
      setTwitterError("Twitter Link is invalid");
    }
    if (telegram.length === 0) {
      setTelegramError("Telegram Link is Required");
    } else if (!isValidSocialLink(telegram, "telegram")) {
      setTelegramError("Telegram Link is invalid");
    } else {
      var data = JSON.stringify({
        nickName: nickName,
        twitterLink: twitter,
        telegramLink: telegram,
        country: selectedCountry,
        email: email,
      });
      setLoader(true);

      var config = {
        method: "post",
        url: `${Api_Url}/temp/leader-signup`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          handleShow1();
          setLoader(false);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          setLoader(false);
        });
    }
  };

  return (
    <>
      <section className="signupproject">
        <div className="custom-container">
          <div className="parent">
            <div className="main-heading">
              <h6>Sign up as Leader</h6>
              <p>
                Please fill the following form. We will reach out to you if your
                application is approved.
              </p>
            </div>
            <div className="bottom-content">
              <div className="option-field">
                <input
                  value={nickName}
                  onChange={(e) => (
                    setNickName(e.target.value), setNameError("")
                  )}
                  type="text"
                  placeholder="Nickname"
                />
                {nameError ? (
                  <p className="error-message">{nameError}</p>
                ) : null}
              </div>
              <div className="option-field">
                <input
                  value={twitter}
                  onChange={(e) => (
                    setTwitter(e.target.value), setTwitterError("")
                  )}
                  type="text"
                  placeholder="Twitter Link"
                />
                {twitterError ? (
                  <p className="error-message">{twitterError}</p>
                ) : null}
              </div>
              <div className="option-field">
                <input
                  value={telegram}
                  onChange={(e) => (
                    setTelegram(e.target.value), setTelegramError("")
                  )}
                  type="text"
                  placeholder="Telegram Link"
                />
                {telegramError ? (
                  <p className="error-message">{telegramError}</p>
                ) : null}
              </div>
              <div className="twice-fields">
                <div className="option-field" style={{ flex: "0 0 100%" }}>
                  <div className="dropdown">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedCountry}{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16 10L12 14L8 10"
                          stroke="#0B0B0B"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    {countryError ? (
                      <p className="error-message">{countryError}</p>
                    ) : null}
                    <ul className="dropdown-menu">
                      {countries.map((country, index) => (
                        <li key={index}>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => (
                              handleItemClick(country.name), setCountryError("")
                            )}
                          >
                            {country.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <div className="option-field">
                                    <div class="dropdown">
                                        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Language <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M16 10L12 14L8 10" stroke="#0B0B0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" href="#">Coming Soon...</a></li>
                                        </ul>
                                    </div>
                                </div> */}
              </div>
              <div className="option-field">
                <input
                  value={email}
                  onChange={(e) => (
                    setEmail(e.target.value), setEmailErrorRegister("")
                  )}
                  type="text"
                  placeholder="Email"
                />
                {emailerrorregister ? (
                  <p className="error-message">{emailerrorregister}</p>
                ) : null}
              </div>
            </div>
            <div onClick={submit} className="btn-sign">
              {loader ? (
                <a href="#" className="btn-continue">
                  <img
                    src="\assets\loadingp.png"
                    alt="img"
                    className="img-fluid loading"
                  />
                  Submitting
                </a>
              ) : (
                <a className="btn-continue">Submit</a>
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="walletconnectmodal addteammembermodal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Sign up as Leader</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <img src="\assets\tick.svg" alt="addedimg" className="addedimg" />
            <h6 className="addedhead">
              Your details have been successfully submitted.
            </h6>
            <button className="greybtn w-100" onClick={() => handleClose1()}>
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Signupleader;
