import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import Navbar from "../landing/header/Navbar";
import './faqs.scss'

const Faqsbanner = () => {
    const { t } = useTranslation();

    return (
        <>
            <Navbar />
            <section className="main-faqsbanner">
                <img src="/assets/education/leftshade.png" alt="leftshade" className="leftshade" />
                <img src="/assets/education/rightshade.png" alt="leftshade" className="rightshade" />
                <img src="/assets/education/leftshadembl.png" alt="leftshade" className="leftshadembl d-none" />
                <img src="/assets/education/rightshadembl.png" alt="leftshade" className="rightshadembl d-none" />
                <div className="custom-container">
                    <div className="main-content">
                        <div className="contentheads">
                            <p className="upper-text">
                                FAQs
                                {/* {t('Faq-heading')} */}
                            </p>
                            <h2 className="lower-text">
                                {/* {t('Education-learn')} */}
                                FREQUENTLY ASKED <br /><span>QUESTIONS</span>
                            </h2>
                            {/* <p className="faqspara">Have some questions you’d like answered? Here are the answers to some frequently asked:</p> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faqsbanner;
