import React from 'react'
import "./visionary.scss"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Visionary = () => {
  const { t } = useTranslation()
  return (
    <>
      <section className="visionary-section">
        <div className="custom-container">
            <div className="parent">
                <div className="left-side">
                    <div className="main-content">
                        <h6>{t('Vision1')} </h6>
                        <p>{t('Vision2')}</p>
                        <Link to="/signupforproject" className='btn-green'>{t('Vision3')}</Link>
                    </div>
                </div>
                <div className="right-side">
                    <h4>{t('Vision4')} <span>{t('Vision5')}</span></h4>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Visionary
