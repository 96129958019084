import React, { useEffect, useState } from "react";
import "./process.scss";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";


function SampleNextArrow(props) {
    const { className, onClick, hideArrow } = props;
    return !hideArrow ? (
        <div className={className} onClick={onClick}>
            <img
                src="\next-arrow.svg"
                alt="Next"
                className="img-fluid d-none show-in-light-theme"
            />
            <img
                src="\next-arrow-dark.svg"
                alt="Next"
                className="img-fluid hide-in-light-theme"
            />
        </div>
    ) : <div className={className} onClick={onClick} style={{ pointerEvents: "none", opacity: "0.5" }}>
        <img
            src="\next-arrow.svg"
            alt="Next"
            className="img-fluid d-none show-in-light-theme"
        />
        <img
            src="\next-arrow-dark.svg"
            alt="Next"
            className="img-fluid hide-in-light-theme"
        />
    </div>;
}

function SamplePrevArrow(props) {
    const { className, onClick, hideArrow } = props;
    return !hideArrow ? (
        <div className={className} onClick={onClick}>
            <img
                src="\prev-icon.svg"
                alt="Prev"
                className="img-fluid d-none show-in-light-theme"
            />
            <img
                src="\prev-icon-dark.svg"
                alt="Prev"
                className="img-fluid hide-in-light-theme"
            />
        </div>
    ) : <div className={className} onClick={onClick} style={{ pointerEvents: "none", opacity: "0.5" }}>
        <img
            src="\prev-icon.svg"
            alt="Prev"
            className="img-fluid d-none show-in-light-theme"
        />
        <img
            src="\prev-icon-dark.svg"
            alt="Prev"
            className="img-fluid hide-in-light-theme"
        />
    </div>;
}

const ProcessLaunchpad = () => {

    const { t } = useTranslation();

    const [timeshow1, setTimeshow1] = useState(false);
      const [day, setDay] = useState(0);
      const [hour, setHour] = useState(0);
      const [min, setMin] = useState(0);
      const [sec, setSec] = useState(0);
      const [day1, setDay1] = useState(0);
      const [hour1, setHour1] = useState(0);
      const [min1, setMin1] = useState(0);
      const [sec1, setSec1] = useState(0);
    
      useEffect(() => {
            const interval = setInterval(() => {
              const now = new Date().getTime();
              const time = 1738076400000;
              const diff = time - now;
              if (diff <= 0) {
                clearInterval(interval);
                setTimeshow1(true);
                return;
              }
              const days = Math.floor(diff / (1000 * 60 * 60 * 24));
              const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
              const secs = Math.floor((diff % (1000 * 60)) / 1000);
              setDay(days);
              setHour(hours);
              setMin(mins);
              setSec(secs);
            }, 1000);
            return () => clearInterval(interval);
          }, []);
          useEffect(() => {
            const interval = setInterval(() => {
              const now = new Date().getTime();
              const time =  1737990000000;
              const diff = time - now;
              if (diff <= 0) {
                clearInterval(interval);
                setTimeshow1(true);
                return;
              }
              const days = Math.floor(diff / (1000 * 60 * 60 * 24));
              const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
              const secs = Math.floor((diff % (1000 * 60)) / 1000);
              setDay1(days);
              setHour1(hours);
              setMin1(mins);
              setSec1(secs);
            }, 1000);
            return () => clearInterval(interval);
          }, []);

    const roundData = [
        { round: 6, price: 0.02712701334, start: 1736863500000, end: 1737468000000 },
        { round: 7, price: 0.02794082374, start: 1737468300000, end: 1738072800000 },
        { round: 8, price: 0.02877904845, start: 1738073100000, end: 1738677600000 },
        { round: 9, price: 0.0296424199, start: 1738677900000, end: 1739282400000 },
        { round: 10, price: 0.0305316925, start: 1739282700000, end: 1739887200000 },
        { round: 11, price: 0.03144764328, start: 1739887500000, end: 1740492000000 },
        { round: 12, price: 0.03239107257, start: 1740492300000, end: 1741096800000 },
    ];

    const [currentRoundIndex, setCurrentRoundIndex] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState({});
    const [timeshow, setTimeshow] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const currentRound = roundData[currentRoundIndex];
            const nextRound = roundData[currentRoundIndex + 1];

            if (!currentRound) {
                clearInterval(interval);
                return;
            }

            const diffStart = currentRound.start - now;
            const diffEnd = currentRound.end - now;

            if (diffStart > 0) {
                setIsWaiting(true);
                setTimeRemaining(calculateTime(diffStart));
            } else if (diffEnd > 0) {
                setIsWaiting(false);
                setTimeRemaining(calculateTime(diffEnd));
            } else {
                // Round has ended, move to next round
                if (nextRound) {
                    setCurrentRoundIndex((prevIndex) => prevIndex + 1);
                } else {
                    setTimeshow(true);
                    clearInterval(interval);
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [currentRoundIndex]);

    const calculateTime = (ms) => {
        const days = Math.floor(ms / (1000 * 60 * 60 * 24));
        const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const mins = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
        const secs = Math.floor((ms % (1000 * 60)) / 1000);
        return { days, hours, mins, secs };
    };

    const currentRound = roundData[currentRoundIndex];


    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = 4.3;

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3.3,
        slidesToScroll: 1,
        draggable: true,
        swipe: true,
        beforeChange: (current, next) => setCurrentSlide(next),
        nextArrow: (
            <SampleNextArrow hideArrow={currentSlide >= totalSlides - 1} />
        ),
        prevArrow: <SamplePrevArrow hideArrow={currentSlide === 0} />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4.2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 3.1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.25,
                    slidesToScroll: 1,
                    draggable: true,
                    swipe: true,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                    draggable: true,
                    swipe: true,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                    swipe: true,
                },
            },
        ],
    };

    return (
        <>
            <section className="process-projects">
                <div className="custom-container padd-right-sm">
                    <div className="bottom-cards">
                        {
                            currentSlide >= totalSlides - 1 ? <>
                                <img src="\cardshadow-light.png" alt="img" className="img-fluid cardshadow-lightleft d-none show-in-light-theme" />
                                <img src="\cardshadow-dark.png" alt="img" className="img-fluid cardshadow-lightleft hide-in-light-theme" />
                            </> : <>
                                <img src="\cardshadow-light.png" alt="img" className="img-fluid cardshadow-light d-none show-in-light-theme" />
                                <img src="\cardshadow-dark.png" alt="img" className="img-fluid cardshadow-light hide-in-light-theme" />
                            </>
                        }
                        <div className="slider">
                            <Slider {...settings}>
                                <div className="single-card">
                                    <Link to="/astranova">
                                        <div className="main-token-img">
                                            <img width={70} height={70} src="\assets\alldetailpages\astra\banner\token-logonew.png" alt="img" className="img-fluid" />
                                        </div>
                                        <div className="token-info">
                                            <h6>Astra Nova <span> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                            </svg>Verified Gems</span></h6>
                                            <p className="sub-title">$RVV</p>
                                            <p className="para">Astra Nova is an AGI-driven gaming ecosystem that evolves with player behavior...</p>
                                        </div>
                                        <div className="tokenprice-info">
                                            <div className="text">
                                                <p>Round</p>
                                                <h6>1</h6>
                                            </div>
                                            <div className="text">
                                                <p>Round Price</p>
                                                <h6>$0.002</h6>
                                            </div>
                                            <div className="text">
                                                <p>Total Raised</p>
                                                <h6>--</h6>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="round-info">
                                        <h6>Round Starts In</h6>
                                        <h6>{day1 ? day1 : 0}D : {hour1 ? hour1 : 0}H : {min1 ? min1 : 0}M : {sec1 ? sec1 : 0}S</h6>
                                    </div>
                                    {
                                        !timeshow1
                                            ?
                                            <Link
                                                to="/astranova"
                                                className="btn-buy">
                                                View Project
                                            </Link>
                                            :

                                            <a
                                                href="https://buyastranova.gems.vip"
                                                target="_blank"
                                                className="btn-green">
                                                Buy Now
                                            </a>

                                    }


                                </div>
                                <div className="single-card">
                                    <Link to="/nfa">
                                        <div className="main-token-img">
                                            <img width={70} height={70} src="\assets\alldetailpages\nfa\token-logo.png" alt="img" className="img-fluid" />
                                        </div>
                                        <div className="token-info">
                                            <h6>NFA <span> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                            </svg>Verified Gems</span></h6>
                                            <p className="sub-title">NFA</p>
                                            <p className="para">NFA is transforming how crypto investors discover opportunities. Powered by advanced AI...</p>
                                        </div>
                                        <div className="tokenprice-info">
                                            <div className="text">
                                                <p>Round</p>
                                                <h6>{currentRound ? currentRound.round : "--"}</h6>
                                            </div>
                                            <div className="text">
                                                <p>Round Price</p>
                                                <h6>${currentRound ? currentRound.price : "--"}</h6>
                                            </div>
                                            <div className="text">
                                                <p>Total Raised</p>
                                                <h6>--</h6>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="round-info">
                                        <h6>{!isWaiting ? "Round Ending In" : "Round Starts In"}</h6>
                                        {!isWaiting ?
                                            <h6>{timeRemaining.days || 0}D : {timeRemaining.hours || 0}H : {timeRemaining.mins || 0}M : {timeRemaining.secs || 0}S</h6>
                                            :
                                            <h6>{timeRemaining.days || 0}D : {timeRemaining.hours || 0}H : {timeRemaining.mins || 0}M : {timeRemaining.secs || 0}S</h6>
                                        }
                                    </div>
                                    {
                                        isWaiting
                                            ?
                                            <Link
                                                to="/nfa"
                                                className="btn-buy">
                                                View Project
                                            </Link>
                                            :

                                            <a
                                                href="https://nfa.gems.vip/"
                                                target="_blank"
                                                className="btn-green">
                                                Buy Now
                                            </a>
                                        // <Link
                                        //   to="/nfa"
                                        //   className="btn-buy">
                                        //   View Project
                                        // </Link>

                                    }
                                </div>
                                <div className="single-card">
                                    <Link to="/olympus">
                                        <div className="main-token-img">
                                            <img width={70} height={70} src="\assets\presaletokens\olympas.png" alt="img" className="img-fluid" />
                                        </div>
                                        <div className="token-info">
                                            <h6>Olympus AI <span> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                            </svg>Verified Gems</span></h6>
                                            <p className="sub-title">OAI</p>
                                            <p className="para">Olympus AI is an innovative platform that integrates multiple leading AI models to create a workforce of AI...</p>
                                        </div>
                                        <div className="tokenprice-info">
                                            <div className="text">
                                                <p>Round</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Round Price</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Total Raised</p>
                                                <h6>--</h6>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="round-info">
                                        <h6>Round Starts In</h6>
                                        {/* {timeshow ? */}
                                        {/* <h6>{t('Process-t12')}</h6> */}
                                        <h6>TBD</h6>
                                        {/* :
                      <h6>{day ? day : 0}D : {hour ? hour : 0}H : {min ? min : 0}M : {sec ? sec : 0}S</h6>
                    } */}
                                    </div>
                                    {/* {
                    !timeshow
                      ?
                      <a
                        href="https://olympusai.gems.vip"
                        target="_blank"
                        className="btn-buy">
                        Buy Now
                      </a>
                      : */}

                                    <Link
                                        to="/olympus"
                                        className="btn-buy">
                                        View Project
                                    </Link>

                                    {/* } */}
                                </div>
                                <div className="single-card">
                                    <Link to="/leia">
                                        <div className="main-token-img">
                                            <img width={70} height={70} src="\assets\presaletokens\leia.png" alt="img" className="img-fluid" />
                                        </div>

                                        <div className="token-info">
                                            <h6>LEIA <span> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                            </svg>Verified Gems</span></h6>
                                            <p className="sub-title">LEIA</p>
                                            <p className="para">Leia games is the first company offering casual mobile games with a real money skill game twist in the Web3 space.</p>
                                        </div>
                                        <div className="tokenprice-info">
                                            <div className="text">
                                                <p>Round</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Round Price</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Total Raised</p>
                                                <h6>$3,300,000+</h6>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="round-info">
                                        <h6>Round Ending In</h6>
                                        {/* {timeshow1 ? */}
                                        <h6>{t('Finished')}</h6>
                                        {/* :
                      <h6>{day1 ? day1 : 0}D : {hour1 ? hour1 : 0}H : {min1 ? min1 : 0}M : {sec1 ? sec1 : 0}S</h6>
                    } */}
                                    </div>
                                    {/* {
                    !timeshow1
                      ?
                      <a
                        href="https://leia.gems.vip"
                        target="_blank"
                        className="btn-buy">
                        Buy Now
                      </a>
                      : */}

                                    <Link
                                        to="/leia"
                                        className="btn-buy">
                                        View Project
                                    </Link>

                                    {/* } */}
                                </div>
                                <div className="single-card">
                                    <Link to="/incentiv">
                                        <div className="main-token-img">
                                            <img width={70} height={70} src="\assets\presaletokens\incentiv.png" alt="img" className="img-fluid" />
                                        </div>

                                        <div className="token-info">
                                            <h6>Incentiv <span> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                            </svg>Verified Gems</span></h6>
                                            <p className="sub-title">INC</p>
                                            <p className="para">Incentiv is a Layer 1 blockchain with a core mission to make blockchain technology easy, accessible, intuitive, and rewarding. </p>
                                        </div>
                                        <div className="tokenprice-info">
                                            <div className="text">
                                                <p>Round</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Round Price</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Total Raised</p>
                                                <h6>$25,000,000+</h6>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="round-info">
                                        <h6>Round Ending In</h6>
                                        <h6 className="ended">Finished</h6>
                                    </div>
                                    <Link
                                        to="/incentiv"
                                        className="btn-buy">
                                        View Project
                                    </Link>
                                </div>
                                <div className="single-card">
                                    <Link to="/gemsdetail">
                                        <div className="main-token-img">
                                            <img width={70} height={70} src="\assets\alldetailpages\gemsdetailpage\info\token-logo.png" alt="img" className="img-fluid" />
                                        </div>

                                        <div className="token-info">
                                            <h6>Gems <span> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                            </svg>Verified Gems</span></h6>
                                            <p className="sub-title">Gems</p>
                                            <p className="para">Gems is a revolutionary platform that is transforming the way innovative projects raise funds and achieve success.</p>
                                        </div>
                                        <div className="tokenprice-info">
                                            <div className="text">
                                                <p>Round</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Round Price</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Total Raised</p>
                                                <h6>$10,000,000+</h6>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="round-info">
                                        <h6>Round Ending In</h6>
                                        <h6 className="ended">Finished</h6>

                                    </div>
                                    <Link
                                        to="/gemsdetail"
                                        className="btn-buy">
                                        View Project
                                    </Link>
                                </div>
                                <div className="single-card">
                                    <Link to="/dopdetail">
                                        <div className="main-token-img">
                                            <img width={70} height={70} src="\assets\presaletokens\dop.png" alt="img" className="img-fluid" />
                                        </div>

                                        <div className="token-info">
                                            <h6>DOP <span> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                                            </svg>Verified Gems</span></h6>
                                            <p className="sub-title">DATA OWNERSHIP PROTOCOL</p>
                                            <p className="para">The Data Ownership Protocol (DOP) utilizes zero-knowledge proofs to allow flexible transparency on top of Ethereum L1.</p>
                                        </div>
                                        <div className="tokenprice-info">
                                            <div className="text">
                                                <p>Round</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Round Price</p>
                                                <h6>--</h6>
                                            </div>
                                            <div className="text">
                                                <p>Total Raised</p>
                                                <h6>$160,000,000+</h6>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="round-info">
                                        <h6>Round Ending In</h6>
                                        <h6 className="ended">Finished</h6>

                                    </div>
                                    <Link
                                        to="/dopdetail"
                                        className="btn-buy">
                                        View Project
                                    </Link>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProcessLaunchpad;