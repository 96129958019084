import React from 'react'
import "./how.scss"
import { useTranslation } from 'react-i18next'

const HowItWorks = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="self-serve howitworks-miners">
                <img src="\assets\landing\selfserve\selfserve-bg.png" alt="img" className='img-fluid selfserve-bg hide-in-light-theme' />
                <img src="\assets\landing\selfserve\selfserve-bg-light.png" alt="img" className='img-fluid selfserve-bg d-none show-in-light-theme' />
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <h4>How It Works
                            </h4>
                        </div>
                        <div className="right-side">
                            <div className="points-data">
                                <div className="single-value">
                                    <span>01</span>
                                    <div className="twice-text">
                                        <h6>Purchase a Gems Node:</h6>
                                        <p>Available in $GEMS tokens.</p>
                                    </div>
                                </div>
                                <div className="single-value">
                                    <span>02</span>
                                    <div className="twice-text">
                                        <h6>Connect a Project Miner</h6>
                                        <p>Purchased in USDT or ETH and linked to your node.</p>
                                    </div>
                                </div>
                                <div className="single-value">
                                    <span>03</span>
                                    <div className="twice-text">
                                        <h6>Start Earning</h6>
                                        <p>Receive tokens from both general & premium projects on the Gems Launchpad.</p>
                                    </div>
                                </div>
                                <div className="single-value">
                                    <span>04</span>
                                    <div className="twice-text">
                                        <h6>Claim and Trade</h6>
                                        <p>Earned tokens can be claimed and traded.
                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HowItWorks
