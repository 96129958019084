import React, { useEffect, useState } from 'react'
import Banner from './Banner/Banner'
import { Link } from 'react-router-dom'
import Navbar from '../../landing/header/Navbar'
import Loader from "../../../hooks/loader";

const GemsDetailPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [mainLoader1, setMainLoader1] = useState(false);

    return (
        <>
            {mainLoader1 && <Loader />}
            <div className="wrapper-detailpage">
                <Navbar />
                <Banner setMainLoader1={setMainLoader1}/>
            </div>


        </>
    )
}

export default GemsDetailPage
