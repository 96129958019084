
import React, { useEffect, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../header/Navbar";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import axios from "axios";

const Banner = () => {
  const { t } = useTranslation()

  const [Allprojectssss, setAllprojectssss] = useState([])

  // console.log("console", Allprojectssss?.leadersCount)
  // console.log("console", Allprojectssss?.totalAmountInUsd)

  const getAllProjectsData = async () => {
    setAllprojectssss([])
    var config = {
      method: "get",
      url: `http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:8007/users/homepage-stats`,
      headers: {
        // authorization: `Bearer ${tok}`
      },
    };
    axios(config)
      .then(function (response) {
        const data = response.data;
        setAllprojectssss(response?.data?.data)
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getAllProjectsData();
    // fetchProjects(currentPage);
  }, []);

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000)?.toFixed(1) + 'M+';
    } else if (num >= 1000) {
      return (num / 1000)?.toFixed(1) + 'k+';
    } else {
      return num?.toString();
    }
  };


  return (
    <>
      <Navbar />
      <section className="main-banner">
        <img src="\assets\landing\banner\banner-shadow.png" alt="img" className="img-fluid banner-shadow hide-in-light-theme" />
        <img src="\assets\landing\banner\banner-shadow-light.png" alt="img" className="img-fluid banner-shadow d-none show-in-light-theme" />
        <video className="main-banner-video hide-in-light-theme"
          muted="muted" playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo">
          <source src="\assets\videos\new-banner-video.mp4" type="video/mp4" />
        </video>
        <video className="main-banner-video d-none show-in-light-theme"
          muted="muted" playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo">
          <source src="\assets\videos\new-banner-video-light.mp4" type="video/mp4" />
        </video>
        <div className="custom-container">
          <div className="main-content">
            <h4>{t('NewBannert1')}<span>{t('NewBannert2')}</span></h4>
            <p>{t('NewBannert3')}</p>
            <div className="twice-btns">
              <Link to="/launchpad" className="btn-green">{t('NewBannert8')}</Link>

              <a href="https://creators.gems.vip/signup" target="_blank" className="btn-transparent">Create A Project</a>
            </div>
          </div>
        </div>
        <section className="user-data">
          <div className="custom-container">
            <div className="parent">
              <div className="text">
                <p>{t('NewBannert4')}</p>
                {/* <h5><span>{Allprojectssss?.totalInvestor ? formatNumber(Allprojectssss?.totalInvestor + 2669811) : 0}</span></h5> */}
                <h5><span>2.5M+</span></h5>
              </div>
              <div className="text">
                <p>{t('NewBannert5')}</p>
                {/* <h5><span>{Allprojectssss?.leadersCount ? formatNumber(Allprojectssss?.leadersCount) : 0}</span></h5> */}
                <h5><span>3.4K+</span></h5>
              </div>
              <div className="text">
                <p>{t('NewBannert6')}</p>
                {/* <h5><span>${Allprojectssss?.totalAmountInUsd ? formatNumber(Allprojectssss?.totalAmountInUsd) : 0}</span></h5> */}
                <h5><span>$200M+</span></h5>

              </div>
              <div className="text">
                <p>{t('NewBannert7')}</p>
                <h5><span>140+</span></h5>
              </div>
            </div>
          </div>
        </section>
      </section>

    </>
  );
};

export default Banner;
