import React from 'react'
import "./info.scss"
import { useTranslation } from 'react-i18next'

const Information = () => {
    const { t } = useTranslation()
    
    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{margin: "0"}}>
                    <h2>{t('Dop-detail4')} <span></span></h2>
                    <p>{t('Dop-detail5')}</p>
                    <p>{t('Dop-detail6')}</p>
                </div>
                <div className="img-wrapper">
                    <img src="\assets\alldetailpages\dopdetailpage\info\projectinfo1dop.svg" alt="img" className='img-fluid' />
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail7')} <span></span></h2>
                    
                </div>
                <div className="first-para">
                    <ul>
                        <li><span className='bold-text'>{t('Dop-detail8')}</span> {t('Dop-detail9')}</li>
                        <li><span className='bold-text'>{t('Dop-detail10')}</span> {t('Dop-detail11')}</li>
                        <li><span className='bold-text'>{t('Dop-detail12')}</span> {t('Dop-detail13')}</li>
                        <li><span className='bold-text'>{t('Dop-detail14')}</span> {t('Dop-detail15')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail16')} <span></span></h2>
                    <p>{t('Dop-detail17')}</p>
                    <p>{t('Dop-detail18')}</p>
                    <p>{t('Dop-detail19')}</p>
                    <p>{t('Dop-detail20')}</p>
                    <p>{t('Dop-detail21')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail22')} <span></span></h2>
                    <div className="team-gems">
                        <div className="single-team">
                            <img src="\assets\alldetailpages\dopdetailpage\info\team\waleed.png" alt="img" className='img-fluid' />
                            <h6>{t('Dop-detail23')}</h6>
                            <p>{t('Dop-detail24')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\dopdetailpage\info\team\kohji.png" alt="img" className='img-fluid' />
                            <h6>{t('Dop-detail25')}</h6>
                            <p>{t('Dop-detail26')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\dopdetailpage\info\team\matan.png" alt="img" className='img-fluid' />
                            <h6>{t('Dop-detail27')}</h6>
                            <p>{t('Dop-detail28')}</p>
                        </div>
                    </div>
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail29')} <span></span></h2>
                    <p>{t('Dop-detail30')}</p>
                    <div className="partners-content">
                        <img src="\assets\alldetailpages\dopdetailpage\info\partners\bitcoin.svg" alt="img" className='img-fluid' />
                        <img src="\assets\alldetailpages\dopdetailpage\info\partners\hacken.svg" alt="img" className='img-fluid' />
                        <img src="\assets\alldetailpages\dopdetailpage\info\partners\chain.svg" alt="img" className='img-fluid' />
                        <img src="\assets\alldetailpages\dopdetailpage\info\partners\zkme.svg" alt="img" className='img-fluid' />
                    </div>
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\alldetailpages\dopdetailpage\info\dop-tokenomics-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                            <img src="\assets\alldetailpages\dopdetailpage\info\dop-tokenomics.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Information
