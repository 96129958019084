import React, { useEffect } from 'react'
import Banner from './Banner/Banner'
import { Link } from 'react-router-dom'
import Navbar from '../../landing/header/Navbar'

const GemsDetailToken = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="wrapper-detailpage">
                <Navbar />
                <Banner />
            </div>


        </>
    )
}

export default GemsDetailToken
