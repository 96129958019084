import React, { useEffect, useState } from "react";
import Navbar from '../landing/header/Navbar';
import "./claim.scss"
import { Modal } from 'react-bootstrap';
import PurchaseModal from './DataTables/purchaseDetail';
import { useParams } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import ClaimToken from "../../hooks/chinaFunction/claimToken";
import { toast } from "react-toastify";
import Loader from "../../hooks/loader";
import Environment from "../../utils/Environment";

import axios from 'axios';
const Claim = () => {
    const { claimToken } = ClaimToken()
    const [mainLoader1, setMainLoader1] = useState(false);

    const [saleTokenAdress, setSaleTokenAdress] = useState(null)
    // console.log("values here", saleTokenAdress)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [isChecked, setIsChecked] = useState(false);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const { id } = useParams();
    const { account } = useWeb3React();
    const [projectdetail, setProjectDetail] = useState(null)
    const getAllProjectsData = async () => {
        setMainLoader1(true)
        var config = {
            method: "get",
            url: `${Environment?.Api_Url}/referral/buyer-all-purchases?by=${account.toLowerCase()}&limit=10&offset=1&privateSale=${id}`,
            headers: {
                // authorization: `Bearer ${tok}`
            },
        };

        axios(config)
            .then(function (response) {
                const data = response.data;
                setProjectDetail(response?.data?.data || []);
                setSaleTokenAdress(response?.data?.data?.projectInfo[0]?.privateSaleContract)
                setMainLoader1(false)
                // setTotalPages(data?.data?.pages || 0);
                // setTotalCount(data?.data?.count || 0);
                // setProjectDetail(response?.data?.data?.projects[0])
                // setProjectDetail(response?.data?.data)

                // console.log("+++++++++++++++++++", response?.data?.data?.buyerAllTransationsOfSpecificProject)

            })
            .catch(function (error) {
                setMainLoader1(false)

                console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        if (id && account)
            getAllProjectsData();
    }, [id, account])



    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const updateStatusClaim = async () => {
        setMainLoader1(true)
        var config = {
            method: "patch",
            url: `${Environment?.Api_Url}/referral/update-claim-status?by=${account.toLowerCase()}&privateSale=${id}`,
            headers: {
                // authorization: `Bearer ${tok}`
            },
        };
        axios(config)
            .then(function (response) {
                if (response) {
                    getAllProjectsData();
                    handleShow();
                }

            })
            .catch(function (error) {
                setMainLoader1(false)
                console.error("Error fetching data:", error);
            });
    };

    // /referral/update-claim-status?by=0x68a6649c2afd27477945a22af9e61b570fbe8ad1&privateSale=MORNING

    const ClaimYourDops = async () => {
        setMainLoader1(true)

        try {
            // console.log('click');
            let clam = await claimToken(saleTokenAdress)
            if (clam) {
                // setMainLoader(false)
                updateStatusClaim();
                setMainLoader1(false)
            }
            // setMainLoader1(false)

        } catch (err) {
            setMainLoader1(false)

            //   setMainLoader(false)
            // toast.error("claimed faild")
            console.log('claim erro', err);
        }
    };

    // const claimHandler = async () => {
    //     toast.success("successfull claimed")

    // }




    return (
        <>
            {mainLoader1 && <Loader />}

            <Navbar />
            <section className="claimallmain">
                <div className="custom-container">
                    <div className="main-heading">
                        <p>Claim</p>
                        <div className="twice-elem">
                            <img width={24} src={projectdetail?.projectInfo[0]?.projectImage} alt="img" className='img-fluid' />
                            <h5>{projectdetail?.projectInfo[0]?.projectName}</h5>
                        </div>
                    </div>
                    <div className="claimallinner">
                        <div className="claimallleft">
                            <div className="claimallleftinner">
                                <h6 className="claimalllefthead">Tokens</h6>
                                <div

                                    className="claimtoken"
                                >
                                    <div className="claimtokenleft">
                                        <div className="claimtokenimg" style={{ borderRadius: "50%", overflow: "hidden" }}>
                                            <img
                                                src={projectdetail?.projectInfo[0]?.projectImage}
                                                alt="claiminnerimg"
                                                className="claiminnerimg"
                                            />
                                        </div>
                                        <h6 className="tokenlefthead">{projectdetail?.projectInfo[0]?.privateSale} Tokens</h6>
                                        {/* <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            onClick={handleShow2}
                                        >
                                            <path
                                                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                                fill="#797979"
                                            />
                                        </svg> */}

                                    </div>
                                    <h6 className="claimtokenrighthead">
                                        {(
                                            projectdetail?.totalTokens
                                        )?.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}{" "}
                                        {projectdetail?.projectInfo[0]?.privateSale}
                                    </h6>
                                </div>
                                {/* <div
          onClick={() => {
            if (airdropAmount > 0) {
              handleShow3();
            }
          }}
          className="claimtoken"
        >
          <div className="claimtokenleft">
            <div className="claimtokenimg">
              <img
                src="\assets\cardimages\rewards.svg"
                alt="claiminnerimg"
                className="claiminnerimg"
              />
            </div>
            <h6 className="tokenlefthead">Testnet Rewards</h6>
            {airdropAmount > 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                  fill="#797979"
                />
              </svg>
            ) : (
              ""
            )}
          </div>
          {profile?.airdropAmount ? (
            <h6 className="claimtokenrighthead">
              {profile?.airdropAmount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              GEMS
            </h6>
          ) : (
            <h6 className="claimtokenrighthead">{0.0} GEMS</h6>
          )}
        </div> */}
                            </div>
                            {/* <div className="claimallleftinner">
                                <h6 className="claimalllefthead">NFTs</h6>
                                <div className="claimtoken" onClick={handleShow4}>
                                    <div className="claimtokenleft">
                                        <div className="claimtokenimg">
                                            <img
                                                src="\assets\cardimages\nftone.svg"
                                                alt="claiminnerimg"
                                                className="claiminnerimg"
                                            />
                                        </div>
                                        <h6 className="tokenlefthead">$200 GEMS NFT</h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                                fill="#797979"
                                            />
                                        </svg>
                                    </div>
                                    <h6 className="claimtokenrighthead">
                                        50,000.00 DOP <br />
                                        <span className="xspan">x2 NFTS</span>
                                    </h6>
                                </div>

                                <div
                                    className="claimtoken"

                                >
                                    <div className="claimtokenleft">
                                        <div className="claimtokenimg">
                                            <img
                                                src="\assets\claim\dummy.png"
                                                alt="claiminnerimg"
                                                className="claiminnerimg"
                                            />
                                        </div>
                                        <h6 className="tokenlefthead">$200 GEMS NFT</h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                                fill="#797979"
                                            />
                                        </svg>
                                    </div>
                                    <h6 className="claimtokenrighthead">
                                        0.00 GEMS <br />
                                        <span className="xspan">
                                            x1200 NFTS
                                        </span>
                                    </h6>
                                </div>


                                <div
                                    className="claimtoken"

                                >
                                    <div className="claimtokenleft">
                                        <div className="claimtokenimg">
                                            <img
                                                src="\assets\claim\dummy.png"
                                                alt="claiminnerimg"
                                                className="claiminnerimg"
                                            />
                                        </div>
                                        <h6 className="tokenlefthead">$500 GEMS NFT</h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                                fill="#797979"
                                            />
                                        </svg>
                                    </div>
                                    <h6 className="claimtokenrighthead">
                                        0.00 GEMS <br />
                                        <span className="xspan">
                                            x1500 NFTS
                                        </span>
                                    </h6>
                                </div>


                                <div
                                    className="claimtoken"

                                >
                                    <div className="claimtokenleft">
                                        <div className="claimtokenimg">
                                            <img
                                                src="\assets\claim\dummy.png"
                                                alt="claiminnerimg"
                                                className="claiminnerimg"
                                            />
                                        </div>
                                        <h6 className="tokenlefthead">$1,000 GEMS NFT</h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                                fill="#797979"
                                            />
                                        </svg>
                                    </div>
                                    <h6 className="claimtokenrighthead">
                                        0.00 GEMS <br />
                                        <span className="xspan">
                                            x10000 NFTS
                                        </span>
                                    </h6>
                                </div>


                                <div
                                    className="claimtoken"

                                >
                                    <div className="claimtokenleft">
                                        <div className="claimtokenimg">
                                            <img
                                                src="\assets\claim\dummy.png"
                                                alt="claiminnerimg"
                                                className="claiminnerimg"
                                            />
                                        </div>
                                        <h6 className="tokenlefthead">$5,000 GEMS NFT</h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                                fill="#797979"
                                            />
                                        </svg>
                                    </div>
                                    <h6 className="claimtokenrighthead">
                                        0.00 GEMS <br />
                                        <span className="xspan">
                                            x15000 NFTS
                                        </span>
                                    </h6>
                                </div>


                                <div
                                    className="claimtoken"

                                >
                                    <div className="claimtokenleft">
                                        <div className="claimtokenimg">
                                            <img
                                                src="\assets\claim\dummy.png"
                                                alt="claiminnerimg"
                                                className="claiminnerimg"
                                            />
                                        </div>
                                        <h6 className="tokenlefthead">$10,000 GEMS NFT</h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                                fill="#797979"
                                            />
                                        </svg>
                                    </div>
                                    <h6 className="claimtokenrighthead">
                                        0.00 GEMS <br />
                                        <span className="xspan">
                                            x10,000 NFTS
                                        </span>
                                    </h6>
                                </div>


                                <div
                                    className="claimtoken"

                                >
                                    <div className="claimtokenleft">
                                        <div className="claimtokenimg">
                                            <img
                                                src="\assets\claim\dummy.png"
                                                alt="claiminnerimg"
                                                className="claiminnerimg"
                                            />
                                        </div>
                                        <h6 className="tokenlefthead">$30,000 GEMS NFT</h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                                fill="#797979"
                                            />
                                        </svg>
                                    </div>
                                    <h6 className="claimtokenrighthead">
                                        0.00 GEMS <br />
                                        <span className="xspan">
                                            x130000 NFTS
                                        </span>
                                    </h6>
                                </div>


                                <div
                                    className="claimtoken"

                                >
                                    <div className="claimtokenleft">
                                        <div className="claimtokenimg">
                                            <img
                                                src="\assets\claim\dummy.png"
                                                alt="claiminnerimg"
                                                className="claiminnerimg"
                                            />
                                        </div>
                                        <h6 className="tokenlefthead">$75,000 GEMS NFT</h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                                fill="#797979"
                                            />
                                        </svg>
                                    </div>
                                    <h6 className="claimtokenrighthead">
                                        0.00 GEMS <br />
                                        <span className="xspan">
                                            x175000 NFTS
                                        </span>
                                    </h6>
                                </div>

                                <div className="checkboxmain">
                                    <div class="content">
                                        <label className="checkBox">
                                            <input
                                                type="checkbox"
                                                id="ch"

                                            />
                                            <div class="transition"></div>
                                        </label>
                                    </div>
                                    <div className="checkboxtexts">
                                        <h6 className="checkboxhead">
                                            claim ONLY GEMS tokens{" "}
                                            <span className="greenspan">Lower Gas Fee</span>
                                        </h6>
                                        <p className="checkboxpara">
                                            CLAIM your GEMS tokens associated with NFTS
                                        </p>
                                    </div>
                                </div>
                                <div className="checkboxmain">
                                    <div class="content">
                                        <label className="checkBox">
                                            <input
                                                type="checkbox"
                                                id="ch"

                                            />
                                            <div class="transition"></div>
                                        </label>
                                    </div>
                                    <div className="checkboxtexts">
                                        <h6 className="checkboxhead">
                                            mint nft & claim GEMS tokens{" "}
                                            <span className="redspan"> Higher Gas Fee</span>
                                        </h6>
                                        <p className="checkboxpara">
                                            mint NFT and also receive your GEMS tokens associated
                                            with it
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="bottom-twice-elem">
                            <div className="claimallright">
                                <h6 className="claimallrighthead">Summary</h6>
                                <div className="summarymain">
                                    <p className="summarypara">{projectdetail?.projectInfo[0]?.privateSale} Tokens</p>
                                    <h6 className="summaryhead">     {(
                                        projectdetail?.totalTokens
                                    )?.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}{" "} {projectdetail?.projectInfo[0]?.privateSale}</h6>
                                </div>
                                {/* <div className="summarymain">
        <p className="summarypara">Testnet Rewards</p>
        <h6 className="summaryhead">
          {profile?.airdropAmount ? (
            <h6 className="summaryhead">
              {profile?.airdropAmount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              GEMS
            </h6>
          ) : (
            <h6 className="summaryhead">{0.0} GEMS</h6>
          )}{" "}
        </h6>
      </div> */}
                                {/* <div className="summarymain">
                                    <p className="summarypara">GEMS tokens associated with NFTs</p>
                                    <h6 className="summaryhead">
                                        <h6 className="summaryhead">{0.0} GEMS</h6>
                                    </h6>
                                </div> */}
                                <div className="totalmain">
                                    <p className="totalpara">TOTAL</p>
                                    {/* {profile?.airdropAmount ? ( */}
                                    <h6 className="totalhead">
                                        {(
                                            projectdetail?.totalTokens
                                        )?.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}{" "}
                                        {projectdetail?.projectInfo[0]?.privateSale}
                                    </h6>
                                    {/* ) : (
          <h6 className="totalhead">0.00</h6>
        )} */}
                                </div>
                                <div className="checkboxmain">
                                    <div className="content">
                                        <label className="checkBox">
                                            <input
                                                type="checkbox"
                                                id="ch"
                                                onChange={handleCheckboxChange}
                                                checked={isChecked}
                                            />
                                            <div className="transition"></div>
                                        </label>
                                    </div>
                                    <p className="maincheckboxpara">
                                        By checking this box, I affirm and warrant that I am not identified
                                        as a sanctioned individual (or any equivalent designation) on any
                                        sanctions, blacklist, or analogous register in any global
                                        jurisdiction.
                                    </p>
                                </div>

                                {/* <p
                                    style={{
                                        color: "red",
                                        textAlign: "center",
                                        marginBottom: 20,
                                        marginTop: -10,
                                        fontSize: 14,
                                    }}
                                >
                                    {error}
                                </p> */}

                                {/* nftCl */}
                                {!account ?
                                    <button
                                        // disabled={!isChecked}
                                        className="claimbtn"
                                        style={{
                                            opacity: 0.5,
                                            cursor: "not-allowed",
                                        }}
                                    >
                                        Connect Your Wallet First
                                    </button>
                                    : projectdetail?.projectInfo[0]?.isClaimed === false && account ?
                                        <button
                                            onClick={ClaimYourDops}
                                            disabled={!isChecked}
                                            className={`claimbtn ${!isChecked ? "disabled" : ""}`}
                                            style={{
                                                opacity: isChecked ? 1 : 0.5,
                                                cursor: isChecked ? "pointer" : "not-allowed",
                                            }}
                                        >
                                            Claim
                                        </button>
                                        :
                                        <button
                                            // disabled={!isChecked}
                                            className="claimbtn"
                                            style={{
                                                opacity: projectdetail?.projectInfo[0]?.isClaimed === false ? 1 : 0.5,
                                                cursor: projectdetail?.projectInfo[0]?.isClaimed === false ? "pointer" : "not-allowed",
                                            }}
                                        >
                                            Already Claimed
                                        </button>

                                }

                            </div>
                            {/* <div className="important-div">
                                <h6>Important Notice</h6>
                                <p>Please be aware that once you finalize this claim process, you will no longer have the opportunity to mint your NFTs at a later time.</p>
                            </div> */}

                        </div>
                    </div>
                </div>
            </section>

            <Modal
                className="common-modal-style success-modal"
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>success!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="buy-modal">
                        {/* <img src="\assets\happy.svg" alt="img" className="img-fluid" /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none" className=" d-none show-in-light-theme">
                            <path d="M76.583 37.9845L76.583 11.417L35.1296 51.7939L10.417 34.7697L10.417 59.3998L35.1296 77.583L76.583 37.9845Z" fill="#02171D" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none" className="hide-in-light-theme">
                            <path d="M76.583 37.9845L76.583 11.417L35.1296 51.7939L10.417 34.7697L10.417 59.3998L35.1296 77.583L76.583 37.9845Z" fill="#D1DADD" />
                        </svg>
                        <p>Your claim has been <br /> processed successfully.</p>
                        <button className="btn-common" onClick={handleClose}>
                            Okay
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                className="common-modal-style success-modal"
                show={show1}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="buy-modal">
                        {/* <img src="\assets\error.svg" alt="img" className="img-fluid" /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none" className=" d-none show-in-light-theme">
                            <path d="M76.998 10.0176L62.9889 10.0176L43.986 43.9639L57.9952 43.9639L76.998 10.0176Z" fill="#02171D" />
                            <path d="M76.998 77.9814L62.9889 77.9814L43.986 44.0351L52.8152 38.1665L57.9952 44.0351L76.998 77.9814Z" fill="#02171D" />
                            <path d="M11 10.0176L25.0092 10.0176L44.012 43.9639L30.0028 43.9639L11 10.0176Z" fill="#02171D" />
                            <path d="M11 77.9814L25.0092 77.9814L44.012 44.0351L35.1828 38.1665L30.0028 44.0351L11 77.9814Z" fill="#02171D" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none" className="hide-in-light-theme">
                            <path d="M76.998 10.0176L62.9889 10.0176L43.986 43.9639L57.9952 43.9639L76.998 10.0176Z" fill="#D1DADD" />
                            <path d="M76.998 77.9814L62.9889 77.9814L43.986 44.0351L52.8152 38.1665L57.9952 44.0351L76.998 77.9814Z" fill="#D1DADD" />
                            <path d="M11 10.0176L25.0092 10.0176L44.012 43.9639L30.0028 43.9639L11 10.0176Z" fill="#D1DADD" />
                            <path d="M11 77.9814L25.0092 77.9814L44.012 44.0351L35.1828 38.1665L30.0028 44.0351L11 77.9814Z" fill="#D1DADD" />
                        </svg>
                        <p>
                            Oops, something went wrong.
                        </p>
                        <button className="btn-common">
                            Okay
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                className="common-modal-style tablemodal"
                show={show2}
                onHide={handleClose2}
                centered
            >
                <Modal.Header closeButton className="mb-0">
                    <Modal.Title>Token Purchase Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <PurchaseModal />
                </Modal.Body>
            </Modal>


        </>
    )
}

export default Claim
