import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Banner from './Banner/Banner'
import Navbar from '../../landing/header/Navbar'

const NFADetailpage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <div className="wrapper-detailpage">
            <Navbar />
            <Banner />
            </div>
        </>
    )
}

export default NFADetailpage
