import React, { useEffect } from 'react'
import "./privacy.scss"
import AmlBanner from './AmlBanner';

const Aml = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="wrapper-apply">
            <AmlBanner />
            <section className="terms-section">
                <div className="custom-container">
                    <div className="insuranceinner">
                        <h6 className="insurancehead">
                        1. Introduction
                        </h6>
                        <p className="midpara">
                        This Anti-Money Laundering (AML) Policy outlines the processes and controls in place at <span className='midparabold'>GEMS LABS LIMITED</span>, a British Virgin Islands (BVI) entity, to prevent money laundering, terrorist financing, and other illicit activities related to our listing launchpad service. This service allows start-up blockchain projects to raise funds through token offerings.
                        </p>
                        <p className="midpara">This policy complies with the Anti-Money Laundering Regulations (AMLR) of the British Virgin Islands and international standards such as those set by the Financial Action Task Force (FATF).</p>
                     

                        <h6 className="insurancehead">
                        2. Objectives
                        </h6>
                        <ul>
                            <li>-Prevent the use of <span className='midparabold'>GEMS LABS LIMITED's</span> services for money laundering and terrorist financing.</li>
                            <li>-Ensure that <span className='midparabold'>GEMS LABS LIMITED</span> complies with relevant AML laws and regulations.</li>
                            <li>-Maintain transparency and integrity within the blockchain and financial cryptocurrency related ecosystems.</li>
                            <li>-Establish risk-based controls and due diligence procedures.</li>

                        </ul>
                        
                        <h6 className="insurancehead">
                        3. Scope
                        </h6>
                        <p className='midpara'>This policy applies to:</p>
                        <ul>
                            <li>All directors, officers, employees, and agents of <span className='midparabold'>GEMS LABS LIMITED</span>.</li>
                            <li>All users of <span className='midparabold'>GEMS LABS LIMITED's</span> listing launchpad service, including start-up blockchain projects and their associated representatives.</li>
                            <li>All activities conducted through <span className='midparabold'>GEMS LABS LIMITED</span>, including token sales, investments, and fundraising initiatives.</li>

                        </ul>
                        <h6 className="insurancehead">
                        4. AML Compliance Officer (AMLCO)
                        </h6>
                        <p className='midpara'><span className='midparabold'>GEMS LABS LIMITED</span> shall appoint an AML Compliance Officer (AMLCO) responsible for overseeing AML compliance. The AMLCO will:</p>
                        <ul>
                            <li>-Develop and implement AML procedures.</li>
                            <li>-Monitor and report suspicious transactions.</li>
                            <li>-Conduct regular AML risk assessments and audits.</li>
                            <li>-Liaise with regulatory authorities in the British Virgin Islands.</li>

                        </ul>
                        <p className="midpara">Responsibilities of AMLCO:</p>
                        <ul>
                            <li>-Ensure compliance with BVI AML regulations.</li>
                            <li>-Ensure staff are trained on AML procedures.</li>
                            <li>-Maintain records of due diligence and suspicious activity reports (SARs).</li>

                        </ul>
                        <h6 className="insurancehead">
                        5. Know Your Customer (KYC) and Due Diligence
                        </h6>
                        <p className='midpara'>5.1. Customer Identification Procedures</p>
                        <p className='midpara'>Prior to accepting any individual or entity onto the launchpad platform, <span className='midparabold'>GEMS LABS LIMITED</span> will verify the identity of customers and entities using a risk-based approach. This includes:</p>
                        <p className="midpara">For Individuals:</p>
                        <ul>
                            <li>-Valid government-issued ID (passport or national ID card).</li>
                            <li>-Proof of address (utility bill, bank statement, or other verified official government document).</li>
                        </ul>
                        <p className="midpara">For Entities (Projects):</p>
                        <ul>
                            <li>-Certificate of incorporation or equivalent registration document.</li>
                            <li>-Proof of the entity’s registered address.</li>
                            <li>-Identification of beneficial owners (individuals who own 20% or more of the entity).</li>
                            <li>-Verification of directors and any significant controlling individuals including authorised signatories.</li>
                            <li>-Other supplementary documents such as licences or registrations related to the provided product or service. </li>
                        </ul>
                        <p className="midpara">5.2. Enhanced Due Diligence (EDD)</p>
                        <p className="midpara">Enhanced Due Diligence (EDD) will be applied to high-risk customers or transactions, including:</p>
                        <ul>
                            <li>-Individuals or entities from high-risk jurisdictions (as per FATF recommendations).</li>
                            <li>-Politically exposed persons (PEPs) or their family members.</li>
                            <li>--Transactions involving unusually large sums of money or complex structures with no clear economic rationale.</li>
                            <li>-EDD measures may include additional verification of identity, obtaining information on the source of funds, and closer monitoring of accounts and transactions.</li>

                        </ul>
                        <h6 className="insurancehead">
                        6. Risk Assessment
                        </h6>
                        <p className="midpara"><span className='midparabold'>GEMS LABS LIMITED</span> will perform a risk-based assessment of its services and customers, considering factors such as geographic location, transaction size, and customer profile. The risk assessment will classify customers and projects into different risk categories, with more stringent controls applied to higher-risk clients or transactions.</p>
                        <h6 className="insurancehead">
                        7. Transaction Monitoring and Reporting
                        </h6>
                        <p className="midpara"><span className='midparabold'>GEMS LABS LIMITED</span> will implement systems to detect and monitor suspicious activities, such as:

</p>
<ul>
                            <li>-Transactions that appear inconsistent with the customer’s profile or financial history.</li>
                            <li>-Transactions or cryptocurrency wallets appear as flagged or identified as illicit in any monitoring undertaken in the regular ongoing monitoring procedure. </li>
                            <li>-Sudden and unexplained changes in the source of funds.</li>
                            <li>-Any activity that may indicate money laundering or terrorist financing.
                            -</li>
                            <li>-Any suspicious activity will be reported to the AMLCO. If deemed necessary, the AMLCO will file a Suspicious Activity Report (SAR) with the Financial Investigation Agency (FIA) of the British Virgin Islands.</li>

                        </ul>
                        <h6 className="insurancehead">
                        8. Record-Keeping
                        </h6>
                        <p className='midpara'><span className='midparabold'>GEMS LABS LIMITED</span> will maintain records of all customer due diligence, transactions, and communications for a minimum period of 5 years from the date of the transaction or the termination of the business relationship. These records include:</p>
                        <ul>
                            <li>-KYC documents.</li>
                            <li>-Transaction records (including blockchain transaction IDs where applicable).</li>
                            <li>-Internal and external communications related to suspicious activities.</li>
                        </ul>

                        <h6 className="insurancehead">9. Training</h6>
                        <p className='midpara'>All employees and agents of <span className='midparabold'>GEMS LABS LIMITED</span> will receive regular AML training. The training will cover:</p>
                        <ul>
                            <li>-AML regulations and legal requirements.</li>
                            <li>-Procedures for identifying suspicious transactions.</li>
                            <li>-Responsibilities for reporting and compliance.</li>
                            <li>-KYC and due diligence processes.</li>
                            <li>-Training will be updated annually and whenever necessary due to regulatory changes or updated internal procedures.</li>
                        </ul>
                        <h6 className="insurancehead">10. Prohibited Activities</h6>



                        <p className='midpara'><span className='midparabold'>GEMS LABS LIMITED</span> will not engage in or facilitate:</p>
                        <ul>
                            <li>-Transactions with entities or individuals listed on sanctions lists, including but not limited to those maintained by the United Nations, European Union, and U.S. Office of Foreign Assets Control (OFAC).</li>
                            <li>-Services or activities related to prohibited industries or jurisdictions that fail to comply with AML standards.</li>
                        </ul>
                        <h6 className="insurancehead">11. Reporting Obligations</h6>
                        <p className='midpara'><span className='midparabold'>GEMS LABS LIMITED</span> will adhere to its reporting obligations under BVI law, including:</p>
                        <ul>
                            <li>-Filing Suspicious Activity Reports (SARs) when required.</li>
                            <li>-Cooperating fully with law enforcement and regulatory authorities in any AML investigations.</li>
                         
                        </ul>
                        
                        <h6 className="insurancehead">12. Independent Audit</h6>
                        <p className='midpara'><span className='midparabold'>GEMS LABS LIMITED</span> will arrange for an independent audit of its AML compliance program periodically, ensuring that the policies and procedures are effective and updated as per regulatory requirements.</p>
                        <h6 className="insurancehead">13. Penalties for Non-Compliance</h6>
                        <p className='midpara'>Failure to comply with this AML policy may result in:</p>
                        <ul>
                            <li>-Disciplinary action against employees or agents, including termination of employment.</li>
                            <li>-Suspension or termination of user's access to the agreed service.</li>
                            <li>-Legal action and regulatory fines as imposed by BVI authorities.</li>
                        </ul>
                       
                        <h6 className="insurancehead">14. Policy Review</h6>
                        <p className='midpara'>This AML policy will be reviewed at least annually or in response to changes in relevant laws and regulations. The AMLCO will be responsible for updating the policy as necessary to reflect new risks, regulatory requirements, and industry best practices.</p>
                        
                        <h6 className="insurancehead">15. Contact Information</h6>
                        <p className='midpara'>For any questions related to this AML policy, please contact at: <a href="mailto: Info@gems.vip">Info@gems.vip</a>.</p>
                     
                        <p className='midpara'>This policy reflects <span className='midparabold'>GEMS LABS LIMITED's</span> commitment to maintaining the highest standards of financial integrity and regulatory compliance in the blockchain space.</p>
                        <p className='midpara'><span className='midparabold'>GEMS LABS LIMITED</span></p>
                        <p className="midpara">Date: September 23th. 2024</p>
                        <p className="midpara">Version V.1</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Aml
