import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import Navbar from "../landing/header/Navbar";
import "./bannerapply.scss"

const BannerApply = ({ scrollToSecondSection }) => {
  const { t } = useTranslation();

  // const [showNav, setShowNav] = useState(true);

  // useEffect(() => {
  //   let timeoutId;

  //   const handleScroll = () => {
  //     setShowNav(true);
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       setShowNav(false);
  //     }, 3000);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     clearTimeout(timeoutId);
  //   };
  // }, []);
  return (
    <>
            <Navbar />
            <section className="main-signupbanner">
                <img src="/assets/education/leftshade.png" alt="leftshade" className="leftshade" />
                <img src="/assets/education/rightshade.png" alt="leftshade" className="rightshade" />
                <img src="/assets/education/leftshadembl.png" alt="leftshade" className="leftshadembl d-none" />
                <img src="/assets/education/rightshadembl.png" alt="leftshade" className="rightshadembl d-none" />
                <div className="custom-container">
                    <div className="main-content">
                        <div className="contentheads">
                            {/* <p className="upper-text">
                            Sign Up as a <br />
                            <span>Project</span>
                            </p> */}
                            <h2 className="lower-text">
                           {t('Signupas')} <br />
                            <span>{t('Process-t2')}</span>
                            </h2>
                            {/* <p className="faqspara">Et dolores quis sed reiciendis quia eum libero voluptates a accusantium culpa eos dolorem nihil qui sint iste.</p> */}
                        </div>
                    </div>
                </div>
            </section>
    </>
  );
};

export default BannerApply;
