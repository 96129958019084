import React, { useEffect, useState } from "react";
import "./gemspurchases.scss"
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import { useParams } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import axios from 'axios';
import Loader from "../../hooks/loader";
import Environment from "../../utils/Environment";
// import PsMarketCap from "../../hooks/chinaFunction/psMarketCap";

const AllPurchasesReferral = () => {
    const { id } = useParams();
    const { account } = useWeb3React();
    // const { psMarketCap } = PsMarketCap()
    // const [claimCheckStatus, setClaimCheckStatus] = useState(false);
    // console.log("value of status", claimCheckStatus)

    const [mainLoader1, setMainLoader1] = useState(false);
    // const [claimedvalue, setClaimedvalue] = useState();
    const [projectdetail, setProjectDetail] = useState(null)
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [contractaddress, setContractAddress] = useState(null);
    const [finalizaesale, setFinalizaeSale] = useState([]);
    // console.log("address here is", contractaddress)
    const getAllProjectsData = async (page = 1) => {
        setMainLoader1(true)
        const offset = page;
        var config = {

            method: "get",
            url: `${Environment?.Api_Url}/referral/buyer-all-purchases?by=${account.toLowerCase()}&limit=10&offset=${offset}&privateSale=${id}`,
            headers: {
                // authorization: `Bearer ${tok}`
            },
        };

        axios(config)
            .then(function (response) {
                const data = response.data;
                setProjectDetail(response?.data?.data || []);
                setTotalPages(data?.data?.pages || 0);
                setTotalCount(data?.data?.count || 0);
                setContractAddress(data?.data?.projectInfo[0]?.privateSaleContract)
                // setMainLoader1(false)
                // setProjectDetail(response?.data?.data?.projects[0])
                // setProjectDetail(response?.data?.data)

                // console.log("+++++++++++++++++++", response?.data?.data?.buyerAllTransationsOfSpecificProject)

            })
            .catch(function (error) {
                setMainLoader1(false)
                console.error("Error fetching data:", error);
            });
    };



    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };



    const checkFinalizedSale = async () => {
        setMainLoader1(true)
        var data = JSON.stringify({
            query: `query MyQuery {
                saleFinalizeds(where: {contract: "${contractaddress}"}) {
                contract
                transactionHash
                id
                blockTimestamp
                blockNumber
            }
            }`,
            variables: {},
        });
        var config = {
            method: "post",
            url: "https://api.studio.thegraph.com/query/63987/referal-sale-base-v2/version/latest",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        await axios(config)
            .then(async function (response) {
                setFinalizaeSale(response?.data?.data?.saleFinalizeds)
                setMainLoader1(false)

                // console.log("responce here is", 
                // )
            })
            .catch(function (error) {
                setMainLoader1(false)
                console.log(error, "error");
            });
    };

    useEffect(() => {
        if (contractaddress) {
            checkFinalizedSale();
        }
    }, [contractaddress])

    useEffect(() => {
        if (id && account) {
            getAllProjectsData(currentPage);
        }
        // else if(!account){
        //     // console.log("outhere")
        //     window.location.assign("/");
        //     // fetchProjects(currentPage); 
        // }
    }, [id, account, currentPage])

    // Format the transaction time
    const formatTransactionTime = (time) => {
        const date = new Date(time);

        // Format for the time: HH:mm dd/MM/yy
        return date.toLocaleString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });
    };

    // useEffect(() => {
    //     if (contractaddress) {
    //         console.log("in here")
    //         firstTimeData();
    //     }
    // }, [contractaddress]);

    // const firstTimeData = async () => {
    //     try {
    //         let a = await psMarketCap(contractaddress)
    //         setClaimedvalue(a)
    //         console.log("claimed value", a)
    //         // setClaimCheckStatus(a?.claimsEnabled);
    //     } catch (err) {
    //         console.log(err.message);
    //     }
    // };

    // console.log("finalize sale is ", finalizaesale?.length > 0 ? "available" : finalizaesale?.length <= 0 ? "not available" : "")
    return (
        <>
            {mainLoader1 && <Loader />}
            <Navbar />
            <section className="all-purchases">
                <div className="custom-container">
                    <div className="main-heading">
                        <p>MY PURCHASES</p>
                        <div className="twice-elem">
                            <img width={24} src={projectdetail?.projectInfo[0]?.projectImage} alt="img" className='img-fluid' />
                            <h5>{projectdetail?.projectInfo[0]?.projectName}</h5>
                        </div>
                    </div>
                    <div className="claim-box">
                        <div className="left-side">
                            <div className="inside-left">
                                <svg className='hide-in-light-theme' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M13.1293 18.9613C10.2194 20.6414 6.49849 19.6444 4.81844 16.7344C3.13839 13.8245 4.1354 10.1036 7.04534 8.42354C9.95527 6.74349 13.6762 7.74051 15.3562 10.6504C17.0363 13.5604 16.0393 17.2813 13.1293 18.9613Z" stroke="#D1DADD" stroke-width="1.62529" />
                                    <path d="M10.3085 5.35899C12.9006 3.22095 16.7351 3.58905 18.8732 6.18117C21.0112 8.77328 20.6431 12.6078 18.051 14.7459" stroke="#D1DADD" stroke-width="1.62529" />
                                    <rect x="10.0502" y="11.0635" width="3.816" height="3.816" transform="rotate(45 10.0502 11.0635)" fill="#D1DADD" />
                                </svg>
                                <svg className='d-none show-in-light-theme' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M13.1295 18.9613C10.2195 20.6414 6.49861 19.6444 4.81856 16.7344C3.13851 13.8245 4.13553 10.1036 7.04546 8.42354C9.95539 6.74349 13.6763 7.74051 15.3564 10.6504C17.0364 13.5604 16.0394 17.2813 13.1295 18.9613Z" stroke="#02171D" stroke-width="1.62529" />
                                    <path d="M10.3086 5.35899C12.9007 3.22095 16.7353 3.58905 18.8733 6.18117C21.0113 8.77328 20.6432 12.6078 18.0511 14.7459" stroke="#02171D" stroke-width="1.62529" />
                                    <rect x="10.0503" y="11.0635" width="3.816" height="3.816" transform="rotate(45 10.0503 11.0635)" fill="#02171D" />
                                </svg>
                                <div className="right-text">
                                    <p>Tokens Purchased</p>
                                    <h5>{projectdetail?.totalTokens ? (projectdetail?.totalTokens)?.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : "0"} {projectdetail?.projectInfo[0]?.privateSale} </h5>
                                    {/* <span>+0.8%</span> */}
                                </div>
                            </div>
                            <div className="bottom-inline">
                                {/* <p><span></span> {projectdetail?.totalTokens?.toLocaleString()} Tokens</p> */}
                                {/* <p><span></span> {projectdetail?.totalNftToken?.toLocaleString()} NFTs</p> */}
                            </div>
                        </div>
                        <div className="right-side">
                            {
                                finalizaesale?.length <= 0 ?
                                    (
                                        <>
                                            <p className="ashdhjasdajsdhhjads">Sale Not Finalized Yet</p>
                                            <Link
                                                class="jnjnjnjnnj"
                                                style={{
                                                    opacity: 0.5,
                                                    cursor: "not-allowed",
                                                }}
                                                to={`/claim/${id}`}>Claim All ${projectdetail?.projectInfo[0]?.privateSale}</Link>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                                projectdetail?.projectInfo[0]?.isClaimed === false ?
                                                    (
                                                        <p>Claim are now open for ${projectdetail?.projectInfo[0]?.privateSale}</p>
                                                    )
                                                    :
                                                    (
                                                        <p className="ashdhjasdajsdhhjads">Already Claimed</p>
                                                    )
                                            }
                                            <Link
                                                class={projectdetail?.projectInfo[0]?.isClaimed === false ? "" : "jnjnjnjnnj"}
                                                style={{
                                                    opacity: projectdetail?.projectInfo[0]?.isClaimed === false ? 1 : 0.5,
                                                    cursor: projectdetail?.projectInfo[0]?.isClaimed === false ? "pointer" : "not-allowed",
                                                }}
                                                to={`/claim/${id}`}>{projectdetail?.projectInfo[0]?.isClaimed === false ? "Claim All" : "Claimed"} ${projectdetail?.projectInfo[0]?.privateSale}</Link>
                                        </>
                                    )
                            }
                            {/* <p>Claim are now open for $OAI</p>
                                      <Link to="/claim">Claim All $OAI</Link> */}

                        </div>
                    </div>
                    <div className="custom-table">
                        <div class="table-responsive">
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th>Purchased on</th>
                                        <th>PURCHASE PRICE</th>
                                        <th>Amount Spent</th>
                                        <th>Token PURCHASED</th>
                                        <th>TYPE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projectdetail?.buyerAllTransationsOfSpecificProject?.map((project, index) => (
                                        <tr key={index}>
                                            <td>
                                                {formatTransactionTime(project?.transactionTime)}
                                            </td>
                                            <td>${project?.price}/{projectdetail?.projectInfo[0]?.privateSale}</td>
                                            {project?.tokenSelected === "BTC" || project?.tokenSelected === "ETH" || project?.tokenSelected === "WBTC" ?
                                                <td>{project?.amountInvested?.toLocaleString("en-US", {
                                                    minimumFractionDigits: 5,
                                                    maximumFractionDigits: 5,
                                                })} {project?.tokenSelected}</td>
                                                :
                                                (
                                                    <td>{project?.amountInvested?.toLocaleString("en-US", {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })} {project?.tokenSelected}</td>
                                                )
                                            }
                                            {/* <td>{project?.amountInvested?.toLocaleString("en-US", {
                                                minimumFractionDigits: 5,
                                                maximumFractionDigits: 5,
                                            })} {project?.tokenSelected}</td> */}
                                            <td>{project?.dopPurchased.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })} {projectdetail?.projectInfo[0]?.privateSale}</td>
                                            <td>{project?.type}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        <div className="custom-pagination">
                            <p>
                                SHOWING {(currentPage - 1) * 10 + 1}-{Math.min(currentPage * 10, totalCount)} OF {totalCount}
                            </p>
                            <ul className="pagination">
                                {/* Previous Button */}
                                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                    <a
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        href="#"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <g opacity="0.5">
                                                <path d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z" fill="#939FA3" />
                                                <path d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z" fill="#939FA3" />
                                                <path d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z" fill="#939FA3" />
                                            </g>
                                        </svg>
                                    </a>
                                </li>

                                {/* Page Numbers */}
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                                        <a
                                            className="page-link"
                                            onClick={() => handlePageChange(index + 1)}
                                            href="#"
                                        >
                                            {index + 1}
                                        </a>
                                    </li>
                                ))}

                                {/* Next Button */}
                                <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                    <a
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        href="#"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z" fill="#30F1B6" />
                                            <path d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z" fill="#30F1B6" />
                                            <path d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z" fill="#30F1B6" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllPurchasesReferral
