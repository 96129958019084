import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Information = () => {
    const { t } = useTranslation()



    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>Overview <span></span></h2>
                    <p>Project Miners are virtual miners with associated NFTs that generate fixed amounts of tokens from premium projects on Gems Launchpad. Available in three tiers (Mini, Standard, and Mega), these virtual miners must be connected to a Gems Node to operate, creating a synergistic relationship within the ecosystem.
                    </p>
                    <p>Mercury represents the first release in the Project Miners series. It is a MultiMiner that generates tokens from four premium projects simultaneously, offering a diverse approach to token generation.</p>

                </div>
                <div className="first-para">
                    <h6>Project Allocation</h6>
                    <p>Mercury generates tokens from four premium projects with the following allocation:</p>
                    <ul>
                        <li>Incentiv: 10%
                        </li>
                        <li>Olympus AI: 36%</li>
                        <li>Prodex: 18%</li>
                        <li>Rain: 36%</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>Product Features  <span></span></h2>
                    <h6>NFT Format</h6>
                    <ul>
                        <li>All Miners are part of a single NFT collection featuring three types with different rarity levels
                        </li>
                        <li>Can be traded on secondary markets as income-generating assets
                        </li>
                        <li>Total number of miners is limited</li>
                        <li>A new Miner generating tokens from new projects is expected to be released every few months</li>
                    </ul>
                    <h6>Token Generation</h6>
                    <ul>
                        <li>Generates tokens from four premium projects simultaneously, as specified above</li>
                        <li>Each Miner has a fixed monthly generation rate that remains constant throughout its lifetime</li>
                        <li>2-year operational period for each project, starting from respective TGE dates</li>
                        <li>Token generation rates are determined by the Miner's type: Mini, Standard, or Mega</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>Types of Miners<span></span></h2>
                    <p>There are 3 types, or tiers, of Miners:</p>
                    <ul>
                        <li><span>MiniMiners:</span>  The most common tier in the collection, offering baseline token generation rates <br />
                            Price: $199</li>
                        <li><span>Standard Miners:</span>  Medium rarity tier with improved generation capabilities <br />
                            Price: $3,999</li>
                        <li><span>MegaMiners:</span>  The rarest and most powerful tier, providing the highest token generation rates for premium project tokens <br />
                            Price: $19,999</li>
                    </ul>
                    <h6>Important notes:</h6>
                    <ul>
                        <li>Miner prices increase over time</li>
                        <li>50% discount available with Gems Leader code</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>How It Works<span></span></h2>
                    <h6>Operation Requirements</h6>
                    <ul>
                        <li>Must be connected to a Gems Node

                        </li>
                        <li>You can connect multiple Miners to one Node</li>
                        <li>MegaMiners unlock the highest yields</li>
                        <li>Token generation starts post-TGE and upon connection with a Gems Node</li>
                    </ul>
                    <h6>Token Claiming Process</h6>
                    <p>Token claims from Miners operate on a monthly schedule, providing a predictable income stream from premium projects. Each month, you'll receive tokens based on your Miner's type and generation rate. These claims are handled separately from Node airdrops, meaning you'll need to manage your Miner claims independently from your Node claims. The process begins as soon as your Miner is connected to a Node, and continues throughout the Miner's 2-year operational period. Claims require only standard network gas fees.</p>
                </div>

                <div className="first-para">
                    <h2>Technical Details<span></span></h2>
                    <ul>
                        <li><span>Purchase Currency:</span> USDT or ETH

                        </li>
                        <li><span>Duration:</span> 2-year operation period per project
                        </li>
                        <li><span>Collection:</span> Single ERC-1155 NFT collection</li>
                        <li><span>Availability:</span> Limited supply</li>
                        <li>Each Miner is wallet-bound but transferable</li>
                        <li>Real-time token accrual tracking on Gems platform</li>
                    </ul>

                </div>
                <div className="first-para">
                    <h2>Utility<span></span></h2>
                    <ul>
                        <li>Enhanced premium token generation

                        </li>
                        <li>Secondary market trading potential

                        </li>
                        <li>Ecosystem participation through premium projects
                        </li>
                        <li>No maintenance required</li>
                    </ul>

                </div>


            </section>


        </>
    )
}

export default Information
