import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL_SURVEY } from "../../utils/ApiUrl";
import Loader from "../../hooks/loader";
import { useWeb3React } from "@web3-react/core";

const SurveyQuestion = ({ setSteps, userData }) => {
  if (userData?.isSurveyCompleted) {
    setSteps(4);
  }

  const token = localStorage.getItem("accessToken");
  // console.log("Access token", token);
  const { account } = useWeb3React();

  if (token && account) {
    const signerAccount = localStorage.getItem("signerAccount");
    if (signerAccount !== account) {
      setSteps(0);
    }
  } else if (!token || !account) {
    setSteps(0);
  }

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [surveyDataa, setSurveyDataa] = useState(null);
  const [surveyQuestions, setSurveyQuestions] = useState(null);

  console.log("responses", responses);

  // Define the survey questions and options
  // const surveyData = [
  //   {
  //     question: "What statement best describes you?",
  //     options: [
  //       {
  //         uid: 1,
  //         text: "Life is a joke",
  //         img: "/assets/gemscommunityproject/survey/1.png",
  //       },
  //       {
  //         uid: 2,
  //         text: "Life is a playground",
  //         img: "/assets/gemscommunityproject/survey/2.png",
  //       },
  //       {
  //         uid: 3,
  //         text: "Life is Hard",
  //         img: "/assets/gemscommunityproject/survey/3.png",
  //       },
  //       {
  //         uid: 4,
  //         text: "Life finds a way",
  //         img: "/assets/gemscommunityproject/survey/4.png",
  //       },
  //     ],
  //   },
  //   {
  //     question:
  //       "If we looked at your browser history, what would we find the most?",
  //     options: [
  //       {
  //         uid: 1,
  //         text: "Animal",
  //         img: "/assets/gemscommunityproject/survey/5.png",
  //       },
  //       {
  //         uid: 2,
  //         text: "Memes",
  //         img: "/assets/gemscommunityproject/survey/6.png",
  //       },
  //       {
  //         uid: 3,
  //         text: "Food",
  //         img: "/assets/gemscommunityproject/survey/7.png",
  //       },
  //       {
  //         uid: 4,
  //         text: "News",
  //         img: "/assets/gemscommunityproject/survey/8.png",
  //       },
  //     ],
  //   },
  //   {
  //     question: "What skill do you wish you had?",
  //     options: [
  //       {
  //         uid: 1,
  //         text: "Dancing",
  //         img: "/assets/gemscommunityproject/survey/9.png",
  //       },
  //       {
  //         uid: 2,
  //         text: "Cooking",
  //         img: "/assets/gemscommunityproject/survey/10.png",
  //       },
  //       {
  //         uid: 3,
  //         text: "Evolving",
  //         img: "/assets/gemscommunityproject/survey/11.png",
  //       },
  //       {
  //         uid: 4,
  //         text: "Singing",
  //         img: "/assets/gemscommunityproject/survey/12.png",
  //       },
  //     ],
  //   },
  //   {
  //     question: "Which is the most important to you?",
  //     options: [
  //       {
  //         uid: 1,
  //         text: "Love",
  //         img: "/assets/gemscommunityproject/survey/13.png",
  //       },
  //       {
  //         uid: 2,
  //         text: "Knowledge",
  //         img: "/assets/gemscommunityproject/survey/14.png",
  //       },
  //       {
  //         uid: 3,
  //         text: "Money",
  //         img: "/assets/gemscommunityproject/survey/15.png",
  //       },
  //       {
  //         uid: 4,
  //         text: "Family",
  //         img: "/assets/gemscommunityproject/survey/16.png",
  //       },
  //     ],
  //   },
  //   {
  //     question: "What's you're favorite style?",
  //     options: [
  //       {
  //         uid: 1,
  //         text: "Pixel Art",
  //         img: "/assets/gemscommunityproject/survey/17.png",
  //       },
  //       {
  //         uid: 2,
  //         text: "Cartoon",
  //         img: "/assets/gemscommunityproject/survey/18.png",
  //       },
  //       {
  //         uid: 3,
  //         text: "AI Art",
  //         img: "/assets/gemscommunityproject/survey/19.png",
  //       },
  //       {
  //         uid: 4,
  //         text: "Realistic",
  //         img: "/assets/gemscommunityproject/survey/20.png",
  //       },
  //       {
  //         uid: 5,
  //         text: "Minimalist",
  //         img: "/assets/gemscommunityproject/survey/21.png",
  //       },
  //       {
  //         uid: 6,
  //         text: "Flat",
  //         img: "/assets/gemscommunityproject/survey/22.png",
  //       },
  //       {
  //         uid: 7,
  //         text: "Line Art",
  //         img: "/assets/gemscommunityproject/survey/23.png",
  //       },
  //       {
  //         uid: 8,
  //         text: "3D Rendered",
  //         img: "/assets/gemscommunityproject/survey/24.png",
  //       },
  //     ],
  //   },
  //   {
  //     question: "Which color makes you go c(◔‿◔)ɔ ♡?",
  //     options: [
  //       {
  //         uid: 1,
  //         text: "Red",
  //         img: "/assets/gemscommunityproject/survey/25.png",
  //       },
  //       {
  //         uid: 2,
  //         text: "Yellow",
  //         img: "/assets/gemscommunityproject/survey/26.png",
  //       },
  //       {
  //         uid: 3,
  //         text: "Green",
  //         img: "/assets/gemscommunityproject/survey/27.png",
  //       },
  //       {
  //         uid: 4,
  //         text: "Purple",
  //         img: "/assets/gemscommunityproject/survey/28.png",
  //       },
  //       {
  //         uid: 5,
  //         text: "Orange",
  //         img: "/assets/gemscommunityproject/survey/29.png",
  //       },
  //       {
  //         uid: 6,
  //         text: "Blue",
  //         img: "/assets/gemscommunityproject/survey/30.png",
  //       },
  //       {
  //         uid: 7,
  //         text: "Teal",
  //         img: "/assets/gemscommunityproject/survey/31.png",
  //       },
  //       {
  //         uid: 8,
  //         text: "Black",
  //         img: "/assets/gemscommunityproject/survey/32.png",
  //       },
  //       {
  //         uid: 9,
  //         text: "Crimson",
  //         img: "/assets/gemscommunityproject/survey/33.png",
  //       },
  //       {
  //         uid: 10,
  //         text: "Pink",
  //         img: "/assets/gemscommunityproject/survey/34.png",
  //       },
  //       {
  //         uid: 11,
  //         text: "Turquoise",
  //         img: "/assets/gemscommunityproject/survey/35.png",
  //       },
  //       {
  //         uid: 12,
  //         text: "Cream",
  //         img: "/assets/gemscommunityproject/survey/36.png",
  //       },
  //     ],
  //   },
  // ];

  const imageData = [
    [
      "/assets/gemscommunityproject/survey/1.png",
      "/assets/gemscommunityproject/survey/2.png",
      "/assets/gemscommunityproject/survey/3.png",
      "/assets/gemscommunityproject/survey/4.png",
    ],
    [
      "/assets/gemscommunityproject/survey/5.png",
      "/assets/gemscommunityproject/survey/6.png",
      "/assets/gemscommunityproject/survey/7.png",
      "/assets/gemscommunityproject/survey/8.png",
    ],
    [
      "/assets/gemscommunityproject/survey/9.png",
      "/assets/gemscommunityproject/survey/10.png",
      "/assets/gemscommunityproject/survey/11.png",
      "/assets/gemscommunityproject/survey/12.png",
    ],
    [
      "/assets/gemscommunityproject/survey/133.png",
      "/assets/gemscommunityproject/survey/14.png",
      "/assets/gemscommunityproject/survey/15.png",
      "/assets/gemscommunityproject/survey/16.png",
    ],
    [
      "/assets/gemscommunityproject/survey/17.png",
      "/assets/gemscommunityproject/survey/18.png",
      "/assets/gemscommunityproject/survey/19.png",
      "/assets/gemscommunityproject/survey/20.png",
      "/assets/gemscommunityproject/survey/21.png",
      "/assets/gemscommunityproject/survey/22.png",
      "/assets/gemscommunityproject/survey/23.png",
      "/assets/gemscommunityproject/survey/24.png",
    ],
    [
      "/assets/gemscommunityproject/survey/25.png",
      "/assets/gemscommunityproject/survey/26.png",
      "/assets/gemscommunityproject/survey/27.png",
      "/assets/gemscommunityproject/survey/28.png",
      "/assets/gemscommunityproject/survey/29.png",
      "/assets/gemscommunityproject/survey/30.png",
      "/assets/gemscommunityproject/survey/31.png",
      "/assets/gemscommunityproject/survey/32.png",
      "/assets/gemscommunityproject/survey/33.png",
      "/assets/gemscommunityproject/survey/34.png",
      "/assets/gemscommunityproject/survey/35.png",
      "/assets/gemscommunityproject/survey/36.png",
    ],
  ];

  const comments = [
    "Interesting person you are!",
    "We’re ready when you are:",
    ["Believe in yourself", "If you can dream it, you can click"],
    ["That makes total sense.", "Go ahead and click"],
    ["Oooh pretty!", "Last question approaches"],
    [
      "Nice getting to know you!",
      "The next stage is, naturally, connecting on social media",
    ],
  ];

  const getSurveryData = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL_SURVEY}/surveys`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      console.log("Survey data:", response?.data?.data);
      setSurveyDataa(response?.data?.data);
      setSurveyQuestions(response?.data?.data?.questions);
    } catch (error) {
      console.error("Error fetching survey data:", error);
    } finally {
      setLoading(false);
    }
  };

  //   const totalQuestions = surveyData.length;
  const totalQuestions = surveyQuestions?.length;

  const handleOptionClick = (option) => {
    const updatedResponses = [...responses];
    updatedResponses[currentQuestionIndex] = option._id;
    setResponses(updatedResponses);
  };

  const handleNextClick = () => {
    setLoading(true);
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const handleBackClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleFinishClick = async () => {
    const answers = responses?.map((optionId, index) => ({
      questionId: surveyQuestions?.[index]?._id,
      optionId,
    }));
    const accessToken = localStorage.getItem("accessToken");

    try {
      setLoading(true);
      const response = await axios.patch(
        `${API_URL_SURVEY}/surveys/${surveyDataa?._id}`,
        { answers },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Survey Response sent successfully:", response.data);
      setSteps(4);
    } catch (error) {
      console.log("Error sending response:", error?.response);
      // if (error?.response?.data?.message === "Already answered") {
      //   setSteps(4);
      // }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSurveryData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading && <Loader />}
      <section className="survey-questions">
        <div className="main-heading">
          <h4>You did good. Now you get to ⭐participate⭐</h4>
          <p>Listen to your heart and answer the following questions.</p>
        </div>
        <div className="bottom-questions">
          <h5 className="head-text">
            <span>
              {currentQuestionIndex + 1}/{totalQuestions}
            </span>{" "}
            {surveyQuestions?.[currentQuestionIndex]?.question}
            {/* {surveyQuestions[currentQuestionIndex].question} */}
          </h5>
          <div className="parent-cards">
            {/* {surveyData[currentQuestionIndex].options.map((option, index) => ( */}
            {surveyQuestions?.[currentQuestionIndex]?.options?.map(
              (option, index) => (
                <div
                  key={index}
                  className={`single-card ${
                    // responses[currentQuestionIndex]?.uid === option.uid
                    responses?.[currentQuestionIndex] === option?._id
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  <div className="main-img">
                    <img
                      src={imageData[currentQuestionIndex][index]}
                      alt="option"
                      className="img-fluid"
                    />
                    {/* <img src={option.imageUrl} alt="option" className="img-fluid" /> */}
                  </div>
                  <h6>{option?.answer}</h6>
                </div>
              )
            )}
          </div>
          {responses.length > 0 && (
            <div className="bottom-detail">
              {responses[currentQuestionIndex] && (
                <h6>
                  {Array.isArray(comments[currentQuestionIndex])
                    ? comments[currentQuestionIndex].map((comment, index) => (
                        <div key={index}>{comment}</div>
                      ))
                    : comments[currentQuestionIndex]}
                </h6>
              )}
              <div className="navigation-buttons">
                {currentQuestionIndex != 0 && (
                  <>
                    <button
                      className="btn-back"
                      onClick={handleBackClick}
                      disabled={currentQuestionIndex === 0}
                    >
                      Back
                    </button>
                    {currentQuestionIndex === totalQuestions - 1 ? (
                      <button
                        className="btn-green"
                        onClick={() => handleFinishClick()}
                      >
                        Finish
                      </button>
                    ) : (
                      <button
                        className={`btn-green ${
                          responses[currentQuestionIndex] ? "" : "disabled"
                        }`}
                        onClick={handleNextClick}
                        disabled={!responses[currentQuestionIndex]}
                      >
                        Next
                      </button>
                    )}
                  </>
                )}

                {currentQuestionIndex === 0 && (
                  <button
                    className="btn-green firstbtngreen-btn"
                    onClick={handleNextClick}
                    disabled={!responses[currentQuestionIndex]}
                  >
                    Let’s Continue
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default SurveyQuestion;
