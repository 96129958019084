import React from 'react'
import "./selfserve.scss"
import { useTranslation } from 'react-i18next'

const Selfserve = () => {
    const {t} = useTranslation();
  return (
    <>
      <section className="self-serve">
        <img src="\assets\landing\selfserve\selfserve-bg.png" alt="img" className='img-fluid selfserve-bg hide-in-light-theme' />
        <img src="\assets\landing\selfserve\selfserve-bg-light.png" alt="img" className='img-fluid selfserve-bg d-none show-in-light-theme' />
        <video className="main-banner-video"
          muted="muted" playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo">
          <source src="\assets\landing\selfserve\bottom-right.mp4" type="video/mp4" />
        </video>
        <div className="custom-container">
            <div className="parent">
                <div className="left-side">
                    <h4>{t("Selft1")} <br /> <span>{t("Selft2")}</span>
                    </h4>
                    <p>The Self-Serve feature puts fundraising in your hands. Create and launch private sales independently with customizable parameters, automated token distribution, and seamless setup—all in just a few steps. Designed for blockchain startups of all sizes, Self-Serve makes raising funds simple, efficient, and accessible.</p>
                    <a href="https://creators.gems.vip/signup" target='_blank' className='btn-create'>{t('How-t3')}</a>
                </div>
                <div className="right-side">
                    <div className="points-data">
                        <div className="single-value">
                            <span>01</span>
                            <h6>{t('Vision3')}</h6>
                        </div>
                        <div className="single-value">
                            <span>02</span>
                            <h6>{t("Selft3")}</h6>
                        </div>
                        <div className="single-value">
                            <span>03</span>
                            <h6>{t("Selft4")}</h6>
                        </div>
                        <div className="single-value">
                            <span>04</span>
                            <h6>{t("Selft5")}</h6>
                        </div>
                        <div className="single-value">
                            <span>05</span>
                            <h6>{t("Selft6")}</h6>
                        </div>
                        <div className="single-value">
                            <span>06</span>
                            <h6>{t("Selft7")}</h6>
                        </div>
                        <div className="single-value">
                            <span>07</span>
                            <h6>{t("Selft8")} <p className="coming-soon">{t('Coming')}</p></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Selfserve
