import web3NoAccount from "./web3";
import GemsLockingABI from "./GemsLockingABI.json";
import GemsStakingABI from "./GemsStakingABI.json";
import GemsTokenABI from "./gemsTokenABI.json";
import StakingABI from "./StakingAbi.json";
import ChinaAbi from "./chinaAbi.json";
import LeiaAbi from "./leiaAbi.json"
import stackcontractGems from "../utils/StakingGemsAbi.json";
import stackcontractDop from "../utils/StakingDopAbi.json";

import tomiContract from "../utils/tomiAbi2.json";
import uniswapAbi from "../utils/uniswap.json";


const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract(abi, address);
};

export const chinaContract = (address, web3) => {
  return getContract(ChinaAbi, address, web3);
};

export const leiaContract = (address, web3) => {
  return getContract(LeiaAbi, address, web3);
};
export const getGemsLockingContract = (address, web3) => {
  return getContract(GemsLockingABI, address, web3);
};
export const getGemsStakingContract = (address, web3) => {
  return getContract(GemsStakingABI, address, web3);
};
export const getGemsTokenContract = (address, web3) => {
  return getContract(GemsTokenABI, address, web3);
};

export const getStackContract = (address, web3) => {
  return getContract(StakingABI, address, web3);
};

export const getStackGemsContract = (address, web3) => {
  return getContract(stackcontractGems, address, web3);
};

export const getStackDopContract = (address, web3) => {
  return getContract(stackcontractDop, address, web3);
};

export const getuniSwapRouter = (address, web3) => {
  return getContract(uniswapAbi, address, web3);
};

export const getTomiContract = (address, web3) => {
  return getContract(tomiContract, address, web3);
};