import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL_SURVEY } from "../../utils/ApiUrl";
import Loader from "../../hooks/loader";

const Completed = () => {
  const [userStats, setUserStats] = useState(null);
  const [referalLink, setreferalLink] = useState(null);
  const [loading, setLoading] = useState(false);

  const getStats = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL_SURVEY}/users`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setUserStats(response?.data?.data);
      setreferalLink(
        `https://gems.vip/gemscommunityproject/?id=${response?.data?.data?.referralCode}`
      );
    } catch (err) {
      console.log("error getting user stats");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Join the Gems Community!",
          text: "Check out this awesome community and earn rewards!",
          url: referalLink,
        });
        console.log("Referral link shared successfully!");
      } catch (error) {
        console.error("Error sharing referral link:", error);
      }
    } else {
      alert("Sharing is not supported on this device.");
    }
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(referalLink).then(
      () => {
        setIsCopied(true);
        console.log("Referral link copied to clipboard");
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section className="refferal-section">
            <div className="main-heading">
              <h4>Thank you for participating </h4>
              <p>
                We'll be announcing the reward claim dates on our social media
                channels, so <br /> make sure to follow us to stay updated!{" "}
              </p>
              <div className="social-links">
                <a href="https://x.com/WeMakeIt2Gether" target="_blank">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <g mask="url(#mask0_415_1381)">
                      <path
                        d="M22.05 1.30396H26.344L16.964 12.052L28 26.6799H19.36L12.588 17.81L4.848 26.6799H0.55L10.582 15.18L0 1.30596H8.86L14.972 9.41195L22.05 1.30396ZM20.54 24.1039H22.92L7.56 3.74595H5.008L20.54 24.1039Z"
                        fill="#30F1B6"
                      />
                    </g>
                  </svg>
                </a>
                <a href="https://t.me/WeMakeIt2Gether" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_415_1387)">
                      <path
                        d="M10.9867 17.7111L10.5235 24.2257C11.1862 24.2257 11.4732 23.9411 11.8174 23.5992L14.9242 20.6301L21.3619 25.3446C22.5425 26.0026 23.3744 25.6561 23.6929 24.2584L27.9185 4.45774L27.9197 4.45657C28.2942 2.71124 27.2885 2.02874 26.1382 2.4569L1.29987 11.9664C-0.395293 12.6244 -0.369626 13.5694 1.01171 13.9976L7.36187 15.9727L22.112 6.74324C22.8062 6.28357 23.4374 6.5379 22.9182 6.99757L10.9867 17.7111Z"
                        fill="#E1F5EB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_415_1387">
                        <rect width="28" height="28" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                {/* <a href="#" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M4.04469 1.42188C2.11983 1.42188 0.861328 2.68583 0.861328 4.34712C0.861328 5.97173 2.08234 7.27173 3.97083 7.27173H4.00735C5.96986 7.27173 7.19119 5.97173 7.19119 4.34712C7.1545 2.68583 5.96986 1.42188 4.04469 1.42188Z"
                  fill="#E1F5EB"
                />
                <path
                  d="M1.19385 9.58301H6.821V26.5125H1.19385V9.58301Z"
                  fill="#E1F5EB"
                />
                <path
                  d="M20.6331 9.18579C17.5974 9.18579 15.5619 12.0383 15.5619 12.0383V9.58313H9.93457V26.5126H15.5616V17.0585C15.5616 16.5524 15.5983 16.0471 15.7469 15.6852C16.1537 14.6745 17.0795 13.6275 18.634 13.6275C20.6702 13.6275 21.4846 15.18 21.4846 17.4559V26.5126H27.1113V16.8056C27.1113 11.6056 24.3351 9.18579 20.6331 9.18579Z"
                  fill="#E1F5EB"
                />
              </svg>
            </a> */}
              </div>
            </div>
            {/* <h4 className="completed-text">Earn an even bigger reward!</h4> */}
            <div className="parent-referalnew">
              <p className="para-new">
                Invite more friends with your referral link for greater rewards!
              </p>
              <div className="refferal-parent">
                <div className="left-ref">
                  <div className="inner-left">
                    <h6>Friends Joined the Quest</h6>
                    <p>
                      <span>{userStats?.referralCompleted}</span>/2
                    </p>
                  </div>
                  <a onClick={getStats}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 20C9.76667 20 7.875 19.225 6.325 17.675C4.775 16.125 4 14.2333 4 12C4 9.76667 4.775 7.875 6.325 6.325C7.875 4.775 9.76667 4 12 4C13.15 4 14.25 4.23733 15.3 4.712C16.35 5.18667 17.25 5.866 18 6.75V4H20V11H13V9H17.2C16.6667 8.06667 15.9377 7.33333 15.013 6.8C14.0883 6.26667 13.084 6 12 6C10.3333 6 8.91667 6.58333 7.75 7.75C6.58333 8.91667 6 10.3333 6 12C6 13.6667 6.58333 15.0833 7.75 16.25C8.91667 17.4167 10.3333 18 12 18C13.2833 18 14.4417 17.6333 15.475 16.9C16.5083 16.1667 17.2333 15.2 17.65 14H19.75C19.2833 15.7667 18.3333 17.2083 16.9 18.325C15.4667 19.4417 13.8333 20 12 20Z"
                        fill="#30F1B6"
                      />
                    </svg>
                  </a>
                </div>
                <div className="right-ref">
                  <div className="inner-left">
                    <h6>Your Referral Link</h6>
                    <h5>{referalLink}</h5>
                  </div>
                  <div
                    className="d-flex align-center justify-content-center"
                    style={{ gap: "10px" }}
                  >
                    <a
                      onClick={handleShare}
                      className="d-none showinmobileonly"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M3 9V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9M12 4.5L9 1.5M9 1.5L6 4.5M9 1.5V11.25"
                          stroke="#30F1B6"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a onClick={handleCopy}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        style={{
                          transition: "fill 0.3s",
                          fill: isCopied ? "var(--btn-green-bg)" : "none",
                        }}
                      >
                        <g clipPath="url(#clip0_415_1228)">
                          <path
                            d="M15 6H7.5C6.67157 6 6 6.67157 6 7.5V15C6 15.8284 6.67157 16.5 7.5 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V7.5C16.5 6.67157 15.8284 6 15 6Z"
                            stroke="#30F1B6"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3 12C2.175 12 1.5 11.325 1.5 10.5V3C1.5 2.175 2.175 1.5 3 1.5H10.5C11.325 1.5 12 2.175 12 3"
                            stroke="#30F1B6"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_415_1228">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                    {/* <a href="#">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.797 6.60572L13.6432 10.9692C13.5133 11.1057 13.3372 11.1824 13.1534 11.1824C12.9697 11.1824 12.7936 11.1057 12.6637 10.9692C12.5338 10.8328 12.4608 10.6477 12.4608 10.4547C12.4608 10.2617 12.5338 10.0766 12.6637 9.94018L15.6362 6.81844H12.2016C10.5129 6.81795 8.87207 7.40707 7.53697 8.49316C6.20186 9.57926 5.24826 11.1007 4.8261 12.8183C4.7802 13.0051 4.66552 13.1652 4.50729 13.2632C4.34907 13.3612 4.16025 13.3892 3.98238 13.341C3.80451 13.2928 3.65216 13.1723 3.55885 13.0061C3.46553 12.8399 3.4389 12.6415 3.4848 12.4547C3.98291 10.4243 5.10973 8.62562 6.68783 7.3419C8.26592 6.05819 10.2056 5.36234 12.2016 5.36393H15.6379L12.6637 2.24219C12.5993 2.17462 12.5483 2.09441 12.5135 2.00612C12.4787 1.91784 12.4608 1.82322 12.4608 1.72766C12.4608 1.6321 12.4787 1.53748 12.5135 1.4492C12.5483 1.36091 12.5993 1.2807 12.6637 1.21313C12.7936 1.07666 12.9697 1 13.1534 1C13.2444 1 13.3345 1.01882 13.4185 1.05539C13.5026 1.09196 13.5789 1.14556 13.6432 1.21313L17.797 5.57665C17.8613 5.64419 17.9124 5.7244 17.9472 5.81269C17.9821 5.90098 18 5.99561 18 6.09118C18 6.18676 17.9821 6.28139 17.9472 6.36968C17.9124 6.45797 17.8613 6.53818 17.797 6.60572ZM14.538 15.5455H1.38457V4.63668C1.38457 4.4438 1.31164 4.25882 1.18181 4.12243C1.05198 3.98604 0.875893 3.90942 0.692287 3.90942C0.508681 3.90942 0.332595 3.98604 0.202766 4.12243C0.0729371 4.25882 0 4.4438 0 4.63668V16.2727C0 16.4656 0.0729371 16.6506 0.202766 16.787C0.332595 16.9234 0.508681 17 0.692287 17H14.538C14.7216 17 14.8977 16.9234 15.0275 16.787C15.1574 16.6506 15.2303 16.4656 15.2303 16.2727C15.2303 16.0799 15.1574 15.8949 15.0275 15.7585C14.8977 15.6221 14.7216 15.5455 14.538 15.5455Z"
                    fill="#30F1B6"
                  />
                </svg>
              </a> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Completed;
