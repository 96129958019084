import React, { useEffect, useState } from "react";
import "./gemscommunity.scss";
import Navbar from "../landing/header/Navbar";
import { Link } from "react-router-dom";
import Timer from "./Timer";
import axios from "axios";
import { API_URL_SURVEY } from "../../utils/ApiUrl";
import Loader from "../../hooks/loader";
import ClaimButton from "./Timer";
import useAuth from "../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
const GemsCommunityProject = () => {
  let { account, chainId } = useWeb3React();
  const { login, logout } = useAuth();
  const [checked, setChecked] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  if (id) {
    localStorage.setItem("referalId", id);
  }
  console.log(id);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const [checked1, setChecked1] = useState(false);
  // const [participants, setParticipants] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange1 = () => {
    setChecked1(!checked1);
  };

  // const getStats = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(`${API_URL_SURVEY}/users/stats`);
  //     console.log(response);
  //     setParticipants(response?.data?.data?.participants);
  //   } catch (error) {
  //     if (error?.response) {
  //       console.error("Error fetching stats:", error?.response?.data?.message);
  //     } else {
  //       console.error("Error fetching stats:", error?.message);
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getStats();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isDisabled = !(checked && checked1);

  const [pageLoaded, setPageLoaded] = useState(false);

  console.log("page loaded", pageLoaded);

  useEffect(() => {
    setPageLoaded(true);

    return () => {
      setPageLoaded(false);
    };
  }, []);

  const logouthandler = async () => {
    const connectorId = window.localStorage.getItem("connectorId");
    await logout(connectorId);
    localStorage.removeItem("connectorId");
    localStorage.removeItem("flag");
  };
  useEffect(() => {
    if (account) {
      logouthandler();
    }
  }, [account]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar pageLoaded={pageLoaded} />
          <section className="gems-community">
            <img
              src="\assets\gemscommunityproject\layer-bg.png"
              alt="img"
              className="img-fluid bg-shadow hide-in-light-theme"
            />
            <img
              src="\assets\gemscommunityproject\layer-bg-light.png"
              alt="img"
              className="img-fluid bg-shadow d-none show-in-light-theme"
            />
            <video
              className="main-banner-video"
              muted="muted"
              playsinline="playsinline"
              autoPlay
              loop
              width="100%"
              id="myVideo"
            >
              <source
                src="\assets\gemscommunityproject\video-bg.mp4"
                type="video/mp4"
              />
            </video>
            <div className="custom-container">
              <div className="parent">
                <div className="main-heading">
                  <h5>
                    <span>Gems Community Project</span>
                  </h5>
                  <h4>Welcome to this thing we’re doing together!</h4>
                </div>
                {/* <div className="inline-elem">
                            <div className="single-elem">
                                <p>Total Tasks</p>
                                <h6>6</h6>
                            </div>
                            <div className="single-elem">
                                <p>Participants</p>
                                <h6>1.4k</h6>
                            </div>
                            <div className="single-elem">
                                <p>Reward</p>
                                <h6>0.5% of supply</h6>
                            </div>
                        </div> */}
                <div className="mid-para">
                  <h6>We are building a community project together!</h6>
                  <p>
                    Every step you take in this quest, will impact the outcome
                    for the entire community and its token &#129309;
                  </p>
                  {/* <h6>Ready to begin?</h6> */}
                </div>
                <div className="bottom-agreement">
                  <label className="custom-checkbox-container">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                    <span className="checkbox-label">
                      I have read and agree with{" "}
                      <span style={{ fontFamily: "hsemibold" }}>Airdrop </span>
                      <Link to="/termscommunityproject" target="_blank">
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacy" target="_blank">
                        Privacy Policy.
                      </Link>{" "}
                    </span>
                  </label>
                  <label className="custom-checkbox-container">
                    <input
                      type="checkbox"
                      checked={checked1}
                      onChange={handleCheckboxChange1}
                    />
                    <span className="checkmark"></span>
                    <span className="checkbox-label">
                      I agree to receive communication and marketing emails from
                      Gems.
                    </span>
                  </label>
                </div>
                <Link
                  to={{ pathname: "/survey", state: { id: id } }}
                  className={`btn-green ${isDisabled ? "disabled" : ""}`}
                  aria-disabled={isDisabled} // Optional: Adds semantic accessibility for disabled state
                >
                  Start Quest
                </Link>
                <div className="bottom-timer">
                  {/* <h6 className="endsin-text">Ends In</h6> */}
                  {/* <div className="timer">
                <div className="single">
                  <h6>02</h6>
                  <p>Days</p>
                </div>
                <span>:</span>
                <div className="single">
                  <h6>23</h6>
                  <p>Hours</p>
                </div>
                <span>:</span>
                <div className="single">
                  <h6>15</h6>
                  <p>MINUTES</p>
                </div>
                <span>:</span>
                <div className="single">
                  <h6>45</h6>
                  <p>Seconds</p>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default GemsCommunityProject;
