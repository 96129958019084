import React, { useState } from 'react'
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap';

const Information = () => {
    const { t } = useTranslation()

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>{t('Dop-detail4')} <span></span></h2>
                    <p>Olympus AI is revolutionizing the AI landscape with a comprehensive framework that transforms how AI agents are created, deployed and monetized.
                    </p>
                    <p>Our platform democratizes AI development through an intuitive environment that empowers both developers and non-technical users to build sophisticated AI agents. These agents are automatically tokenized and can execute complex tasks across multiple platforms, creating immediate value for their creators.</p>
                    <p>At the heart of our ecosystem lies the $OAI token, which powers a unique AI-driven economy. The platform automatically assigns each AI agent its own tradeable token, exclusively paired with $OAI. This creates a self-sustaining ecosystem where developers can instantly monetize their creations while $OAI holders benefit from staking rewards, trading fees, and governance rights. As all agent tokens must trade through $OAI, the token's value naturally appreciates with ecosystem growth.</p>
                </div>
                <div className="first-para">
                    <h2>WHAT MAKES OLYMPUS AI UNIQUE <span></span></h2>
                    <ol>
                        <li><span>Unified Development Environment:</span>  We've reimagined AI development by creating a seamless ecosystem that combines visual and code-based approaches. Our intuitive studio provides all essential tools in one cohesive platform, dramatically reducing the complexity of AI agent creation.</li>
                        <li><span>Revolutionary Token Economics:</span>  Our innovative economic model positions $OAI as the exclusive trading pair for all platform-created AI agent tokens. This strategic architecture ensures sustained demand and trading volume, while creating multiple revenue streams through trading fees, staking rewards, and natural value appreciation.</li>
                        <li><span>Cross-Platform Integration:</span>  Deploy AI agents effortlessly across social media, messaging services, and blockchain networks. Our built-in analytics dashboard provides real-time insights into agent performance across all platforms, enabling data-driven optimization.                        </li>
                        <li><span>AI Agent Marketplace:</span>  Our dynamic marketplace connects developers with users seeking specialized AI solutions, featuring comprehensive agent profiles, verified user reviews, and quality metrics. This creates a thriving ecosystem where developers can showcase and monetize their innovations while users easily discover the perfect AI agents for their needs.</li>
                        <li><span>Market-Ready Implementation:</span>  We're launching with Satoshi GPT, an advanced crypto intelligence platform that showcases our ecosystem's potential. This flagship AI agent demonstrates how developers can create specialized, value-generating applications while contributing to ecosystem growth and $OAI holder value.</li>
                    </ol>
                </div>
                <div className="first-para">
                    <h2>REVOLUTIONIZING AI AGENT DEVELOPMENT <span></span></h2>
                    <ul>
                        <li><span>Instant Value Creation:</span>  Our platform's automatic token generation for new AI agents creates immediate monetization opportunities while expanding the $OAI ecosystem through consistent trading volume and market activity.</li>
                        <li><span>Mass Market Potential:</span>  By eliminating technical barriers through intuitive visual tools, we're opening AI development to a vast new audience of creators, dramatically expanding our potential market reach.</li>
                        <li><span>Enterprise Adoption:</span>  Our ready-made solutions enable businesses to rapidly deploy custom AI agents tailored to their specific needs, tapping into the massive corporate AI market.</li>
                        <li><span>Income Generation for $OAI Investors:</span>  The platform's architecture ensures multiple revenue streams for $OAI holders through trading fees, staking rewards, and governance participation, all growing proportionally with ecosystem expansion.</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>THE FUTURE OF AI DEVELOPMENT IS HERE                     <span></span></h2>
                    <p>Olympus AI is much more than an AI platform – it's a paradigm shift in AI agent development and monetization. </p>
                    <p>Our groundbreaking approach to automated agent tokenization, paired with $OAI's central role, creates a unique investment opportunity in the rapidly expanding AI sector.</p>
                    <p>As our ecosystem grows through increased agent creation and adoption, value naturally flows to $OAI holders through multiple capture mechanisms.</p>
                    <p>By establishing $OAI as the cornerstone of this new AI token economy, we're building a sustainable, expanding ecosystem where innovation drives direct returns for $OAI holders.</p>
                </div>
                {/* <div className="first-para">
                    <h2>OLYMPUS AI'S GO-TO-MARKET STRATEGY <span></span></h2>
                    <p>Olympus AI will drive user growth and platform adoption through a multi-faceted approach
                        combining community engagement, strategic partnerships, and performance marketing. By
                        building a strong community with AMAs, webinars, and product demos, collaborating with
                        leading AI and blockchain companies, and employing targeted digital marketing
                        campaigns, the platform will attract high-intent users. Incentive programs such as staking
                        rewards and a referral program will further boost engagement, while continuous innovation
                        and expansion of AI capabilities will ensure long-term relevance and scalability.</p>
                </div> */}
                <div className="first-para">
                    <h2>The Team <span></span></h2>
                    <div className="team-gems">
                        <div className="single-team">
                            <img src="\assets\alldetailpages\olympasdetailpage\banner\team\mor.jpg" alt="img" className='img-fluid' />
                            <h6>Mor Hogeg </h6>
                            <h5>General Manager</h5>
                            <p>A blockchain researcher and attorney with over seven years of experience, Mor has been deeply immersed in artificial intelligence technology for several years. Mor leverages this unique blend of expertise to guide Olympus AI's product development and strategic direction, steering the platform towards innovative AI solutions.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\olympasdetailpage\banner\team\lotem.jpg" alt="img" className='img-fluid' />
                            <h6>Lotem Elazar </h6>
                            <h5>Developer</h5>
                            <p>Lotem is a versatile AI prompt engineer and developer with deep expertise in React, Node.js, and MongoDB, and experience in creating scalable and efficient applications. With a growing interest and experience in Web3 technologies, Lotem is committed to staying current with emerging trends, continually sharpening his skill set to deliver innovative and future-ready solutions.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\alldetailpages\olympasdetailpage\banner\team\omri.jpg" alt="img" className='img-fluid' style={{ objectPosition: "50%" }} />
                            <h6>Omri Bakish  </h6>
                            <h5>Developer</h5>
                            <p>A seasoned React and Node.js developer with 4 years of hands-on experience in building scalable applications using React, React Native, Node APIs, and Redux. Omri is a skilled prompt engineer who is passionate about continuous learning, always striving to refine skills and stay ahead of industry trends, ensuring the delivery of cutting-edge solution</p>
                        </div>
                    </div>

                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\alldetailpages\olympasdetailpage\banner\olympus-tokenomics-darkn.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                            <img src="\assets\alldetailpages\olympasdetailpage\banner\olympus-tokenomicsn.png" alt="img" className='img-fluid hide-in-light-theme' />
                        </div>
                    </div>
                </div>
                <div className="first-para">
                    <h2>{t('Distandallocation')} <span></span></h2>
                    <div onClick={() => { setShow1(true) }} className="twice-tokenomics" style={{ cursor: "pointer" }}>
                        <div className="tokenomic-img">
                            <img src="\assets\alldetailpages\olympasdetailpage\banner\olympus-distribution-darkn.png" alt="img" className='img-fluid hide-in-light-theme' />
                            <img src="\assets\alldetailpages\olympasdetailpage\banner\olympus-distributionn.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                        </div>
                    </div>

                </div>


            </section>

            <Modal className='common-modal-style image-preview distribution-modl' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                    <img src="\assets\alldetailpages\olympasdetailpage\banner\olympus-distribution-dark.png" alt="img" className='img-fluid hide-in-light-theme' />
                    <img src="\assets\alldetailpages\olympasdetailpage\banner\olympus-distribution.png" alt="img" className='img-fluid d-none show-in-light-theme' />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Information
