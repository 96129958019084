import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import ReactPaginate from "react-paginate";

const PurchaseModal = ({ page, pageCount, handlePageChange, arryy, count }) => {
  return (
    <section className="datatable">
      <div className="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>
                <div className="parent-tag">Purchased on</div>
              </th>
              <th>
                <div className="parent-tag">PURCHASE PRICE</div>
              </th>
              <th>
                <div className="parent-tag">Amount Spent</div>
              </th>
              <th>


                <div className="parent-tag">
                  GEMS PURCHASED
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>02:46 25/06/23</td>
              <td>$0.01/GEMS</td>
              <td>2.36 ETH</td>
              <td>426.249 GEMS</td>
            </tr>

          </tbody>
        </table>
        <div className="footer-content">
          <div className="left-f">   <h6>  {" "}
            {count > 0 && count <= 5 ? (
              <>
                <p>
                  SHOWING 1-{count} OF {count}
                </p>
              </>
            ) : count > 5 ? (
              <>
                <p>
                  SHOWING {page > 0 ? page * 5 + 1 : page + 1} -
                  {5 * (page + 1) > count ? count : 5 * (page + 1)} OF{" "}
                  {count}
                </p>
              </>
            ) : (
              <>
                <p>SHOWING 0 OF 0</p>
              </>
            )}
          </h6> </div>
          <div className="right-f">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <nav aria-label="Page navigation example">
                  <ul class="text-center">
                    <ReactPaginate
                      previousLabel={
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <g opacity="0.5">
                            <path d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z" fill="#939FA3" />
                            <path d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z" fill="#939FA3" />
                            <path d="M8.01649 6.85742H14.9615L13.6868 9.14313H6.74176L8.01649 6.85742Z" fill="#939FA3" />
                          </g>
                        </svg>
                      }
                      nextLabel={
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z" fill="#30F1B6" />
                          <path d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z" fill="#30F1B6" />
                          <path d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z" fill="#30F1B6" />
                        </svg>
                      }
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      forcePage={page}
                    ></ReactPaginate>
                  </ul>
                </nav>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="accmblview d-none">
        <div className="main-twice-text">
          <h6 className="accmblviewhead">Purchased on</h6>
        </div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="accheadermain">
                <p className="acctext">02:46 25/06/23</p>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="acctexts">
                <h6 className="textleft">PURCHASE PRICE</h6>
                <p className="textright">$$0.01/GEMS</p>
              </div>
              <div className="acctexts">
                <h6 className="textleft">Amount Spent</h6>
                <p className="textright">2.36 ETH</p>
              </div>
              <div className="acctexts">
                <h6 className="textleft">GEMS PURCHASED</h6>
                <p className="textright">426.249 GEMS</p>
              </div>
            </Accordion.Body>
          </Accordion.Item>

        </Accordion>

        <div className="footer-content">
          <div className="left-f">  <h6>     {" "}
            {count > 0 && count <= 5 ? (
              <>
                <p>
                  SHOWING 1-{count} OF {count}
                </p>
              </>
            ) : count > 5 ? (
              <>
                <p>
                  SHOWING {page > 0 ? page * 5 + 1 : page + 1} -
                  {5 * (page + 1) > count ? count : 5 * (page + 1)} OF{" "}
                  {count}
                </p>
              </>
            ) : (
              <>
                <p>SHOWING 0 OF 0</p>
              </>
            )}</h6></div>
          <div className="right-f">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <nav aria-label="Page navigation example">
                  <ul class="text-center">
                    <ReactPaginate
                      previousLabel={
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <g opacity="0.5">
                            <path d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z" fill="#939FA3" />
                            <path d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z" fill="#939FA3" />
                            <path d="M8.01649 6.85742H14.9615L13.6868 9.14313H6.74176L8.01649 6.85742Z" fill="#939FA3" />
                          </g>
                        </svg>
                      }
                      nextLabel={
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z" fill="#30F1B6" />
                          <path d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z" fill="#30F1B6" />
                          <path d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z" fill="#30F1B6" />
                        </svg>
                      }
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      forcePage={page}
                    ></ReactPaginate>
                  </ul>
                </nav>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PurchaseModal;
