import React, { useEffect, useState } from "react";
import "./gemspurchases.scss"
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import { useParams } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import axios from 'axios';
const AllPurchasesPremium = () => {
    const { id } = useParams();
    // console.log("name here is ", name)
    const { account } = useWeb3React();
    const [projectdetail, setProjectDetail] = useState(null)
    const getAllProjectsData = async () => {
        // console.log("in here ", name)
        var config = {
            method: "get",
            url: `http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:8007/users/premium/buyer-all-purchases?by=${account.toLowerCase()}&limit=20&offset=1&privateSale=${id}`,
            headers: {
                // authorization: `Bearer ${tok}`
            },
        };

        axios(config)
            .then(function (response) {
                setProjectDetail(response?.data?.data)
                // console.log("+++++++++++++++++++", response?.data?.data?.buyerAllTransationsOfSpecificProject)

            })
            .catch(function (error) {
                console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        // console.log("in here ")

        if (id && account)
            // console.log("in here ")

            getAllProjectsData();
    }, [id, account])
    return (
        <>
            <Navbar />
            <section className="all-purchases">
                <div className="custom-container">
                    <div className="main-heading">
                        <p>MY PURCHASES</p>
                        <div className="twice-elem">
                            <img width={24} src="\assets\alldetailpages\olympasdetailpage\info\token-logo.png" alt="img" className='img-fluid' />
                            <h5>Olympus AI</h5>
                        </div>
                    </div>
                    <div className="claim-box">
                        <div className="left-side">
                            <div className="inside-left">
                                <svg className='hide-in-light-theme' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M13.1293 18.9613C10.2194 20.6414 6.49849 19.6444 4.81844 16.7344C3.13839 13.8245 4.1354 10.1036 7.04534 8.42354C9.95527 6.74349 13.6762 7.74051 15.3562 10.6504C17.0363 13.5604 16.0393 17.2813 13.1293 18.9613Z" stroke="#D1DADD" stroke-width="1.62529" />
                                    <path d="M10.3085 5.35899C12.9006 3.22095 16.7351 3.58905 18.8732 6.18117C21.0112 8.77328 20.6431 12.6078 18.051 14.7459" stroke="#D1DADD" stroke-width="1.62529" />
                                    <rect x="10.0502" y="11.0635" width="3.816" height="3.816" transform="rotate(45 10.0502 11.0635)" fill="#D1DADD" />
                                </svg>
                                <svg className='d-none show-in-light-theme' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M13.1295 18.9613C10.2195 20.6414 6.49861 19.6444 4.81856 16.7344C3.13851 13.8245 4.13553 10.1036 7.04546 8.42354C9.95539 6.74349 13.6763 7.74051 15.3564 10.6504C17.0364 13.5604 16.0394 17.2813 13.1295 18.9613Z" stroke="#02171D" stroke-width="1.62529" />
                                    <path d="M10.3086 5.35899C12.9007 3.22095 16.7353 3.58905 18.8733 6.18117C21.0113 8.77328 20.6432 12.6078 18.0511 14.7459" stroke="#02171D" stroke-width="1.62529" />
                                    <rect x="10.0503" y="11.0635" width="3.816" height="3.816" transform="rotate(45 10.0503 11.0635)" fill="#02171D" />
                                </svg>
                                <div className="right-text">
                                    <p>Tokens Purchased</p>
                                    <h5>{projectdetail?.totalTokens || projectdetail?.totalNftToken ?  (projectdetail?.totalTokens + projectdetail?.totalNftToken)?.toLocaleString() : "0"} OAI <span>+0.8%</span></h5>
                                </div>
                            </div>
                            <div className="bottom-inline">
                                <p><span></span> {projectdetail?.totalTokens?.toLocaleString()} Tokens</p>
                                <p><span></span> {projectdetail?.totalNftToken?.toLocaleString()} NFTs</p>
                            </div>
                        </div>
                        <div className="right-side">
                            {/* <p>Claim are now open for $OAI</p>
                            <Link to="/claim">Claim All $OAI</Link> */}
                            <p>Claim are now open for $OAI</p>
                            <Link to="/claim">Claim All $OAI</Link>
                        </div>
                    </div>
                    <div className="custom-table">
                        <div class="table-responsive">
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th>Purchased on</th>
                                        <th>PURCHASE PRICE</th>
                                        <th>Amount Spent</th>
                                        <th>Token PURCHASED</th>
                                        <th>TYPE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projectdetail?.buyerAllTransationsOfSpecificProject?.map((project, index) => (
                                        <tr key={index}>
                                            <td>
                                                02:46 25/04/24
                                            </td>
                                            <td>${project?.price}/OAI</td>
                                            <td>{project?.totalSaleConverted?.toLocaleString()} {project?.tokenSelected}</td>
                                            <td>{project?.dopPurchased.toLocaleString()} GEMS</td>
                                            <td>{project?.type}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        <div className="custom-pagination">
                            <p>SHOWING 1-10 OF 145</p>
                            <ul class="pagination">
                                <li class="page-item"><a class="page-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <g opacity="0.5">
                                        <path d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z" fill="#939FA3" />
                                        <path d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z" fill="#939FA3" />
                                        <path d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z" fill="#939FA3" />
                                    </g>
                                </svg></a></li>
                                <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">4</a></li>
                                <li class="page-item"><a class="page-link" href="#">5</a></li>
                                <li class="page-item"><a class="page-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z" fill="#30F1B6" />
                                    <path d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z" fill="#30F1B6" />
                                    <path d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z" fill="#30F1B6" />
                                </svg></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllPurchasesPremium
