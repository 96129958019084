import React, { useEffect, useState } from "react";
import VerifyEmail from "./VerifyEmail";
import Navbar from "../landing/header/Navbar";
import ConnecrTwitter from "./ConnecrTwitter";
import SurveyQuestion from "./SurveyQuestion";
import SocialTask from "./SocialTask";
import Refferal from "./Refferal";
import ConnectWallet from "./ConnectWallet";
// import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL_SURVEY } from "../../utils/ApiUrl";
import Loader from "../../hooks/loader";

const TakeSurvey = () => {
  const [steps, setSteps] = useState(0);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  // const location = useLocation();
  // // Extract the id from location.state
  // const { id } = location.state || {};

  const getStats = async (accessToken) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL_SURVEY}/users`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setUserData(response?.data?.data);
    } catch (err) {
      console.log("error getting user stats");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      getStats(token);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />

          <section className="survey-section">
            <div className="custom-container">
              <div className="steps-survey">
                <div
                  className={
                    steps == 0 ||
                    steps == 1 ||
                    steps == 2 ||
                    steps == 3 ||
                    steps == 4 ||
                    steps == 5
                      ? "single-step active"
                      : "single-step"
                  }
                >
                  <span>1</span>
                  <p>Connect Wallet</p>
                </div>
                <span
                  className={
                    steps == 0 ||
                    steps == 1 ||
                    steps == 2 ||
                    steps == 3 ||
                    steps == 4 ||
                    steps == 5
                      ? "line active"
                      : "line"
                  }
                ></span>
                <div
                  className={
                    steps == 1 ||
                    steps == 2 ||
                    steps == 3 ||
                    steps == 4 ||
                    steps == 5
                      ? "single-step active"
                      : "single-step"
                  }
                >
                  <span>2</span>
                  <p>Verify Email</p>
                </div>
                <span
                  className={
                    steps == 1 ||
                    steps == 2 ||
                    steps == 3 ||
                    steps == 4 ||
                    steps == 5
                      ? "line active"
                      : "line"
                  }
                ></span>
                <div
                  className={
                    steps == 2 || steps == 3 || steps == 4 || steps == 5
                      ? "single-step active"
                      : "single-step"
                  }
                >
                  <span>3</span>
                  <p>Connect X</p>
                </div>
                <span
                  className={
                    steps == 2 || steps == 3 || steps == 4 || steps == 5
                      ? "line active"
                      : "line"
                  }
                ></span>
                <div
                  className={
                    steps == 3 || steps == 4 || steps == 5
                      ? "single-step active"
                      : "single-step"
                  }
                >
                  <span>4</span>
                  <p>Complete Survey</p>
                </div>
                <span
                  className={
                    steps == 3 || steps == 4 || steps == 5
                      ? "line active"
                      : "line"
                  }
                ></span>
                <div
                  className={
                    steps == 4 || steps == 5
                      ? "single-step active"
                      : "single-step"
                  }
                >
                  <span>5</span>
                  <p>Social Tasks</p>
                </div>
                <span
                  className={steps == 4 || steps == 5 ? "line active" : "line"}
                ></span>
                <div
                  className={steps == 5 ? "single-step active" : "single-step"}
                >
                  <span>6</span>
                  <p>Referral</p>
                </div>
              </div>
              <div className="bottom-content">
                {steps == 0 ? (
                  <ConnectWallet
                    setSteps={setSteps}
                    setUserData={setUserData}
                  />
                ) : steps == 1 ? (
                  <VerifyEmail setSteps={setSteps} userData={userData} />
                ) : steps == 2 ? (
                  <ConnecrTwitter setSteps={setSteps} userData={userData} />
                ) : steps == 3 ? (
                  <SurveyQuestion setSteps={setSteps} userData={userData} />
                ) : steps == 4 ? (
                  <SocialTask setSteps={setSteps} userData={userData} />
                ) : steps == 5 ? (
                  <Refferal setSteps={setSteps} userData={userData} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default TakeSurvey;
