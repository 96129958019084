import React, { useEffect, useState } from 'react'
import Navbar from '../landing/header/Navbar'
import Banner from './Banner/Banner'
import Nodes from './Nodes/Nodes'
import ProjectMiners from './ProjectMiners/ProjectMiners'
import HowItWorks from './HowItWorks/HowItWorks'
import Faqs from './Faqs/Faqs'
import Join from './Join/Join'
import Loader from '../../hooks/loader'

const NodesMinersLanding = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isFirstVisitLoader = localStorage.getItem("firstVisitLoader");

    if (isFirstVisitLoader) {
      setLoading(false);
    } else {

      localStorage.setItem("firstVisitLoader", "true");
      const timer = setTimeout(() => {
        setLoading(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);
  return (
    <>
      {
        loading && <Loader />
      }

      <div style={window.innerWidth < 600 ? {overflow: "hidden"} : {}}>
      <Navbar />
      <Banner />
      <Nodes />
      <ProjectMiners />
      <HowItWorks />
      <Faqs />
      <Join />
      </div>
    </>
  )
}

export default NodesMinersLanding
