import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { API_URL_SURVEY } from "../../utils/ApiUrl";
import { DragHandle } from "@material-ui/icons";
import Loader from "../../hooks/loader";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";

const SocialTask = ({ setSteps, userData }) => {
  if (userData?.isSocialTaskCompleted) {
    setSteps(5);
  }

  const token = localStorage.getItem("accessToken");
  // console.log("Access token", token);
  const { account } = useWeb3React();

  if (token && account) {
    const signerAccount = localStorage.getItem("signerAccount");
    if (signerAccount !== account) {
      setSteps(0);
    }
  } else if (!token || !account) {
    setSteps(0);
  }
  const referalLink = `https://gems.vip/gemscommunityproject/?id=${userData?.referralCode}`;
  const linkText = `No time to explain, go to ${referalLink},\ntake the quiz and thank me later!
Ok I can explain a little - it’s @WeMakeIt2Gether's survey and I need you there with me. You\n in? There’s a \u{2728}reward\u{2728} at the end`;

  const twiterPost = async () => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `${linkText} `
    )}`;
    window.open(tweetUrl);
    updateSocialTasksStatus("postOnX");
  };

  const [user, setUser] = useState(userData);
  const [loading, setLoading] = useState(false);
  const [followGemsOnX, setFollowGemsOnX] = useState(
    userData?.followGemsOnTwitter
  );
  const [postOnX, setPostOnX] = useState(userData?.postOnTwitter);
  const [followGemsCommunityOnX, setFollowGemsCommunityOnX] = useState(
    userData?.followGemsCommunityOnTwitter
  );
  const [followOnTelegram, setFollowOnTelegram] = useState(
    userData?.followUsOnTelegram
  );

  const updateSocialTasksStatus = async (boolToUpdate) => {
    let payload;

    if (boolToUpdate === "followGemsOnX") {
      payload = { followGemsOnTwitter: true };
    } else if (boolToUpdate === "postOnX") {
      payload = { postOnTwitter: true };
    } else if (boolToUpdate === "followGemsCommunityOnX") {
      payload = { followGemsCommunityOnTwitter: true };
    }

    const accessToken = localStorage.getItem("accessToken");
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_URL_SURVEY}/users/social-quest`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      // if (response) {
      //   window.location.reload();
      // }
      if (boolToUpdate === "followGemsOnX") {
        setFollowGemsOnX(true);
      } else if (boolToUpdate === "postOnX") {
        setPostOnX(true);
      } else if (boolToUpdate === "followGemsCommunityOnX") {
        setFollowGemsCommunityOnX(true);
      }
    } catch (e) {
      if (e?.response) console.error(e?.response);
      else console.error(e);
    } finally {
      setLoading(false);
    }
  };

  //////////////   TELEGRAM     //////////////

  const [id, setId] = useState("");
  const telegramWrapperRef = useRef(null);

  useEffect(() => {
    if (telegramWrapperRef?.current) {
      const scriptElement = document.createElement("script");
      scriptElement.src = "https://telegram.org/js/telegram-widget.js?22";
      scriptElement.setAttribute(
        "data-telegram-login",
        "GemsCommunityProject_bot"
      ); ///usama
      scriptElement.setAttribute("data-size", "large");
      scriptElement.setAttribute("data-auth-url", "https://gems.vip/survey"); //live ka link
      scriptElement.async = true;

      telegramWrapperRef.current?.appendChild(scriptElement);
    }
  }, [telegramWrapperRef]);

  const searchParams = useMemo(() => {
    if (typeof window !== "undefined") {
      return new URLSearchParams(window.location.search);
    }
    return new URLSearchParams(); // Returns a blank set if not on client side
  }, []);

  useEffect(() => {
    setId(searchParams.get("id"));
  }, [searchParams]);

  useEffect(() => {
    if (id) {
      toggleTelegram();
    }
  }, [id]);

  const toggleTelegram = async () => {
    let tok = localStorage.getItem("accessToken");
    const postData = {
      telegramId: id,
    };
    axios
      .post(`${API_URL_SURVEY}/users/toggle-telegram`, postData, {
        headers: {
          Authorization: `Bearer ${tok}`, // Include your authentication token here
        },
      })
      .then(async (response) => {
        let headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tok}`, // Include your authentication token here
        };
        axios
          .get(`${API_URL_SURVEY}/users`, { headers })
          .then((response) => {
            // Handle the success of the GET request
            setUser(response?.data?.data);
            setFollowOnTelegram(response?.data?.data?.followUsOnTelegram);
            setFollowGemsOnX(response?.data?.data?.followGemsOnTwitter);
            setFollowGemsCommunityOnX(
              response?.data?.data?.followGemsCommunityOnTwitter
            );
            setPostOnX(response?.data?.data?.postOnTwitter);
          })
          .catch((error) => {
            // Handle errors that occurred during the GET request
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        localStorage.removeItem("telegramJoined");
        setVerifyTelegram(false);

        if (
          error?.response?.data?.message !==
          "The Telegram account you are trying to log in to is affiliated with another Gems account"
        ) {
          // setTelegrampop(true);
        }
        console.error("Error2:", error);
      });
  };

  //////////////////////

  const [verifyTelegram, setVerifyTelegram] = useState(
    localStorage.getItem("telegramJoined")
  );

  // const verifyTelegram1 = localStorage.getItem("telegramJoined");

  // useEffect(() => {
  //   if (verifyTelegram1) {
  //     setVerifyTelegram(true);
  //   }
  // }, [verifyTelegram1]);

  const handleJoinTelegramClick = () => {
    localStorage.setItem("telegramJoined", true);
    setVerifyTelegram(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Get the current URL's path
    const currentPath = window.location.pathname;

    // Desired path to update to
    const desiredPath = "/survey";

    if (currentPath !== desiredPath) {
      // Update the path without changing the origin
      window.history.replaceState({}, document.title, desiredPath);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="social-task">
          <div className="main-heading">
            <h4>
              Remember facebook? <br />
              <span>neither do we</span>
            </h4>
          </div>
          <div className="bottom-social-detail">
            <h5 className="head-text">Complete Social Engagement Tasks</h5>
            <div className="parent">
              <div className="single-task">
                <div className="left-text">
                  <h5>Follow Gems on X</h5>
                  <p>
                    Our system will capture X snapshots to ensure users follow
                    the required profiles.
                  </p>
                </div>
                {followGemsOnX ? (
                  <button className="btn-joined">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="none"
                    >
                      <path
                        d="M11.553 4.4345L11.553 0.0893555L4.93276 6.69304L0.986084 3.90871L0.986084 7.93699L4.93275 10.9109L11.553 4.4345Z"
                        fill="#30F1B6"
                      />
                    </svg>
                    Followed
                  </button>
                ) : (
                  <button
                    className="btn-green"
                    onClick={() => {
                      updateSocialTasksStatus("followGemsOnX");
                      window.open("https://x.com/Gems_VIP_");
                    }}
                  >
                    Follow
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M2.25 10.25L9.75 2.75M9.75 2.75H4.125M9.75 2.75V8.375"
                        stroke="#02171D"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div className="single-task">
                <div className="left-text">
                  <h5>Post about it on X</h5>
                  <p>
                    Our system will capture X snapshots to ensure users posted
                    the required content.
                  </p>
                </div>
                {postOnX ? (
                  <button className="btn-joined">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="none"
                    >
                      <path
                        d="M11.553 4.4345L11.553 0.0893555L4.93276 6.69304L0.986084 3.90871L0.986084 7.93699L4.93275 10.9109L11.553 4.4345Z"
                        fill="#30F1B6"
                      />
                    </svg>
                    Tweeted
                  </button>
                ) : (
                  <button
                    className="btn-green"
                    onClick={() => {
                      twiterPost();
                    }}
                  >
                    Tweet{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M2.25 10.25L9.75 2.75M9.75 2.75H4.125M9.75 2.75V8.375"
                        stroke="#02171D"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div className="single-task">
                <div className="left-text">
                  <h5>Follow the Community Project’s X account</h5>
                  <p>
                    Twitter accounts must be at least 1 month old to participate
                  </p>
                </div>
                {followGemsCommunityOnX ? (
                  <button className="btn-joined">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="none"
                    >
                      <path
                        d="M11.553 4.4345L11.553 0.0893555L4.93276 6.69304L0.986084 3.90871L0.986084 7.93699L4.93275 10.9109L11.553 4.4345Z"
                        fill="#30F1B6"
                      />
                    </svg>
                    Followed
                  </button>
                ) : (
                  <button
                    className="btn-green"
                    onClick={() => {
                      updateSocialTasksStatus("followGemsCommunityOnX");
                      window.open("https://x.com/WeMakeIt2Gether");
                    }}
                  >
                    Follow
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M2.25 10.25L9.75 2.75M9.75 2.75H4.125M9.75 2.75V8.375"
                        stroke="#02171D"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div className="single-task">
                <div className="left-text">
                  <h5>Join the Community Project Telegram group</h5>
                  <p>
                    Please join our telegram group and make sure to verify to
                    complete <br /> this task
                  </p>
                </div>
                {followOnTelegram ? (
                  <button className="btn-joined">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="none"
                    >
                      <path
                        d="M11.553 4.4345L11.553 0.0893555L4.93276 6.69304L0.986084 3.90871L0.986084 7.93699L4.93275 10.9109L11.553 4.4345Z"
                        fill="#30F1B6"
                      />
                    </svg>
                    Joined
                  </button>
                ) : (
                  <div className="twice-btns">
                    <a
                      className={`btn-green ${
                        verifyTelegram ? "whendisabled" : ""
                      }`}
                      href="https://t.me/WeMakeIt2Gether"
                      target="_blank"
                      // onClick={() => setVerifyTelegram(true)}
                      onClick={handleJoinTelegramClick}
                    >
                      Join
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                      >
                        <path
                          d="M2.25 10.25L9.75 2.75M9.75 2.75H4.125M9.75 2.75V8.375"
                          stroke="#02171D"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <button
                      className={`btn-green btn-telegram ${
                        verifyTelegram ? "" : "whendisabled"
                      }`}
                      // className="btn-green btn-telegram whendisabled"
                      ref={telegramWrapperRef}
                    >
                      Verify
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                      >
                        <path
                          d="M2.25 10.25L9.75 2.75M9.75 2.75H4.125M9.75 2.75V8.375"
                          stroke="#02171D"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg> */}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* followOnTelegram && */}
          {followGemsOnX &&
            postOnX &&
            followGemsCommunityOnX &&
            followOnTelegram && (
              <div className="bottom-detail">
                <h6>
                  Awesome! <br />
                  Off to the last step{" "}
                </h6>
                <div className="navigation-buttons">
                  <button
                    className="btn-green firstbtngreen-btn"
                    onClick={() => {
                      setSteps(5);
                    }}
                  >
                    Next{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                    >
                      <path
                        d="M3.4165 7H11.5832"
                        stroke="#02171D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.5 2.91675L11.5833 7.00008L7.5 11.0834"
                        stroke="#02171D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
        </section>
      )}
    </>
  );
};

export default SocialTask;
