import { initializeConnector } from "@web3-react/core";
// import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";

// import { MAINNET_CHAINS } from "../chains";
// const [mainnet, ...optionalChains] = Object.keys(MAINNET_CHAINS).map(Number);
// const TomiPayBol = localStorage.getItem("IsTomipay");
// let ab = TomiPayBol ==true || TomiPayBol==null ? false : true
// console.log("TomiPayBol",typeof TomiPayBol)
// alert(TomiPayBol)
export const [coinbaseWallet, hooks] = initializeConnector(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: "https://responsive-proportionate-slug.base-mainnet.quiknode.pro/b1f1cd7d806690354d7613da7c4f5a302c39f7c8/",
        appName: "gems",
        supportedChainIds: [8453],
      },
    })
);
