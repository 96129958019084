import React, { useEffect, useState } from 'react'
import "./seeallprojects.scss"
import Navbar from '../landing/header/Navbar'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Presalecards from './PresalesCardAll';
import { useTranslation } from 'react-i18next';
import PresalecardsAll from './PresalesCardAll';
import PresalecardsLive from './PresalesCardLive';
import PresalecardsUpcoming from './PresalesCardUpcoming';
import PresalecardsEnded from './PresalesCardEnded';
import axios from 'axios';
import Loader from "../../hooks/loader";
import Environment from '../../utils/Environment';
import ProcessLaunchpad from '../landing/Process/ProcessLaunchpad';
import { Link } from 'react-router-dom';

const SeeAllProjects = () => {
    const [mainLoader1, setMainLoader1] = useState(false);
    const [mainLoader2, setMainLoader2] = useState(false);
    const [mainLoader3, setMainLoader3] = useState(false);
    console.log(mainLoader1, "mainLoader1mainLoader1mainLoader1mainLoader1");

    const [role, setRole] = useState('all')
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [Allprojectssss, setAllprojectssss] = useState([])

    // console.log("console", Allprojectssss?.leadersCount)
    // console.log("console", Allprojectssss?.totalAmountInUsd)

    const getAllProjectsData = async () => {
        setAllprojectssss([])
        var config = {
            method: "get",
            url: `${Environment?.Api_Url}/users/homepage-stats`,
            headers: {
                // authorization: `Bearer ${tok}`
            },
        };
        axios(config)
            .then(function (response) {
                const data = response.data;
                setAllprojectssss(response?.data?.data)
            })
            .catch(function (error) {
                console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        getAllProjectsData();
        // fetchProjects(currentPage);
    }, []);

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000)?.toFixed(1) + 'M+';
        } else if (num >= 1000) {
            return (num / 1000)?.toFixed(1) + 'k+';
        } else {
            return num?.toString();
        }
    };

    const handleTabSelect = (rolee) => {
        setRole(rolee)
    }
    return (
        <>
            {mainLoader1 && <Loader />}
            {mainLoader2 && <Loader />}
            {mainLoader3 && <Loader />}
            <div className="wrapper-seeallprojects">
                <Navbar />
                <section className="seeallprojects">
                    <img src="\assets\seeallprojects\allprojects-bg.png" alt="img" className='img-fluid allprojects-bg' />
                    <img src="\assets\seeallprojects\allprojects-bg-mblnew.png" alt="img" className='img-fluid allprojects-bg d-none allprojects-bg-mbl' />
                    <video className="main-banner-video"
                        muted="muted" playsinline="playsinline"
                        autoPlay
                        loop
                        id="myVideo">
                        <source src="\assets\seeallprojects\dark-video.mp4" type="video/mp4" />
                    </video>
                    <div className="custom-container">
                        <div className="upper-content">
                            <p className='top-title'>{t('Seeallpro1')}</p>
                            <h5 className='head-text'>{t('Seeallpro2')} <span>{t('Seeallpro3')}</span></h5>
                            <div className="twice-btns">
                                <a href="https://creators.gems.vip/signup" className='btn-green' target='_blank'>Create a Project</a>
                                <Link to="/signupforproject" className='btn-transparent'>Apply for Gems Pro</Link>
                            </div>
                            <div className="user-data">
                                <div className="parent">
                                    <div className="text">
                                        <p>{t('NewBannert4')}</p>
                                        {/* <h5><span>{Allprojectssss?.totalInvestor ? formatNumber(Allprojectssss?.totalInvestor + 2669811) : 0}</span></h5> */}
                                        <h5><span>2.5M+</span></h5>
                                    </div>
                                    <div className="text">
                                        <p>{t('NewBannert5')}</p>
                                        {/* <h5><span>{Allprojectssss?.leadersCount ? formatNumber(Allprojectssss?.leadersCount) : 0}</span></h5> */}
                                        <h5><span>3.4K+</span></h5>
                                    </div>
                                    <div className="text">
                                        <p>{t('NewBannert6')}</p>
                                        {/* <h5><span>${Allprojectssss?.totalAmountInUsd ? formatNumber(Allprojectssss?.totalAmountInUsd) : 0}</span></h5> */}
                                        <h5><span>$200M+</span></h5>
                                    </div>
                                    <div className="text">
                                        <p>{t('NewBannert7')}</p>
                                        {/* <h5><span>{Allprojectssss?.totalCountries ? formatNumber(Allprojectssss?.totalCountries) : 0}</span></h5> */}
                                        <h5><span>140+</span></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-container">
                        <div className="pro-projects">
                            <h6>Gems pro <span> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                <path d="M7.45048 0.257202L8.48835 3.53194L12.1008 3.45449L12.7619 5.38046L9.79188 7.32689L10.9857 10.5534L9.2544 11.7429L6.38016 9.67079L3.50592 11.7429L1.77458 10.5534L2.96845 7.32689L0 5.379L0.661029 3.45303L4.27352 3.53048L5.3114 0.257202H7.45048Z" fill="#30F1B6" />
                            </svg>Verified Gems</span></h6>
                        </div>
                    </div>

                    <div className="process-launchpad">
                        <ProcessLaunchpad />
                    </div>
                    <div className="custom-container">
                        <div className="pro-projects">
                            <h6>gems self serve</h6>
                        </div>
                    </div>
                    <div className="bottom-content">
                        <div className="custom-container">
                            <Tabs
                                onSelect={handleTabSelect} defaultActiveKey="all"
                                // defaultActiveKey="all"
                                id="uncontrolled-tab-example"
                                className="allprojects-tab"
                            >
                                <Tab eventKey="all" title={t('Seeallpro8')}>
                                    <PresalecardsAll role={role} setMainLoader1={setMainLoader1} />
                                </Tab>
                                <Tab eventKey="live" title={t('live')}>
                                    <PresalecardsLive role={role} setMainLoader1={setMainLoader1} />
                                </Tab>
                                <Tab eventKey="upcoming" title={t('Upcoming')}>
                                    <PresalecardsUpcoming role={role} setMainLoader1={setMainLoader3} />
                                </Tab>
                                <Tab eventKey="ended" title={t('Dop-detail3')}>
                                    <PresalecardsEnded role={role} setMainLoader1={setMainLoader2} />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </section >
            </div >
        </>
    )
}

export default SeeAllProjects
