import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const RoundCardsss = ({ round }) => {
  // console.log("rounddata", round?.projectName)
  const [roundStatus, setRoundStatus] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Function to calculate time remaining in format: 11D:8H:57M:9S
    const getTimeRemaining = (targetTime) => {
      const timeDifference = new Date(targetTime).getTime() - new Date().getTime();

      // Ensure timeDifference is non-negative
      const seconds = Math.max(Math.floor(timeDifference / 1000), 0);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const daysFormatted = days > 0 ? `${days}D:` : '0D:';
      const hoursFormatted = (hours % 24) > 0 ? `${hours % 24}H:` : '0H:';
      const minutesFormatted = (minutes % 60) > 0 ? `${minutes % 60}M:` : '0M';
      const secondsFormatted = seconds % 60;

      return `${daysFormatted}${hoursFormatted}${minutesFormatted}${secondsFormatted}S`;
    };

    const currentTime = new Date().getTime();
    // console.log("current time",currentTime)
    const startTime = new Date(round?.presaleRounds[0]?.startDate).getTime();
    // console.log("start time", startTime)
    const endTime = new Date(round?.presaleRounds[0]?.endDate).getTime();

    // Initialize round status based on current time
    const checkRoundStatus = () => {
      if (currentTime < startTime) {
        // console.log("1")
        setRoundStatus({ status: 'starting', timeRemaining: getTimeRemaining(round?.presaleRounds[0]?.startDate) });
      } else if (currentTime >= startTime && currentTime <= endTime) {
        // console.log("2")
        setRoundStatus({ status: 'ending', timeRemaining: getTimeRemaining(round?.presaleRounds[0]?.endDate) });
      } else {
        // console.log("3")
        setRoundStatus({ status: 'ended' });
      }
    };

    // Run check initially and then every second
    checkRoundStatus();
    const intervalId = setInterval(checkRoundStatus, 1000);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [round]);

  if (!roundStatus) return null;

  return (
    //     <div className="round-info">
    //     <h6>Round Ending In</h6>
    //     {timeshow ?
    //       <h6>{t('Process-t12')}</h6>
    //       :
    //       <h6>{day ? day : 0}D : {hour ? hour : 0}H : {min ? min : 0}M : {sec ? sec : 0}S</h6>
    //     }
    //   </div>
    <>
      <div className="round-info">
        {/* <h5>abdullah</h5> */}
        {roundStatus.status === 'starting' && (
          <>
            <h6>{t('Process-t13')}</h6>
            <h6>{roundStatus.timeRemaining}</h6>
          </>
        )}

        {roundStatus.status === 'ending' && (
          <>
            <h6>{t('Process-t10')}</h6>
            <h6>{roundStatus.timeRemaining}</h6>
          </>
        )}

        {roundStatus.status === 'ended' && (
          <>
            <h6>{t('RoundIs')}</h6>
            <h6 className="ended">{t('Finished')}</h6>
          </>
        )}
      </div>
      {roundStatus.status === 'starting' && (
        <a href="#" className='btn-buy' style={{ opacity: "0.5", pointerEvents: "none" }}>{t('Btn-buynow')}</a>

      )}

      {roundStatus.status === 'ending' && (
        // ""
        <>
          {round?.isPremiumProject === true ?
            (
              <a href={`http://34.216.224.214:3000/id=${round?.projectName}`} target='_blank' className='btn-buy' >{t('Btn-buynow')}</a>

            )
            :
            (
              <a href={`https://buy.gems.vip/?projectName=${round?.projectName}`} target='_blank' className='btn-buy' >{t('Btn-buynow')}</a>

            )
          }

        </>

      )}

      {roundStatus.status === 'ended' && (
        <Link
          to={`/Gems/${round?.projectName}`}
          className="btn-buy">
          {t('Process-t7')}
        </Link>
      )}


    </>

  );
};

export default RoundCardsss;
