import React, { useEffect, useState } from "react";
import "./faqs.scss";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import Faqsbanner from "./Faqsbanner";

const Faqs = () => {
  const [activeKey, setActiveKey] = useState("0");
  const handleSelect = (key) => {
    setActiveKey(key);
  };
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className="mainfaqs">
        <Faqsbanner />
        <div className="innerfaq">
          <h2 className="head">{t('Faq-head1')}</h2>
          <Accordion activeKey={activeKey} onSelect={handleSelect}>
            <Accordion.Item
              eventKey="0"
              className={activeKey === "0" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>01.</span>{t('Faq-q1')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a1')}
                </p>
                <ol>
                  <li> {t('Faq-a1-l1')}</li>
                  <li> {t('Faq-a1-l2')}</li>
                  <li> {t('Faq-a1-l3')}</li>
                  <li> {t('Faq-a1-l4')}</li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="1"
              className={activeKey === "1" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>02.</span> {t('Faq-q2')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a2')}
                </p>
                <ol>
                  <li><span> {t('Faq-a2-l1')}</span> {t('Faq-a2-l1p')}</li>
                  <li><span> {t('Faq-a2-l2')}</span> {t('Faq-a2-l2p')}</li>
                  <li><span> {t('Faq-a2-l3')}</span> {t('Faq-a2-l3p')}</li>
                  <li><span> {t('Faq-a2-l4')}</span> {t('Faq-a2-l4p')}</li>
                </ol>
                <p className="para">
                  {t('Faq-a2-endpara')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="2"
              className={activeKey === "2" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>03.</span>{t('Faq-q3')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a3')}
                </p>
                <ol>
                  <li>{t('Faq-a3-l1')}</li>
                  <li>{t('Faq-a3-l2')}</li>
                  <li>{t('Faq-a3-l3')}</li>
                  <li>{t('Faq-a3-l4')}</li>
                  <li>{t('Faq-a3-l5')}</li>
                  <li>{t('Faq-a3-l6')}</li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="3"
              className={activeKey === "3" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>04.</span>{t('Faq-q4')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a4')}
                </p>
                <ol>
                  <li>{t('Faq-a4-l1')}</li>
                  <li>{t('Faq-a4-l2')}</li>
                  <li>{t('Faq-a4-l3')}</li>
                  <li>{t('Faq-a4-l4')}</li>
                </ol>
                <p className="para">
                  {t('Faq-a4-endpara')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="4"
              className={activeKey === "4" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>05.</span>{t('Faq-q5')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a5')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="5"
              className={activeKey === "5" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>06.</span>{t('Faq-q6')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a6')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="6"
              className={activeKey === "6" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>07.</span>{t('Faq-q7')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a7')}
                </p>
                <p className="para">
                  {t('Faq-a71')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <h2 className="head">{t('Faq-head2')}</h2>
          <Accordion activeKey={activeKey} onSelect={handleSelect}>
            <Accordion.Item
              eventKey="0"
              className={activeKey === "0" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>08.</span>{t('Faq-q8')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a8')}
                </p>
                <ul>
                  <li> {t('Faq-a8-l1')}</li>
                  <li> {t('Faq-a8-l2')}</li>
                  <li> {t('Faq-a8-l3')}</li>
                  <li> {t('Faq-a8-l4')}</li>
                  <li> {t('Faq-a8-l5')}</li>
                </ul>
                <p className="para">
                  {t('Faq-a8-endpara')}
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="1"
              className={activeKey === "1" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>09.</span> {t('Faq-q9')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a9')}
                </p>
               
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="2"
              className={activeKey === "2" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>10.</span>{t('Faq-q10')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a10')}
                </p>
                
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="3"
              className={activeKey === "3" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>11.</span>{t('Faq-q11')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a11')}
                </p>
                <ul>
                  <li>{t('Faq-a11-l1')}</li>
                  <li>{t('Faq-a11-l2')}</li>
                  <li>{t('Faq-a11-l3')}</li>
                  <li>{t('Faq-a11-l4')}</li>
                </ul>
                <p className="para">
                  {t('Faq-a11-l5')}
                </p>
                <ul>
                  <li>{t('Faq-a11-l6')}</li>
                  <li>{t('Faq-a11-l7')}</li>
                  <li>{t('Faq-a11-l8')}</li>
                </ul>
                <p className="para">
                  {t('Faq-a11-endpara')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="4"
              className={activeKey === "4" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>12.</span>{t('Faq-q12')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a12')}
                </p>
                <ul>
                  <li> {t('Faq-a12-l1')}</li>
                  <li> {t('Faq-a12-l2')}</li>
                  <li> {t('Faq-a12-l3')}</li>
                  <li> {t('Faq-a12-l4')}</li>
                  <li> {t('Faq-a12-l5')}</li>
                  <li> {t('Faq-a12-l6')}</li>
                </ul>
                <p className="para">
                  {t('Faq-a12-endpara')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="5"
              className={activeKey === "5" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>13.</span>{t('Faq-q13')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a13')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <h2 className="head">{t('Faq-head3')}</h2>
          <Accordion activeKey={activeKey} onSelect={handleSelect}>
            <Accordion.Item
              eventKey="0"
              className={activeKey === "0" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>14.</span>{t('Faq-q14')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a14')}
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="1"
              className={activeKey === "1" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>15.</span> {t('Faq-q15')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a15')}
                </p>
               
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="2"
              className={activeKey === "2" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>16.</span>{t('Faq-q16')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a16')}
                </p>
                
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="3"
              className={activeKey === "3" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>17.</span>{t('Faq-q17')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a17')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <h2 className="head">{t('Faq-head4')}</h2>
          <Accordion activeKey={activeKey} onSelect={handleSelect}>
            <Accordion.Item
              eventKey="0"
              className={activeKey === "0" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>18.</span>{t('Faq-q18')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a18')}
                </p>
                <p className="para">
                  {t('Faq-a181')}
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="1"
              className={activeKey === "1" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>19.</span> {t('Faq-q19')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a19')}
                </p>
                <ul>
                  <li>{t('Faq-a19-l1')}</li>
                  <li>{t('Faq-a19-l2')}</li>
                  <li>{t('Faq-a19-l3')}</li>
                </ul>
               
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="2"
              className={activeKey === "2" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>20.</span>{t('Faq-q20')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a20')}
                </p>
                <p className="para">
                  {t('Faq-a201')}
                </p>
                
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="3"
              className={activeKey === "3" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>21.</span>{t('Faq-q21')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                  {t('Faq-a21')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </>
  );
};

export default Faqs;
