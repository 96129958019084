import React from 'react'
import { useTranslation } from 'react-i18next'

const Document = () => {
    const { t } = useTranslation()
    return (
        <>
            <section className="document-section">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>{t('Gems-detailt4')} <span></span></h2>
                </div>
                <div className="bottom-documents">
                    <div className="single-document">
                        <div className="wrap-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M23.8438 3.28125H23.0234V2.46094C23.0234 1.10398 21.9195 0 20.5625 0H4.15625C2.79929 0 1.69531 1.10398 1.69531 2.46094V22.2578C1.69531 23.6148 2.79929 24.7188 4.15625 24.7188H4.97656V25.5391C4.97656 26.896 6.08054 28 7.4375 28H23.8438C25.2007 28 26.3047 26.896 26.3047 25.5391V5.74219C26.3047 4.38523 25.2007 3.28125 23.8438 3.28125ZM5.69734 6.32226C5.2325 6.78705 4.97656 7.40502 4.97656 8.06236V23.0781H4.15625C3.70393 23.0781 3.33594 22.7101 3.33594 22.2578V2.46094C3.33594 2.00862 3.70393 1.64062 4.15625 1.64062H20.5625C21.0148 1.64062 21.3828 2.00862 21.3828 2.46094V3.28125H9.75767C9.10033 3.28125 8.48236 3.53724 8.01757 4.00203L5.69734 6.32226ZM9.89844 4.92188V8.20312H6.61719V8.06236C6.61719 7.84328 6.7025 7.63727 6.85743 7.48234L9.17766 5.16212C9.33259 5.00719 9.53859 4.92188 9.75767 4.92188H9.89844ZM24.6641 25.5391C24.6641 25.9914 24.2961 26.3594 23.8438 26.3594H7.4375C6.98518 26.3594 6.61719 25.9914 6.61719 25.5391V9.84375H10.7188C11.1718 9.84375 11.5391 9.47647 11.5391 9.02344V4.92188H23.8438C24.2961 4.92188 24.6641 5.28987 24.6641 5.74219V25.5391Z" fill="#657B82" />
                                <path d="M22.2031 11.5938H9.07812C8.62509 11.5938 8.25781 11.961 8.25781 12.4141C8.25781 12.8671 8.62509 13.2344 9.07812 13.2344H22.2031C22.6562 13.2344 23.0234 12.8671 23.0234 12.4141C23.0234 11.961 22.6562 11.5938 22.2031 11.5938Z" fill="#657B82" />
                                <path d="M22.2031 14.875H9.07812C8.62509 14.875 8.25781 15.2423 8.25781 15.6953C8.25781 16.1483 8.62509 16.5156 9.07812 16.5156H22.2031C22.6562 16.5156 23.0234 16.1483 23.0234 15.6953C23.0234 15.2423 22.6562 14.875 22.2031 14.875Z" fill="#657B82" />
                                <path d="M22.2031 18.1562H9.07812C8.62509 18.1562 8.25781 18.5235 8.25781 18.9766C8.25781 19.4296 8.62509 19.7969 9.07812 19.7969H22.2031C22.6562 19.7969 23.0234 19.4296 23.0234 18.9766C23.0234 18.5235 22.6562 18.1562 22.2031 18.1562Z" fill="#657B82" />
                                <path d="M22.2031 21.4375H9.07812C8.62509 21.4375 8.25781 21.8048 8.25781 22.2578C8.25781 22.7108 8.62509 23.0781 9.07812 23.0781H22.2031C22.6562 23.0781 23.0234 22.7108 23.0234 22.2578C23.0234 21.8048 22.6562 21.4375 22.2031 21.4375Z" fill="#657B82" />
                            </svg>
                            <h6>{t('Gems-detailt45')} </h6>
                        </div>
                        <a href="\assets\alldetailpages\olympasdetailpage\document\olympus_aI_whitepaper.pdf" target='_blank'>{t('Btn-readnow')} </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Document
